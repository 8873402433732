import React from 'react';

const AutoComplete = ({ options, onClick, length = 5 }) => {
  return (
    <div>
      <ul>
        {options.slice(0, length).map((item, index) => (
          <li key={index} onClick={e => onClick(e, item.label)}>
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AutoComplete;
