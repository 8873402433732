import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Rating from 'react-rating';
import { getTutorById, createReview } from '../../actions/tutor';
import {
  getCurrentStudent,
  requestTutor,
  enrollStudent
} from '../../actions/student';
import PropTypes from 'prop-types';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../layout/Footer';
import Review from './Review';
import HelmetWrapper from '../utils/HelmetWrapper';
import { setAlert } from '../../actions/alert';

const grades = {
  0: 'K',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: 'College'
};

const TutorPage = ({
  tutor: { tutor, loading },
  student: { student },
  getTutorById,
  getCurrentStudent,
  requestTutor,
  enrollStudent,
  createReview,
  setAlert,
  auth: { user },
  match
}) => {
  const history = useHistory();

  useEffect(() => {
    if (match.params.id) {
      getTutorById(match.params.id);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (tutor && tutor.ap) {
      setCompletedAP(tutor.ap.filter(ap => ap.completed));
      setOngoingAP(tutor.ap.filter(ap => !ap.completed));
    }
    if (tutor && !tutor.ap) {
      setCompletedAP([]);
      setOngoingAP([]);
    }
  }, [tutor]);

  useEffect(() => {
    getCurrentStudent();
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState('');

  const [showReviewForm, setShowReviewForm] = useState(false);

  const [completedAP, setCompletedAP] = useState([]);

  const [ongoingAP, setOngoingAP] = useState([]);

  const [reviewData, setReviewData] = useState({
    rating: 0,
    text: '',
    anonymous: false
  });

  const [enrollData, setEnrollData] = useState('');

  const onEnrollSubmit = e => {
    e.preventDefault();
    enrollStudent(enrollData, tutor.user._id);
  };
  const onEnrollChange = e => {
    setEnrollData(e.target.value);
  };

  const onChange = e => {
    setFormData(e.target.value);
  };

  const onSubmit = e => {
    e.preventDefault();
    requestTutor(formData, tutor.user._id);
  };

  const handleReviewSubmit = e => {
    e.preventDefault();
    if (reviewData.rating <= 0) {
      setAlert('Please leave a star rating', 'danger');
      return;
    }
    createReview(tutor._id, reviewData);
  };

  const onReviewChange = e => {
    setReviewData({ ...reviewData, [e.target.name]: e.target.value });
  };

  const onAnonymousChange = e => {
    setReviewData({ ...reviewData, anonymous: !reviewData.anonymous });
  };

  const onRatingChange = value => {
    setReviewData({ ...reviewData, rating: value });
  };

  return (
    <div className='container colored'>
      {tutor ? (
        <HelmetWrapper
          title={`${tutor.user.name} | Ingenify`}
          description="This is the public profile page of the tutor. It contains the tutor's name, grade, school, number of students, number of hours rating, affiliation, bio, and subjects. Students may send a request to this tutor on this page."
        />
      ) : (
        <HelmetWrapper
          title={`Tutor | Ingenify`}
          description="This is the public profile page of the tutor. It contains the tutor's name, grade, school, number of students, number of hours rating, affiliation, bio, and subjects. Students may send a request to this tutor on this page."
        />
      )}
      <div className='profile-page'>
        <div className='profile-body'>
          {tutor ? (
            <div className='profile-info'>
              <div className='profile-top'>
                <img src={tutor.user.profileImageUrl} alt='tutor' />
                <div>
                  <h1 className='large mobile-center'>{tutor.user.name}</h1>
                  <div>
                    {/* <FontAwesomeIcon
                    icon='star'
                    className='text-secondary'
                    title='Rating'
                  />
                  <span className='mx-1'>
                    {tutor.rating === 0 ? <span>Not Rated</span> : tutor.rating}
                  </span> */}
                    <Rating
                      fractions={2}
                      readonly={true}
                      initialRating={tutor.rating}
                      emptySymbol={
                        <FontAwesomeIcon icon='star' className='text-gray' />
                      }
                      fullSymbol={
                        <FontAwesomeIcon icon='star' className='text-gold' />
                      }
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon='school'
                      className='text-secondary'
                      title='School'
                    />{' '}
                    <span className='mx'>
                      {tutor.profile.school}, {tutor.profile.location}
                    </span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon='graduation-cap'
                      className='text-secondary'
                      title='Grade'
                    />{' '}
                    {tutor.profile.grade == 13 ? (
                      <span className='mx'>College</span>
                    ) : (
                      <span className='mx'>{tutor.profile.grade}th Grade</span>
                    )}
                  </div>
                  {tutor.students.length !== 0 && (
                    <div>
                      <FontAwesomeIcon
                        icon='user-friends'
                        className='text-secondary'
                        title='Current Students'
                      />{' '}
                      <span className='mx'>
                        {tutor.totalStudents.length} Students
                      </span>
                    </div>
                  )}
                  {tutor.hours !== 0 && (
                    <div>
                      <FontAwesomeIcon
                        icon='hourglass-half'
                        className='text-secondary'
                        title='Hours'
                      />{' '}
                      <span className='mx-1'>{tutor.hours} Hours</span>
                    </div>
                  )}
                  {tutor.availability && tutor.availability !== '' && (
                    <div>
                      <FontAwesomeIcon
                        icon='calendar-alt'
                        className='text-secondary'
                        title='Availability'
                      />{' '}
                      <span className='mx-1'>{tutor.availability}</span>
                    </div>
                  )}
                  {tutor.affiliation && tutor.affiliation !== '' && (
                    <div>
                      <FontAwesomeIcon
                        icon='sitemap'
                        className='text-secondary'
                        title='Affiliation'
                      />{' '}
                      <span className='mx'>{tutor.affiliation}</span>
                    </div>
                  )}
                  <form className='form flex' onSubmit={e => onEnrollSubmit(e)}>
                    <div className='width-50'>
                      <input
                        type='text'
                        placeholder='Enrollment Code'
                        name='code'
                        onChange={e => onEnrollChange(e)}
                        value={enrollData}
                        required
                      />
                    </div>
                    <input
                      type='submit'
                      style={{ borderRadius: '0.25rem' }}
                      className='btn btn-secondary mx-1'
                      value='Enroll'
                    />
                  </form>
                </div>
                <div className='hide-sm'>
                  <button className='btn-link' onClick={e => history.goBack()}>
                    Back
                  </button>
                </div>
              </div>
              <hr />
              <div
                className='profile-mid flex-justify-center'
                style={{ whiteSpace: 'pre-wrap' }}
              >
                <div className='tutor-bio'>{tutor.bio}</div>
              </div>
              <hr />
              {tutor.subjects.length > 0 && (
                <p className='medium p-1'>Subjects</p>
              )}
              <ul className='profile-low'>
                {tutor.subjects.map((subject, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon='check' className='text-secondary' />{' '}
                    <span className='mx'>{subject}</span>
                  </li>
                ))}
              </ul>
              {completedAP.length > 0 && (
                <p className='medium p-1'>Completed AP</p>
              )}
              <ul className='profile-low'>
                {completedAP.map((ap, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon='check' className='text-secondary' />
                    <span className='mx'>{ap.courseName}</span>
                    <span style={{ color: '#28a745', marginRight: '0.5rem' }}>
                      {ap.score}
                    </span>
                  </li>
                ))}
              </ul>
              {ongoingAP.length > 0 && <p className='medium p-1'>Ongoing AP</p>}
              <ul className='profile-low'>
                {ongoingAP.map((ap, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon='check' className='text-secondary' />
                    <span className='mx'>{ap.courseName}</span>
                    <span style={{ color: '#28a745', marginRight: '0.5rem' }}>
                      {ap.score}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {!student && (
            <Link to='/account/profile'>
              <p className='text-danger'>
                <i>Create a profile to send request</i>
              </p>
            </Link>
          )}
          <form className='form flex-column-center' onSubmit={e => onSubmit(e)}>
            <div className='form-group request-form'>
              <textarea
                placeholder="Introduce yourself, explain what subjects you're interested in, etc."
                name='msg'
                value={formData}
                onChange={e => onChange(e)}
                required
              />
              <input
                className='btn btn-secondary'
                type='submit'
                value='Send Request'
              />
            </div>
          </form>
        </div>
        <div className='profile-reviews'>
          {tutor && (
            <div>
              <div className='flex-spread'>
                <p className='lead'>{tutor.reviews.length} Reviews</p>
                <button
                  className='btn btn-success'
                  onClick={e => setShowReviewForm(!showReviewForm)}
                >
                  Write a Review
                </button>
              </div>
              {showReviewForm && (
                <form className='form' onSubmit={e => handleReviewSubmit(e)}>
                  <div className='form-group'>
                    <Rating
                      fractions={2}
                      readonly={false}
                      initialRating={0}
                      emptySymbol={
                        <FontAwesomeIcon icon='star' className='text-gray' />
                      }
                      fullSymbol={
                        <FontAwesomeIcon icon='star' className='text-gold' />
                      }
                      initialRating={reviewData.rating}
                      onClick={onRatingChange}
                    />
                  </div>
                  <div className='form-group'>
                    <textarea
                      placeholder='Write a Review'
                      name='text'
                      rows='10'
                      value={reviewData.text}
                      onChange={e => onReviewChange(e)}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='checkbox'
                      name='anonymous'
                      checked={reviewData.anonymous}
                      onChange={e => onAnonymousChange(e)}
                    />
                    <label htmlFor='anonymous'>{` Check to submit an anonymous review`}</label>
                  </div>
                  <div className='form-group'>
                    <input
                      style={{ width: '100%' }}
                      type='submit'
                      className='btn btn-secondary'
                      value='Submit Review'
                    />
                  </div>
                </form>
              )}
              <ul>
                {tutor.reviews.map((review, index) => (
                  <li key={index}>
                    <Review
                      className='my'
                      review={review}
                      ownership={user && user._id === review.student._id}
                      tutor={tutor}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

TutorPage.propTypes = {
  getTutorById: PropTypes.func.isRequired,
  requestTutor: PropTypes.func.isRequired,
  enrollStudent: PropTypes.func.isRequired,
  createReview: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  tutor: PropTypes.object,
  student: PropTypes.object,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  tutor: state.tutor,
  student: state.student,
  auth: state.auth
});

export default connect(mapStateToProps, {
  getTutorById,
  getCurrentStudent,
  requestTutor,
  enrollStudent,
  createReview,
  setAlert
})(TutorPage);
