import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { removeStudent, scheduleMeeting } from '../../../actions/tutor';
import PropTypes from 'prop-types';

const StudentItem = ({ student, removeStudent, scheduleMeeting }) => {
  const initialForm = {
    date: '',
    time: '',
    duration: 0
  };

  const [validBrowser, setValidBrowser] = useState(false);

  useEffect(() => {
    let userAgentString = navigator.userAgent;
    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1;

    // Detect Internet Explorer
    let IExplorerAgent =
      userAgentString.indexOf('MSIE') > -1 ||
      userAgentString.indexOf('rv:') > -1;

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;

    // Detect Opera
    let operaAgent = userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if (chromeAgent && operaAgent) chromeAgent = false;

    if (chromeAgent || firefoxAgent) {
      setValidBrowser(true);
    } else {
      setValidBrowser(false);
    }
  }, []);

  const [showForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState(initialForm);

  const handleUnenroll = e => {
    e.preventDefault();
    removeStudent(student._id);
  };

  const onSubmit = e => {
    e.preventDefault();
    scheduleMeeting(student._id, formData);
    setFormData(initialForm);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { date, time, duration } = formData;

  return (
    <div>
      <div className='flex-spread student-item'>
        <div className='flex'>
          <img src={student.profileImageUrl} alt='student' />
          <p className='px-1'>{student.name}</p>
        </div>
        <div className='flex right'>
          <div className='px-1 m-auto-vert'>
            <button
              className='btn btn-secondary'
              onClick={e => setShowForm(!showForm)}
            >
              Schedule Meeting
            </button>
          </div>
          <div className='m-auto-vert hide-sm'>
            <button className='btn btn-danger' onClick={e => handleUnenroll(e)}>
              Remove
            </button>
          </div>
        </div>
      </div>
      {showForm ? (
        <div>
          {validBrowser ? (
            <form className='form' onSubmit={e => onSubmit(e)}>
              <div className='flex form-group mobile-form'>
                <div>
                  <div className='medium'>
                    <strong>Date</strong>
                  </div>
                  <input
                    type='date'
                    name='date'
                    onChange={e => onChange(e)}
                    value={date}
                    required
                  />
                </div>
                <div className='mx-2'>
                  <div className='medium'>
                    <strong>Time</strong>
                  </div>
                  <input
                    type='time'
                    name='time'
                    onChange={e => onChange(e)}
                    value={time}
                    required
                  />
                </div>
                <div>
                  <div className='medium'>
                    <strong>Duration</strong>
                  </div>
                  <select
                    name='duration'
                    value={duration}
                    onChange={e => onChange(e)}
                    required
                  >
                    <option value='0'>0 minutes</option>
                    <option value='30'>30 minutes</option>
                    <option value='60'>1 hour</option>
                    <option value='90'>1 hour 30 minutes</option>
                    <option value='120'>2 hours</option>
                  </select>
                </div>
              </div>
              <div className='flex-spread'>
                <input
                  type='submit'
                  className='btn btn-secondary'
                  value='Schedule'
                />
                <button
                  className='btn btn-danger'
                  onClick={e => setShowForm(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <div>
              <p>
                Please use Chrome or Firefox. Try using a computer if on mobile.
              </p>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

StudentItem.propTypes = {
  student: PropTypes.object,
  scheduleMeeting: PropTypes.func.isRequired,
  removeStudent: PropTypes.func.isRequired
};

export default connect(null, { removeStudent, scheduleMeeting })(StudentItem);
