import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getChats, getChat, afterPostMessage } from '../../actions/chat';
import ChatBox from './ChatBox';
import SideBar from './SideBar';

let socket;
// const ENDPOINT = 'http://localhost:5000';
const ENDPOINT = 'https://ingenify.org';
const Chat = ({
  user,
  chat: { chat, chats },
  getChats,
  getChat,
  afterPostMessage
}) => {
  useEffect(() => {
    getChats();
    socket = io(ENDPOINT);
    socket.on('Output Chat Message', messageFromBackEnd => {
      afterPostMessage(messageFromBackEnd);
    });
  }, [ENDPOINT]);

  const submitChatMessage = (e, msg) => {
    e.preventDefault();
    socket.emit('Input Chat Message', {
      chatMessage: msg,
      userId: user._id,
      chatId: chat._id
    });
  };

  useEffect(() => {
    if (chats) {
      chats.forEach(chat => {
        socket.emit('join', chat._id);
        console.log('joining');
      });
    }
  }, [chats]);

  const newSpecifiedChat = (e, newChat) => {
    e.preventDefault();
    getChat(newChat._id);
  };

  return (
    <div className='container'>
      {chats && user && (
        <div className='chat-container'>
          <SideBar
            newSpecifiedChat={newSpecifiedChat}
            chats={chats}
            user={user}
          />
          {chat && (
            <ChatBox submitChatMessage={submitChatMessage} chat={chat} />
          )}
        </div>
      )}
    </div>
  );
};

Chat.propTypes = {
  user: PropTypes.object,
  chat: PropTypes.object,
  getChat: PropTypes.func.isRequired,
  getChats: PropTypes.func.isRequired,
  afterPostMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  chat: state.chat
});

export default connect(mapStateToProps, {
  getChats,
  getChat,
  afterPostMessage
})(Chat);
