import React, { useEffect } from 'react';
import Footer from './Footer';
import HelmetWrapper from '../utils/HelmetWrapper';
import '../../App.css';
const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class='container privacy'>
      <HelmetWrapper
        title='Terms of Service'
        description='This is the terms of service of Ingenify.'
      />
      <div className='m-3'>
        <h1 className='text-center'>Terms of Service</h1>
        <hr />
        <p>
          Thank you for your interest in using the online tutoring services of
          Ingenify (“we,” “us,” “our team,” “the platform”). The following terms
          of service govern your use of any mobile, web, software, or any other
          affiliated services provided by Ingenify. To use the platform, you
          must agree to the following terms and conditions for usage.
        </p>
        <br />

        <p>
          PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY. BY AGREEING TO
          THESE TERMS, YOU ARE INDICATING THAT YOU HAVE READ AND ACKNOWLEDGED
          THE FOLLOWING CONDITIONS, GUIDELINES, AS WELL AS THE PRIVACY POLICY OF
          THIS SERVICE.
        </p>
        <br />

        <p>I. Conditions and Eligibility</p>
        <p>
          To hold an account and use our service, you must adhere to the
          following conditions:
        </p>
        <ol>
          <li>
            Be at least 13 years old, and if otherwise, you must obtain consent
            from a parent or guardian that is responsible for your account and
            activity on the platform.
          </li>
          <li>
            Have not been banned or restricted to use Ingenify due to a
            violation of this terms of service on a prior occasion.
          </li>
          <li>
            Adhere to user rights, service rights, and acceptable use as
            outlined below.
          </li>
        </ol>
        <br />
        <p>II. User Rights</p>
        <p>
          By accepting to use our service, Ingenify grants you a fully
          accessible license to hold an account and access our website and our
          platform as per the conditions. Registration for an account grants you
          ownership rights to that account and all of its contributions on the
          site to other users in the form of tutoring (hereby defined as
          “content”).
        </p>
        <br />

        <p>III. Service Rights</p>
        <p>By accepting to use our service, Ingenify reserves all rights:</p>
        <ol>
          <li>
            to modify, suspend, or discontinue your account and/or content if we
            deem it in violation to community guidelines and terms of service or
            in engagement of suspicious activity, with or without notice.
          </li>
          <li>
            to hold onto our reserved copyright and intellectual property rights
            within our services.
          </li>
          <li>
            to uphold these terms to the fullest reasonable and legal extent.
          </li>
        </ol>
        <br />

        <p>IV. User responsibilities</p>
        <p>
          Registering and holding an Ingenify account entails the following
          responsibilities as a user:
        </p>
        <ol>
          <li>
            Report all instances of legal violations of other users, to Ingenify
            or to legal authorities.
          </li>
          <li>Maintain the security and privacy of your own account.</li>
          <li>
            Follow the correct procedures as specified by the instructions of
            the site.
          </li>
          <li>Maintain the truthfulness of your profile and information.</li>
          <li>
            As an educator, take responsibility for the quality of your user
            content.
          </li>
          <li>As a student, take responsibility for your own learning.</li>
          <li>
            Be aware that the Ingenify is a nonprofit, uncompensated,
            noncommercial platform that neither the users nor the organization
            would ask for any financial information.
          </li>
        </ol>
        <br />

        <p>V. Acceptable Use</p>
        <ol>
          <li>
            Uphold the law within your activities and interactions on the
            website.
          </li>
          <li>Do not use the site for commercial purposes.</li>
          <li>
            Do not impersonate or falsely imply affiliation with the company or
            another party.
          </li>
          <li>
            Do not breach the Ingenify server security or endanger the security
            of other users, either through means of hacking, DDoSing, or
            solicitation.
          </li>
          <li>
            Do not disclose sensitive or private information to other users.
          </li>
          <li>
            Do not solicit or encourage any other users to violate the law or
            these terms and conditions.
          </li>
          <li>Do not incite conflict with Ingenify or with other users.</li>
        </ol>
        <br />

        <p>VI. Enforcements and Disputes</p>
        <p>
          We reserve all rights to take proper action and penalize violations of
          these terms and conditions. To maintain community guidelines, Ingenify
          holds all authority to delete or redact accounts and content on the
          platform. Other users of the site are reserved the right to report any
          account or content deemed to have violated our terms and conditions.
        </p>

        <p>
          Any dispute between you and the company will enter a negotiation
          period that upholds the specifications in the privacy policy and the
          terms of service. If engaged in legal dispute, both Ingenify and you
          may proceed with an arbitration process. Arbitration will take place
          in Atlanta, Georgia, and must be binded to the processes as defined by
          the American Arbitration Association, outlined within Commercial
          Arbitration Rules and Supplementary Procedures for Consumer Related
          Disputes.
        </p>

        <p>
          Class Action Waiver: Proceedings of dispute must be completed on an
          individual basis without class action or other representative
          proceedings.
        </p>
        <br />

        <p>VII. Liability Limits</p>
        <p>
          Ingenify has no liability regarding personal use and encounters with
          other users of the platform, though, as specified in clause III, all
          users maintain the right to report breaches and violations of the
          terms of use. In addition, the service is not responsible for any
          issues arising from third party sources and content that could not be
          reasonably foreseen. Ingenify does not hold any consequential and
          indirect liability for damages and losses relating to the use and
          interactions of the website with others or within legal disputes.
        </p>
        <br />

        <p>VIII. Feedback</p>

        <p>
          Ingenify welcomes any feedback or input regarding the improvement of
          our site and service through our proper contact system. Though, the
          company is not obligated to institute any feedback on your behalf.
          Furthermore, your feedback is not subject to confidentiality,
          intellectual property, nor compensation.
        </p>
        <br />

        <p>IX. General Terms</p>
        <p>
          All rights not explicitly stated in the terms and conditions are
          reserved by Ingenify. The failure to exercise an enforcement of a
          breach in a provision does not constitute a waiver to nullify the
          validity of the provision. The explicated terms above constitute all
          terms and conditions between you and Ingenify regarding the use of our
          service, and overrides all other written, verbal, or implicit
          agreements between the service and you.
        </p>
        <br />

        <p>X. Updating this Term of Use</p>
        <p>
          The Ingenify team in the future may see it necessary to update or
          change this term of use to address discretion or to accommodate
          necessary changes. In the event of such a change, a notice will be
          issued to all users on the platform via provided emails and within the
          website at least thirty days prior to the modification. An effective
          date of last modification will be provided at the bottom of this
          statement.
        </p>
        <br />

        <p>XI. Contact</p>
        <p>
          For any questions, inquiries, or concerns regarding the terms of
          service, you may contact us via support@ingenify.org
        </p>
        <br />

        <p>
          <em>Last updated: July 15, 2020</em>
        </p>
      </div>
      <Footer backgroundColor='#fff' />
    </section>
  );
};

export default Terms;
