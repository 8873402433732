import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetWrapper = ({ title, description }) => {
  return (
    <Helmet>
      <meta charset='utf-8' />
      <link rel='icon' href='%PUBLIC_URL%/favicon.ico' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='theme-color' content='#000000' />
      <meta name='description' content={description} />
      <meta name='author' content='Jatong Su' />
      <link rel='apple-touch-icon' href='%PUBLIC_URL%/logo192.png' />

      <link
        href='https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap'
        rel='stylesheet'
      />

      <link rel='manifest' href='%PUBLIC_URL%/manifest.json' />
      <title>{title}</title>
    </Helmet>
  );
};

export default HelmetWrapper;
