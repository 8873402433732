import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading },
  setAlert,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated && !loading ? (
        <div>
          {setAlert('Please Sign In First', 'danger')}
          {window.location.replace('https://ingenify.org/api/auth/google')}
        </div>
      ) : (
        <Component {...props} />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, { setAlert })(PrivateRoute);
