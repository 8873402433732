import React from 'react';
import { deleteMeeting } from '../../../actions/tutor';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const NextMeeting = ({ meeting, isTutor, deleteMeeting }) => {
  let date = meeting.startTime.split('T')[0];
  let time = meeting.startTime.split('T')[1];
  const dateParts = date.split('-');
  date = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]);
  const minutes = timeParts[1];
  if (hour >= 12) {
    if (hour === 12) {
      time = `${hour}:${minutes} PM`;
    } else {
      time = `${hour - 12}:${minutes} PM`;
    }
  } else {
    if (hour === 0) {
      time = `12:${minutes} AM`;
    } else {
      time = `${hour}:${minutes} AM`;
    }
  }
  const handleDelete = e => {
    deleteMeeting(meeting._id);
  };
  return (
    <div>
      <div>
        <p>
          {date} at {time} {meeting.timezone}
        </p>
      </div>
      {isTutor ? (
        <div>
          <div className='flex-spread' style={{ alignItems: 'center' }}>
            <div className='flex' style={{ alignItems: 'center' }}>
              <img
                src={meeting.student.profileImageUrl}
                className='profile-img-small'
                alt='student'
              />
              <p className='medium mx'>{meeting.student.name}</p>
            </div>
            <div className='flex'>
              <a
                href={meeting.startUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                <button className='btn btn-secondary'>Start Meeting</button>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='flex-spread' style={{ alignItems: 'center' }}>
            <div className='flex' style={{ alignItems: 'center' }}>
              <img
                src={meeting.tutor.profileImageUrl}
                className='profile-img-small'
                alt='tutor'
              />
              <p className='medium mx'>{meeting.tutor.name}</p>
            </div>
            <div className='flex'>
              <a
                href={meeting.joinUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                <button className='btn btn-secondary'>Join Meeting</button>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

NextMeeting.propTypes = {
  deleteMeeting: PropTypes.func.isRequired
};

export default connect(null, { deleteMeeting })(NextMeeting);
