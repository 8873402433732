import React, { useEffect, useState } from 'react';

const ChatItem = ({ chat, user }) => {
  const [other, setOther] = useState(null);

  useEffect(() => {
    if (chat.members[0]._id === user._id) {
      setOther(chat.members[1]);
    } else {
      setOther(chat.members[0]);
    }
  }, [chat]);

  return (
    <div>
      {other && (
        <div className='chat-item'>
          <img src={other.profileImageUrl} alt='img' />
          <div className='mx-1'>
            <p>{other.name}</p>
            <p className='small'>
              {chat.messages[chat.messages.length - 1].sentMessage.substring(
                0,
                20
              )}
              {chat.messages[chat.messages.length - 1].sentMessage.length >
                20 && <span>...</span>}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatItem;
