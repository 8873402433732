import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { claimBadge, checkSocial } from '../../../actions/profile';

const NormalBadgesDisplay = ({
  profile,
  tutor,
  student,
  checkSocial,
  claimBadge
}) => {
  const initialBadgeState = {
    Getting_Started: 'unqualified',
    The_Beginning: 'unqualified',
    Pupil: 'unqualified',
    Undergraduate: 'unqualified',
    Postgraduate: 'unqualified',
    Critic: 'unqualified',
    Connoisseur: 'unqualified',
    Apprentice: 'unqualified',
    Month: 'unqualified',
    '2_Months': 'unqualified',
    '3_Months': 'unqualified',
    Double_Tap: 'unqualified',
    Endurance: 'unqualified',
    Marathon: 'unqualified',
    Referral: 'unqualified',
    Connected: 'unqualified',
    Jackpot: 'unqualified',
    Collector: 'unqualified',
    Patron: 'unqualified',
    National: 'unqualified'
  };
  const [badgeState, setBadgeState] = useState(initialBadgeState);

  const [socialMediaHandle, setSocialMediaHandle] = useState('');

  useEffect(() => {
    if (profile) {
      const badgeData = { ...initialBadgeState };
      profile.qualifiedBadges.forEach(badgeName => {
        if (profile.claimedBadges.includes(badgeName)) {
          badgeData[badgeName] = 'claimed';
        } else {
          badgeData[badgeName] = 'qualified';
        }
      });
      setBadgeState({ ...badgeData });
    }
  }, [profile]);

  const handleSocialMedia = e => {
    e.preventDefault();
    checkSocial(socialMediaHandle);
  };

  return (
    <div>
      <div className='badge-list'>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Getting_Started}`}
            src={require(`../../../img/badge_Getting_Started.png`)}
          />
          <h3>Getting Started</h3>
          <div className='tier-description'>
            <p className='small text-center'>Create a profile</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Getting_Started') &&
            !profile.claimedBadges.includes('Getting_Started') && (
              <button
                onClick={e => claimBadge('Getting_Started')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Apprentice}`}
            src={require(`../../../img/badge_Apprentice.png`)}
          />
          <h3>Apprentice</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && tutor && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    tutor.students.length,
                    2
                  )} / 2)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
            {profile && !tutor && student && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    student.tutors.length,
                    2
                  )} / 2)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Have 2+ students or tutors</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Apprentice') &&
            !profile.claimedBadges.includes('Apprentice') && (
              <button
                onClick={e => claimBadge('Apprentice')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
      </div>
      <div className='badge-list'>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.The_Beginning}`}
            src={require(`../../../img/badge_The_Beginning.png`)}
          />
          <h3>The Beginning</h3>
          <div className='tier-description'>
            <p className='small text-center'>Have your first meeting</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('The_Beginning') &&
            !profile.claimedBadges.includes('The_Beginning') && (
              <button
                onClick={e => claimBadge('The_Beginning')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Pupil}`}
            src={require(`../../../img/badge_Pupil.png`)}
          />
          <h3>Pupil</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.meetingCount,
                    5
                  )} / 5)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Complete five meetings</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Pupil') &&
            !profile.claimedBadges.includes('Pupil') && (
              <button
                onClick={e => claimBadge('Pupil')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Undergraduate}`}
            src={require(`../../../img/badge_Undergraduate.png`)}
          />
          <h3>Undergraduate</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.meetingCount,
                    10
                  )} / 10)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Complete ten meetings</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Undergraduate') &&
            !profile.claimedBadges.includes('Undergraduate') && (
              <button
                onClick={e => claimBadge('Undergraduate')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Postgraduate}`}
            src={require(`../../../img/badge_Postgraduate.png`)}
          />
          <h3>Postgraduate</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.meetingCount,
                    15
                  )} / 15)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Complete fifteen meetings</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Postgraduate') &&
            !profile.claimedBadges.includes('Postgraduate') && (
              <button
                onClick={e => claimBadge('Postgraduate')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
      </div>
      <div className='badge-list'>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Month}`}
            src={require(`../../../img/badge_Month.png`)}
          />
          <h3>Month</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.longestStreak,
                    4
                  )} / 4)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Obtain a 4-week streak</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Month') &&
            !profile.claimedBadges.includes('Month') && (
              <button
                onClick={e => claimBadge('Month')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState['2_Months']}`}
            src={require(`../../../img/badge_2_Months.png`)}
          />
          <h3>2 Months</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.longestStreak,
                    8
                  )} / 8)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Obtain an 8-week streak</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('2_Months') &&
            !profile.claimedBadges.includes('2_Months') && (
              <button
                onClick={e => claimBadge('2_Months')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState['3_Months']}`}
            src={require(`../../../img/badge_3_Months.png`)}
          />
          <h3>3 Months</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.longestStreak,
                    12
                  )} / 12)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Obtain a 12-week streak</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('3_Months') &&
            !profile.claimedBadges.includes('3_Months') && (
              <button
                onClick={e => claimBadge('3_Months')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
      </div>
      <div className='badge-list'>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Critic}`}
            src={require(`../../../img/badge_Critic.png`)}
          />
          <h3>Critic</h3>
          <div className='tier-description'>
            <p className='small text-center'>
              Submit/recieve your first review
            </p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Critic') &&
            !profile.claimedBadges.includes('Critic') && (
              <button
                onClick={e => claimBadge('Critic')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Connoisseur}`}
            src={require(`../../../img/badge_Connoisseur.png`)}
          />
          <h3>Connoisseur</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && tutor && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    tutor.reviews.length,
                    3
                  )} / 3)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
            {profile && !tutor && student && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    student.reviews.length,
                    3
                  )} / 3)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Submit/receive 3 reviews</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Connoisseur') &&
            !profile.claimedBadges.includes('Connoisseur') && (
              <button
                onClick={e => claimBadge('Connoisseur')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Referral}`}
            src={require(`../../../img/badge_Referral.png`)}
          />
          <h3>Referral</h3>
          <div className='tier-description'>
            <p className='small text-center'>
              Refer someone to Ingenify using your referral code
              {profile && (
                <span>
                  : <strong>{profile.referralCode.toUpperCase()}</strong>
                </span>
              )}
            </p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Referral') &&
            !profile.claimedBadges.includes('Referral') && (
              <button
                onClick={e => claimBadge('Referral')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Connected}`}
            src={require(`../../../img/badge_Connected.png`)}
          />
          <h3>Connected</h3>
          <div className='tier-description'>
            <p className='small text-center'>Follow Ingenify on social media</p>
            <form className='form' onSubmit={e => handleSocialMedia(e)}>
              <div className='form-group'>
                <input
                  type='text'
                  value={socialMediaHandle}
                  placeholder='@'
                  name='socialMedialHandle'
                  required
                  onChange={e => setSocialMediaHandle(e.target.value)}
                />
              </div>
            </form>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Connected') &&
            !profile.claimedBadges.includes('Connected') && (
              <button
                onClick={e => claimBadge('Connected')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
      </div>
      <div className='badge-list'>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Endurance}`}
            src={require(`../../../img/badge_Endurance.png`)}
          />
          <h3>Endurance</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.longestMeeting,
                    60
                  )} / 60)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Have a meeting for 1+ hours</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Endurance') &&
            !profile.claimedBadges.includes('Endurance') && (
              <button
                onClick={e => claimBadge('Endurance')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Marathon}`}
            src={require(`../../../img/badge_Marathon.png`)}
          />
          <h3>Marathon</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.longestMeeting,
                    120
                  )} / 120)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Have a meeting for 2+ hours</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Marathon') &&
            !profile.claimedBadges.includes('Marathon') && (
              <button
                onClick={e => claimBadge('Marathon')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Double_Tap}`}
            src={require(`../../../img/badge_Double_Tap.png`)}
          />
          <h3>Double Tap</h3>
          <div className='tier-description'>
            <p className='small text-center'>Have 2+ meetings in one week</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Double_Tap') &&
            !profile.claimedBadges.includes('Double_Tap') && (
              <button
                onClick={e => claimBadge('Double_Tap')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

NormalBadgesDisplay.propTypes = {
  profile: PropTypes.object.isRequired,
  student: PropTypes.object,
  tutor: PropTypes.object,
  checkSocial: PropTypes.func.isRequired,
  claimBadge: PropTypes.func.isRequired
};

export default connect(null, { checkSocial, claimBadge })(NormalBadgesDisplay);
