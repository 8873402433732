import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { getOrgs } from '../../actions/org';
import OrgItem from './OrgItem';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from '../layout/Footer';
import '../../App.css';

const Orgs = ({ org: { orgs, loading }, getOrgs, location }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const initialForm = {
    sort: ''
  };
  const [formData, setFormData] = useState(initialForm);
  const [redirect, setRedirect] = useState(false);
  const [newQuery, setNewQuery] = useState('');

  const onChange = e => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setRedirect(false);
  });
  useEffect(() => {
    getOrgs();
  }, []);

  //search and sort
  useEffect(() => {
    let { sort, page } = queryString.parse(location.search);

    let results = orgs.filter(org =>
      org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sort) {
      setFormData({ ...formData, sort });
      results.sort((a, b) => b[sort] - a[sort]);
    }
    if (page) {
      results = results.filter(
        (org, index) => index < 10 * page && index >= 10 * (page - 1)
      );
    }
    setSearchResults(results);
  }, [location.search, searchTerm, orgs]);

  const onSortChange = e => {
    e.preventDefault();
    setFormData({ ...formData, sort: e.target.value });
  };

  useEffect(() => {
    const { searchTerm } = queryString.parse(location.search);
    if (searchTerm) {
      setSearchTerm(searchTerm);
      setNewQuery(`searchTerm=${searchTerm}&sort=${formData.sort}`);
      return;
    }
    setNewQuery(`sort=${formData.sort}`);
  }, [formData.sort, location.search]);

  useEffect(() => {
    setRedirect(true);
  }, [newQuery]);

  if (redirect) {
    return <Redirect push to={`/organizations?${newQuery}`} />;
  }

  return (
    <div className='container'>
      <HelmetWrapper
        title='Organizations | Ingenify'
        description='This is the organizations page of Ingenify. It contains a list of all of the organizations on the site. There is also search available to be used to find an organization.'
      />
      <div className='orgs-page'>
        <div className='org-inner'>
          <div>
            <form className='form'>
              <input
                type='text'
                autoComplete='off'
                placeholder='Search for Organization'
                name='searchTerm'
                value={searchTerm}
                className='search-bar-org'
                onChange={e => onChange(e)}
              />
            </form>
            <Link to='/create/organizations'>Create Organization</Link>
            <div>
              Sort By{' '}
              <select
                value={formData.sort}
                name='sort'
                onChange={e => onSortChange(e)}
              >
                <option></option>
                <option value='hours'>Hours</option>
              </select>
            </div>
            {searchResults.map((org, index) => (
              <OrgItem org={org} key={index} />
            ))}
          </div>
        </div>
      </div>
      <Footer backgroundColor='#fff' />
    </div>
  );
};

Orgs.propTypes = {
  getOrgs: PropTypes.func.isRequired,
  org: PropTypes.object
};

const mapStateToProps = state => ({
  org: state.org
});

export default connect(mapStateToProps, { getOrgs })(Orgs);
