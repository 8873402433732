import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../../App.css';

const OrgMeeting = ({ meeting }) => {
  let date = meeting.startTime.split('T')[0];
  let time = meeting.startTime.split('T')[1];
  const dateParts = date.split('-');
  date = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]);
  const minutes = timeParts[1];
  if (hour >= 12) {
    if (hour === 12) {
      time = `${hour}:${minutes} PM`;
    } else {
      time = `${hour - 12}:${minutes} PM`;
    }
  } else {
    if (hour === 0) {
      time = `12:${minutes} AM`;
    } else {
      time = `${hour}:${minutes} AM`;
    }
  }
  return (
    <div className='personal-past-meeting meeting-item'>
      <div className='flex meeting-col'>
        <img src={meeting.org.orgImageUrl} alt='org' />
        <p className='px-1'>{meeting.org.name}</p>
      </div>
      <div className='flex hide-sm meeting-col'>
        <p>
          {date} at {time}
        </p>
      </div>
      <div className='flex right meeting-col'>
        <p>{meeting.duration} minutes</p>
      </div>
    </div>
  );
};

OrgMeeting.propTypes = {
  meeting: PropTypes.object.isRequired
};

export default OrgMeeting;
