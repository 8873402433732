import React, { useEffect } from 'react';
import '../../App.css';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from './Footer';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className='privacy container'>
      <HelmetWrapper
        title='Privacy Policy'
        description='This is the privacy policy of Ingenify.'
      />
      <div className='m-3'>
        <h1 className='text-center'>Privacy Policy</h1>
        <hr />
        <p>
          The mission at Ingenify (“we,” “us,” “our team,” “the platform”) is to
          offer an educational platform to all of our users. The Ingenify team
          is committed to secure student data privacy in full compliance with
          legal regulations. Our privacy principles below highlight how we
          collect and use information provided by students and educators that
          use our service.
        </p>
        <br />
        <p>I. Types of Collected Data</p>
        <br />
        <p>Personal data</p>
        <p>
          For the purposes of contact, authentication, and administration, we
          collect personal data of both students and tutors that are associated
          with our service. Collected personal data may include:
        </p>
        <ul>
          <li>Contact and profile information</li>
          <li>School enrollment</li>
          <li>Location information</li>
          <li>Bio description</li>
        </ul>
        <p>
          We hold user security with equal importance to user privacy. As a
          result, user identification, especially for educators using the
          platform, is required upon registration. In addition, Ingenify
          receives personal information from third party applications, in
          particular with Google for account verification. Collecting personal
          information is ultimately for the benefit of the user to optimize
          tailoring together students and educators, thus functionalities and
          features of the platform mandate the creation of a profile that
          contains the aforementioned inputs.
        </p>
        <br />
        <p>Technical Data</p>
        <p>
          Along with manually imputed information, interactions with the service
          through the website also provides Ingenify technical information that
          is collected automatically via browsers. Collected technical data
          include:
        </p>
        <ul>
          <li>
            Cookies, which track browser sessions and remember navigational
            preferences
          </li>
          <li>Log files to record sessions and events</li>
          <li>Service Use Data, which tracks referring and exiting pages</li>
        </ul>
        <p>
          Similar to the purposes of collected personal data, technical data
          provides security and enhanced personalization for users of this
          service. Log files and service use information help keep a track
          record to prevent malicious users and exploitations of information
          from other users. The automated process of collecting cookies further
          improves upon the experience of using the site and providing easier
          access to the platform.
        </p>
        <br />
        <p>II. Data Usage</p>
        <p>
          The collection of personal and technical data largely serves three
          purposes:
        </p>
        <ol>
          <li>
            Security- Collecting profile and contact information and Google
            sign-in provides the platform with the necessary procedures to
            enable a safe and secure environment for our users. Registering for
            a profile requires the user to provide basic information that can be
            used for identity authentication. Personal and technical data are
            also used for legal and applicable regulatory purposes.
          </li>
          <li>
            Education- An integral element of Ingenify is the pairing process of
            students and educators on the platform, which mandates basic
            information to be collected to fully use the service for educational
            purposes. Collecting basic data further ensures the standard of the
            educators on the site.
          </li>
          <li>
            User Experience- Technical data, in the form of cookies and service
            use information, yield important information that stores data to
            better polish the site experience. It is also essential to collect
            personal data for communication and contact for questions and
            inquiries regarding educators and our service itself. Moreover,
            device data grants us the ability for customer support and debugging
            analysis.
          </li>
        </ol>
        <br />
        <p>III. Sharing Data</p>
        <p>
          Personal and technical data collected from our users are not in any
          way disclosed, shared, accessed, or sold to other parties for
          commercial or malicious purposes. Only verified third party sources,
          through the usage of APIs, have access to data that is disclosed in
          the service. Ingenify does not use student data in any form of
          targeted advertising programs, and we do not monetize any acquired
          private information. As stated in the previous clause, all collected
          information is solely for the purposes of securing identity
          verification, education, and improving the user's technical
          experience. A user's profile information such as name, school, grade,
          and email will only be shared with the tutors that the user makes
          requests too for the purpose of contacting each other.
        </p>
        <p>
          All of our user data is maintained within a secure database protected
          with an SSH (secure shell). Data transmissions between our users are
          encrypted with a 256-bit SSL (secure sockets layer) between our user
          clients and the main server that create secure keys, impenetrable by
          malicious users and third-party software.
        </p>
        <br />
        <p>IV. Access, Update, or Deleting Personal Information</p>
        Users may choose to edit or delete their profiles at any time by
        accessing their profile settings via the Ingenify website. For minors,
        it is highly recommended for parents and guardians to review their
        profiles. If a user wishes to stop using this service, he or she has the
        freedom to terminate the associated account and remove all present
        information within his or her profile from the website.
        <p>
          Ingenify complies with the legal requirements set in place by the
          USDOE within the Requirements for Accessible Electronic and
          Information Technology Design.
        </p>
        <br />
        <p>V. Data Retention and Account Deletion</p>
        <p>
          It should be understood that Ingenify has the ability to retain
          personal information to better improve user experiences, even beyond
          the voluntary termination of an account. Personal information of a
          deleted account may be kept within an archived database for up to one
          month before being removed. That data is still subject to this privacy
          statement, and will not be publicized anywhere within the platform.
          However, data collected through the use of third party apps such as
          Zoom will be deleted if requested upon deauthorization.
        </p>
        <p>
          Voluntarily deleted accounts and their corresponding personal
          information will no longer be visible publicly on the site.
          Non-compliance with the Ingenify terms of service may result in
          involuntary deletion of accounts, in which the user can no longer
          access his or her profile or use any features through that account
          within the service.
        </p>
        <p>
          By request, personal data can be obfuscated and made anonymous within
          databases to completely remove an account from the platform. However,
          do note that such a step would be irreversible and cannot be
          recovered. Student performance data will remain to continue to improve
          our platform, but any private information and related accounts will be
          terminated.
        </p>
        <br />
        <p>VI. Unauthorized Disclosure</p>
        <p>
          Maintaining the security and privacy of our users is one of our most
          important objectives. On the occasion of a data breach/unauthorized
          disclosure, Ingenify will notify all users and all responsible parties
          of details of the incident, and we will take necessary action, in
          correspondence with applicable law enforcement, to deal with the
          breach. Any student or educator affected by the data breach will be
          notified immediately and will be followed up with further
          communication while the investigation. Compromised data will be
          deleted or made anonymous as per policies highlighted in section V.
        </p>
        <br />
        <p>VII. Children’s Privacy Policy</p>
        <p>
          As per regulation with the Children’s Online Privacy Protection Act
          (“COPPA”), Ingenify requires parental or guardian consent for students
          under the age of 13 to register and use an account. For users under
          13, we need to collect a parental email for regulation,
          administration, and communication. Registered parents are provided
          with access to the child’s account and profile information, and any
          parent to revoke or deny the consent of the child’s permission of
          using the account has full power to delete the account and its data.
        </p>
        <br />
        <p>VIII. Legal Compliance Statements</p>
        <ol>
          <li>COPPA- See the previous section.</li>
          <li>
            California Assembly Bill 1584- Ingenify recognizes and complies with
            the regulations outlined under AB 1584. All student information and
            records are the sole property of local educational agencies. On the
            occasion of a data breach as specified in section VI, we will notify
            local educational agencies that oversee the affected students.
          </li>
          <li>
            The Family Educational Rights and Privacy Act (FERPA)- Ingenify
            recognizes and complies with the responsibilities and protections of
            personal information, notably students’ records, as outlined under
            FERPA. Educators will be able to review student information.
          </li>
          <li>
            Students Online Personal Information Protection Act (SOPIPA)-
            Ingenify recognizes and complies with the regulations set in place
            by SOPIPA. We do not use any personal data in advertising or in
            transactions, nor do we sell any student information to third
            parties. For details on how we use student data, please see section
            III of this privacy statement.
          </li>
        </ol>
        <br />
        <p>IX. Updating this Privacy Policy</p>
        <p>
          The Ingenify team in the future may see it necessary to update or
          change this privacy policy to address discretion or to accommodate
          necessary changes. In the event of such a change, a notice will be
          issued to all users on the platform via provided emails and within the
          website at least thirty days prior to the modification. An effective
          date of last modification will be provided at the bottom of this
          statement.
        </p>
        <br />
        <p>X. Contact Us</p>
        <p>
          For any questions or inquiries regarding the specifics of this privacy
          statement and legal practices of Ingenify, please contact us at:
          support@ingenify.org
        </p>
        <br />
        <p>
          <em>Last updated: July 15, 2020</em>
        </p>
      </div>
      <Footer backgroundColor='#fff' />
    </section>
  );
};

export default Privacy;
