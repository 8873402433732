import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTutors } from '../../actions/tutor';
import queryString from 'query-string';
import TutorItem from './TutorItem';
import SidePanel from './SidePanel';
import Footer from '../layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoComplete from '../utils/AutoComplete';
import HelmetWrapper from '../utils/HelmetWrapper';
import '../../App.css';

const possibleSubjects = [
  'Elementary School Math',
  'Elementary School Reading and Writing',
  'Elementary School Science',
  'Elementary School Social Studies',
  'Middle School Math',
  'Middle School Reading and Writing',
  'Middle School Science',
  'Middle School Social Studies',
  'Algebra',
  'Geometry',
  'Precalculus',
  'Calculus',
  'Biology',
  'Chemistry',
  'Physics',
  'English and Literature',
  'Spanish',
  'French',
  'Chinese',
  'Computer Science',
  'SAT',
  'ACT',
  'Math',
  'Science',
  'Programming',
  'Reading and Writing',
  'AIME',
  'Competition Math',
  'AP Research',
  'AP Seminar',
  'AP Art and Design Program',
  'AP Art History',
  'AP Music Theory',
  'AP English Language and Composition',
  'AP English Literature and Composition',
  'AP Comparative Government and Politics',
  'AP European History',
  'AP Human Geography',
  'AP Macroeconomics',
  'AP Microeconomics',
  'AP Psychology',
  'AP United States Government and Politics',
  'AP United States History',
  'AP World History: Modern',
  'AP Calculus AB',
  'AP Calculus BC',
  'AP Computer Science A',
  'AP Computer Science Principles',
  'AP Statistics',
  'AP Biology',
  'AP Chemistry',
  'AP Environmental Science',
  'AP Physics 1',
  'AP Physics 2',
  'AP Physics C',
  'AP Chinese Language and Culture',
  'AP French Language and Culture',
  'AP German Language and Culture',
  'AP Italian Language and Culture',
  'AP Japanese Language and Culture',
  'AP Latin',
  'AP Spanish Language and Culture',
  'AP Spanish Literature and Culture'
];

const schools = [
  'Northview High School',
  'Northview Computer Science Honors Society',
  'Northview Science National Honors Society',
  'Northview MAO',
  'Northview Beta',
  'North Gwinnett High School',
  'North Gwinnett Beta'
];

const searchPlaceHolders = [
  'Search for tutors',
  'Search for subjects',
  'Search for APs',
  'Search for schools'
];

const shuffle = a => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

const kLimit = 0;
const kReduce = 10;
const k1 = 0.5;
const k2 = 1;
const k3 = 1;

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const Tutors = ({ tutor: { tutors, loading }, getTutors, location }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [completeResults, setCompleteResults] = useState([]);
  const [searchAhead, setSearchAhead] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [newQuery, setNewQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [placeHolderIndex, setPlaceHolderIndex] = useState(0);
  const perPage = 8;
  const listRef = useRef(null);
  const executeScroll = () => scrollToRef(listRef);
  const onChange = e => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (redirect) {
      setRedirect(false);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getTutors();
  }, []);

  useEffect(() => {
    if (tutors) {
      let tutorArray = [];
      tutors.forEach(tutor => tutorArray.push({ label: tutor.user.name }));
      setSearchAhead([...searchAhead, ...tutorArray]);
    }
  }, [tutors]);

  useEffect(() => {
    let constantArray = [];
    possibleSubjects.forEach(subject => constantArray.push({ label: subject }));
    schools.forEach(school => constantArray.push({ label: school }));
    setSearchAhead([...searchAhead, ...constantArray]);
  }, []);

  useEffect(() => {
    setSearchOptions(shuffle(searchAhead));
  }, [searchAhead]);

  useEffect(() => {
    setSearchOptions(
      searchAhead.filter(item =>
        item.label.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    );
  }, [searchAhead, searchTerm]);

  useEffect(() => {
    //search and filter
    let { subjects, grades, page, sort, ascending, s, availability } =
      queryString.parse(location.search);
    if (grades) {
      grades = grades.split(' ');
    }
    if (subjects) {
      subjects = subjects.split(' ');
    }
    if (availability) {
      availability = availability.split(' ');
    }
    let results = tutors;
    if (s) {
      results = tutors.filter(
        tutor =>
          tutor.user.name.toLowerCase().includes(s.toLowerCase()) ||
          tutor.profile.school.toLowerCase().includes(s.toLowerCase()) ||
          (tutor.affiliation &&
            tutor.affiliation.toLowerCase().includes(s.toLocaleLowerCase())) ||
          tutor.bio.toLowerCase().includes(s.toLocaleLowerCase()) ||
          (tutor.subjects &&
            tutor.subjects.join().toLowerCase().includes(s.toLowerCase())) ||
          (tutor.ap &&
            tutor.ap
              .map(ap => ap.courseName)
              .join()
              .toLowerCase()
              .includes(s.toLowerCase()))
      );
      setSearchTerm(s);
      setSearchQuery(s);
    } else {
      setSearchQuery('');
      setSearchTerm('');
    }
    if (grades) {
      results = results.filter(tutor => {
        let found = false;
        grades.forEach(grade => {
          if (tutor.profile.grade == grade) {
            found = true;
          }
        });
        return found;
      });
    }
    if (availability) {
      results = results.filter(tutor => {
        let found = false;
        availability.forEach(ava => {
          if (ava === 'shortTerm' && tutor.shortTerm) {
            found = true;
          }
          if (ava === 'longTerm' && tutor.longTerm) {
            found = true;
          }
        });
        return found;
      });
    }
    if (subjects) {
      results = results.filter(tutor => {
        let found = true;
        subjects.forEach(subject => {
          let foundSpecific = false;
          tutor.subjects.forEach(sub => {
            const newSub = sub.replace(/ /g, '_');
            if (subject === newSub) {
              foundSpecific = true;
            }
          });
          if (!foundSpecific) {
            found = false;
          }
        });
        return found;
      });
    }
    if (sort) {
      if (ascending) {
        results.sort((a, b) => a[sort] - b[sort]);
      } else {
        results.sort((a, b) => b[sort] - a[sort]);
      }
    } else {
      results.sort((a, b) => {
        let total1 = 20;
        let total2 = 20;
        if (a.students.length > 0) {
          total1 = k1 * a.students.length + k2 * a.rating + k3 * a.hours;
        }
        if (b.students.length > 0) {
          total2 = k1 * b.students.length + k2 * b.rating + k3 * b.hours;
        }
        return total2 - total1;
        // if (a.students.length > kLimit && b.students.length > kLimit) {
        //   return (
        //     k1 * (kReduce - Math.abs(kReduce - b.students.length)) +
        //     k2 * b.rating +
        //     k3 * b.hours -
        //     (k1 * (kReduce - Math.abs(kReduce - a.students.length)) +
        //       k2 * a.rating +
        //       k3 * a.hours)
        //   );
        // } else {
        //   if (a.students.length > kLimit) {
        //     return 1;
        //   } else if (b.students.length > kLimit) {
        //     return -1;
        //   } else {
        //     return (
        //       k1 * (kReduce - Math.abs(kReduce - b.students.length)) +
        //       k2 * b.rating +
        //       k3 * b.hours -
        //       (k1 * (kReduce - Math.abs(kReduce - a.students.length)) +
        //         k2 * a.rating +
        //         k3 * a.hours)
        //     );
        //   }
        // }
      });
    }
    let p = 1;
    if (page) {
      p = parseInt(page);
    }
    setPage(p);
    const maxPage = Math.ceil(results.length / perPage);
    if (p === 1) {
      let newPages = [];
      for (let i = 1; i <= Math.min(maxPage, 5); i++) {
        newPages.push(i);
      }
      setPages(newPages);
    } else if (p === 2) {
      let newPages = [];
      for (let i = 1; i <= Math.min(maxPage, 5); i++) {
        newPages.push(i);
      }
      setPages(newPages);
    } else if (p === 3) {
      let newPages = [];
      for (let i = 1; i <= Math.min(maxPage, 5); i++) {
        newPages.push(i);
      }
      setPages(newPages);
    } else if (p === 4) {
      let newPages = [];
      for (let i = 1; i <= Math.min(maxPage, 5); i++) {
        newPages.push(i);
      }
      setPages(newPages);
    } else if (p === maxPage - 1) {
      let newPages = [];
      for (let i = maxPage - 4; i <= maxPage; i++) {
        newPages.push(i);
      }
      setPages(newPages);
    } else if (p === maxPage) {
      let newPages = [];
      for (let i = maxPage - 4; i <= maxPage; i++) {
        newPages.push(i);
      }
      setPages(newPages);
    } else {
      let newPages = [];
      for (let i = p - 2; i <= p + 2; i++) {
        newPages.push(i);
      }
      setPages(newPages);
    }
    setCompleteResults(results);
    results = results.filter(
      (tutor, index) => index < perPage * p && index >= perPage * (p - 1)
    );
    setSearchResults(results);
  }, [location.search, tutors]);

  const newPage = (e, p) => {
    e.preventDefault();
    let { subjects, grades, sort, ascending, s, availability } =
      queryString.parse(location.search);
    if (p < 1 || p > pages[pages.length - 1]) {
      return;
    }
    let query = '';
    query = query + 'page=' + p;
    if (grades) {
      query = query + '&grades=' + grades;
    }
    if (subjects) {
      query = query + '&subjects=' + subjects;
    }
    if (sort) {
      query = query + '&sort=' + sort;
    }
    if (availability) {
      query = query + '&availability=' + availability;
    }
    if (ascending) {
      query = query + '&ascending=true';
    }
    if (s) {
      query = query + '&s=' + s;
    }
    if (query) {
      setRedirect(true);
      setNewQuery(query);
    }
  };

  const search = (e, s) => {
    e.preventDefault();
    setNewQuery(`s=${s}`);
    setRedirect(true);
  };

  const toggleSuggestions = e => {
    if (e.target.name === 'searchTerm') {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceHolderIndex(
        placeHolderIndex => (placeHolderIndex + 1) % searchPlaceHolders.length
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  if (redirect) {
    return <Redirect push to={`tutors?${newQuery}`} />;
  }

  return (
    <div className='container' onClick={e => toggleSuggestions(e)}>
      <HelmetWrapper
        title='Tutors | Ingenify'
        description='This is the tutors page of Ingenify. It contains a list of all of the public tutors on the site. There are also search and filters available to be used to find a tutor.'
      />
      <div className='tutor-banner'>
        {/* <div className='tutor-title'>
          <p>Search for a Tutor</p>
        </div> */}
        <div className='search has-search' ref={listRef}>
          <form className='form' onSubmit={e => search(e, searchTerm)}>
            <FontAwesomeIcon
              icon='search'
              className='form-control-feedback-2'
            />
            <input
              type='text'
              autoComplete='off'
              placeholder={searchPlaceHolders[placeHolderIndex]}
              className='search-bar form-control-2'
              name='searchTerm'
              value={searchTerm}
              onChange={e => onChange(e)}
            />
            {showSuggestions && (
              <div className='search-suggestions'>
                <AutoComplete options={searchOptions} onClick={search} />
              </div>
            )}
          </form>
        </div>
      </div>
      <div className='flex tutors-page'>
        <div>
          <SidePanel location={location} />
        </div>
        <div className='profile-list'>
          {searchQuery !== '' && !loading && (
            <h1>
              {completeResults.length} Results for "{searchQuery}"
            </h1>
          )}
          {searchQuery === '' && !loading && completeResults.length === 0 && (
            <h1>0 Results</h1>
          )}
          {!loading && tutors ? (
            <div>
              {searchResults.map((tutor, index) => (
                <TutorItem tutor={tutor} key={index} />
              ))}
            </div>
          ) : null}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <ul className='pagination'>
              <li
                onClick={e => {
                  newPage(e, page - 1);
                  executeScroll();
                }}
              >
                {page > 1 && <FontAwesomeIcon icon='chevron-left' />}
              </li>
              {pages.map((p, index) =>
                p === page ? (
                  <li
                    className='active'
                    key={index}
                    onClick={e => {
                      newPage(e, p);
                      executeScroll();
                    }}
                  >
                    {p}
                  </li>
                ) : (
                  <li
                    key={index}
                    onClick={e => {
                      newPage(e, p);
                      executeScroll();
                    }}
                  >
                    {p}
                  </li>
                )
              )}
              <li
                onClick={e => {
                  newPage(e, page + 1);
                  executeScroll();
                }}
              >
                {page < pages[pages.length - 1] && (
                  <FontAwesomeIcon icon='chevron-right' />
                )}
              </li>
            </ul>
          </div>
          <Footer backgroundColor='#fff' />
        </div>
      </div>
    </div>
  );
};

Tutors.propTypes = {
  getTutors: PropTypes.func.isRequired,
  tutor: PropTypes.object
};

const mapStateToProps = state => ({
  tutor: state.tutor
});

export default connect(mapStateToProps, { getTutors })(Tutors);
