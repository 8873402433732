import React from 'react';

const PastMeetingItem = ({ meeting, isTutor }) => {
  let date = meeting.startTime.split('T')[0];
  let time = meeting.startTime.split('T')[1];
  const dateParts = date.split('-');
  date = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]);
  const minutes = timeParts[1];
  if (hour >= 12) {
    if (hour === 12) {
      time = `${hour}:${minutes} PM`;
    } else {
      time = `${hour - 12}:${minutes} PM`;
    }
  } else {
    if (hour === 0) {
      time = `12:${minutes} AM`;
    } else {
      time = `${hour}:${minutes} AM`;
    }
  }
  return (
    <div>
      {isTutor ? (
        <div>
          <div className='personal-past-meeting meeting-item'>
            <div className='flex meeting-col'>
              <img src={meeting.student.profileImageUrl} alt='student' />
              <p className='px-1'>{meeting.student.name}</p>
            </div>
            <div className='flex hide-sm meeting-col'>
              <p>
                {date} at {time} {meeting.timezone}
              </p>
            </div>
            <div className='flex right meeting-col'>
              <div className='m-auto-vert'>
                <span className='highligh'>{meeting.duration} minutes</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='personal-past-meeting meeting-item'>
            <div className='flex meeting-col'>
              <img src={meeting.tutor.profileImageUrl} alt='tutor' />
              <p className='px-1'>{meeting.tutor.name}</p>
            </div>
            <div className='flex hide-sm meeting-col'>
              <p>
                {date} at {time} {meeting.timezone}
              </p>
            </div>
            <div className='flex right meeting-col'>
              <div className='m-auto-vert'>
                <span className='highligh'>{meeting.duration} minutes</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PastMeetingItem;
