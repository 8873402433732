import React, { useState, useRef, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import ChatMessage from './ChatMessage';

const ChatBox = ({ chat, submitChatMessage }) => {
  const [msg, setMsg] = useState('');
  const messagesEndRef = useRef(null);
  const formRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [chat.messages]);
  const onSubmit = e => {
    e.preventDefault();
    submitChatMessage(e, msg);
    setMsg('');
  };

  const onChange = e => {
    e.preventDefault();
    setMsg(e.target.value);
  };

  const onEnterPress = e => {
    if (e.keyCode == 13 && !e.shiftKey) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  return (
    <div>
      <div className='chat-box'>
        <ul>
          {chat.messages.map((message, index) => (
            <li key={index}>
              <ChatMessage message={message} />
            </li>
          ))}
        </ul>
        <div ref={messagesEndRef}></div>
      </div>
      <form
        className='form chat-box-bottom'
        autoComplete='off'
        ref={formRef}
        onSubmit={e => onSubmit(e)}
      >
        <TextareaAutosize
          type='text'
          maxRows='3'
          minRows='1'
          className='chat-msg'
          placeholder='Send A Message'
          value={msg}
          onChange={e => onChange(e)}
          name='msg'
          onKeyDown={e => onEnterPress(e)}
          required
        />
        <div className='form-group'>
          <input
            type='submit'
            className='btn btn-secondary hide-lg'
            style={{ width: '100%' }}
            value='Send'
          />
        </div>
      </form>
    </div>
  );
};

export default ChatBox;
