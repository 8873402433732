import React, { useEffect } from 'react';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from './Footer';
import '../../App.css';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container'>
      <HelmetWrapper
        title='About'
        description='This is the about page of Ingenify.'
      />
      <div className='about-banner'>
        <h1 className='about-banner-text large'>
          Ingenify connects students with great tutors for free.
        </h1>
      </div>
      <div className='about-team my-2'>
        <h1 className='text-center my'>Our Team</h1>
        <div className='about-team-members my-1'>
          <div className='about-team-member'>
            <div className='flex-justify-center'>
              <img
                src={require('../../img/jatong.png')}
                className='team-member-img'
              />
            </div>
            <p className='medium text-center'>
              <strong>President</strong>
            </p>
            <p className='team-member-description text-center'>
              Jatong Su is passionate about computer science and
              entrepreneurship. He enjoys problem solving and developed Ingenify
              to combat issues of educational inaccessibility.
            </p>
          </div>
          <div className='about-team-member'>
            <div className='flex-justify-center'>
              <img
                src={require('../../img/paul.jpg')}
                className='team-member-img'
              />
            </div>
            <p className='medium text-center'>
              <strong>Director of Outreach</strong>
            </p>
            <p className='team-member-description text-center'>
              Paul Philip is passionate about community service and
              entrepreneurship. He was drawn to Ingenify’s core vision and hopes
              to continue to grow the program.
            </p>
          </div>
          <div className='about-team-member'>
            <div className='flex-justify-center'>
              <img
                src={require('../../img/benson.png')}
                className='team-member-img'
              />
            </div>
            <p className='medium text-center'>
              <strong>Director of Operations</strong>
            </p>
            <p className='team-member-description text-center'>
              Benson Zhang has great interest in technology and the humanities.
              He wishes that his efforts in Ingenify will create a positive
              community impact in using modern solutions for education.
            </p>
          </div>
          <div className='about-team-member'>
            <div className='flex-justify-center'>
              <img
                src={require('../../img/bedansh.jpg')}
                className='team-member-img'
              />
            </div>
            <p className='medium text-center'>
              <strong>Director of Communications</strong>
            </p>
            <p className='team-member-description text-center'>
              Bedansh Pandey has interests in language arts and social studies.
              He hopes students can not only gain not only an understanding of
              concepts but also apply them creatively.
            </p>
          </div>
        </div>
        <div className='about-team-members'>
          <div className='about-team-member'>
            <div className='flex-justify-center'>
              <img
                src={require('../../img/ali.jpg')}
                className='team-member-img'
              />
            </div>
            <p className='medium text-center'>
              <strong>Marketing Lead</strong>
            </p>
            <p className='team-member-description text-center'>
              Ali Addis is interested in marketing and entrepreneurship. He
              joined Ingenify with the goal of making positive change in the
              community. He enjoys watching MMA and basketball in his free time.
            </p>
          </div>
          <div className='about-team-member'>
            <div className='flex-justify-center'>
              <img
                src={require('../../img/grace.png')}
                className='team-member-img'
              />
            </div>
            <p className='medium text-center'>
              <strong>Design Lead</strong>
            </p>
            <p className='team-member-description text-center'>
              Grace Peng's biggest joys at Ingenify are meeting new students and
              designing its logo and graphics. She also delights in taking
              action shots of the team and occasionally annoying Jatong.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
