import React from 'react';
import { deleteMeeting } from '../../../actions/tutor';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const UpcomingMeetingItem = ({ meeting, isTutor, deleteMeeting }) => {
  let date = meeting.startTime.split('T')[0];
  let time = meeting.startTime.split('T')[1];
  const dateParts = date.split('-');
  date = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]);
  const minutes = timeParts[1];
  if (hour >= 12) {
    if (hour === 12) {
      time = `${hour}:${minutes} PM`;
    } else {
      time = `${hour - 12}:${minutes} PM`;
    }
  } else {
    if (hour === 0) {
      time = `12:${minutes} AM`;
    } else {
      time = `${hour}:${minutes} AM`;
    }
  }
  const handleDelete = e => {
    deleteMeeting(meeting._id);
  };
  return (
    <div>
      {isTutor ? (
        <div>
          <div className='personal-upcoming-meeting meeting-item'>
            <div className='flex meeting-col'>
              <img src={meeting.student.profileImageUrl} alt='student' />
              <p className='px-1'>{meeting.student.name}</p>
            </div>
            <div className='flex meeting-col'>
              <p>
                {date} at {time} {meeting.timezone}
              </p>
            </div>
            <div className='flex-column m-auto-vert meeting-col'>
              <div className='flex right'>
                <a
                  href={meeting.startUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='btn btn-secondary'>Start Meeting</button>
                </a>
              </div>
              <div
                className='flex right hide-sm'
                style={{ marginTop: '0.5rem' }}
              >
                <button className='btn btn-text' onClick={e => handleDelete(e)}>
                  Delete Meeting
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='personal-upcoming-meeting meeting-item'>
            <div className='flex meeting-col'>
              <img src={meeting.tutor.profileImageUrl} alt='tutor' />
              <p className='px-1'>{meeting.tutor.name}</p>
            </div>
            <div className='flex meeting-col'>
              <p>
                {date} at {time} {meeting.timzone}
              </p>
            </div>
            <div className='flex right meeting-col'>
              <div className='m-auto-vert'>
                <a
                  href={meeting.joinUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='btn btn-secondary'>Join Meeting</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

UpcomingMeetingItem.propTypes = {
  deleteMeeting: PropTypes.func.isRequired
};

export default connect(null, { deleteMeeting })(UpcomingMeetingItem);
