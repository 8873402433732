import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getOrgById, updateOrg } from '../../../actions/org';
import OrgHeader from './OrgHeader';
import OrgNav from './OrgNav';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const EditOrg = ({
  org: { org, loading, view },
  getOrgById,
  updateOrg,
  match
}) => {
  useEffect(() => {
    if (match.params.id) {
      getOrgById(match.params.id);
    }
  }, [match.params.id]);

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (view !== 0 && !loading) {
      setRedirect(true);
    }
  }, [view, loading]);

  const initialForm = {
    name: '',
    description: '',
    orgImageUrl: '',
    email: '',
    website: '',
    enrollmentCode: '',
    timezone: 'EST'
  };

  const [formData, setFormData] = useState(initialForm);

  useEffect(() => {
    if (org && !loading) {
      const orgData = { ...initialForm };
      for (const key in org) {
        if (key in orgData) orgData[key] = org[key];
      }
      setFormData(orgData);
    }
  }, [loading, org]);

  const onSubmit = e => {
    e.preventDefault();
    updateOrg(formData, org._id);
  };

  const onChange = e => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const {
    name,
    description,
    orgImageUrl,
    email,
    website,
    enrollmentCode,
    timezone
  } = formData;

  if (redirect) {
    return <Redirect push to={`/organizations/${org._id}`} />;
  }

  return (
    <div className='container colored'>
      {org ? (
        <HelmetWrapper
          title={`${org.name} | Ingenify`}
          description='This is where the organization can make edits to its profile.'
        />
      ) : (
        <HelmetWrapper
          title={`Organization | Ingenify`}
          description='This is where the organization can make edits to its profile.'
        />
      )}
      <div className='flex-column-center'>
        {org && (
          <div className='org-width'>
            <OrgHeader org={org} view={view} />
            <OrgNav org={org} view={view} />
            <div className='org-body'>
              <div className='org-form-edit'>
                <h1 className='lead'>Update Organization</h1>
                <form className='form' onSubmit={e => onSubmit(e)}>
                  <div className='form-group'>
                    <p className='small text-gray'>
                      Name
                      {name === '' && <span className='text-danger'>*</span>}
                    </p>
                    <input
                      type='text'
                      name='name'
                      placeholder='Name'
                      value={name}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <p className='small text-gray'>
                      Description
                      {description === '' && (
                        <span className='text-danger'>*</span>
                      )}
                    </p>
                    <textarea
                      name='description'
                      placeholder='Description'
                      value={description}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <p className='small text-gray'>
                      Email
                      {email === '' && <span className='text-danger'>*</span>}
                    </p>
                    <input
                      type='text'
                      name='email'
                      placeholder='Email'
                      value={email}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <p className='small text-gray'>Website</p>
                    <input
                      type='text'
                      name='website'
                      placeholder='https://'
                      value={website}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <p className='small text-gray'>Image Url</p>
                    <input
                      type='text'
                      name='orgImageUrl'
                      placeholder='https://'
                      value={orgImageUrl}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className='form-group'>
                    <p className='small text-gray'>
                      Enrollment Code
                      {enrollmentCode === '' && (
                        <span className='text-danger'>*</span>
                      )}
                    </p>
                    <input
                      type='text'
                      name='enrollmentCode'
                      placeholder='Enrollment Code'
                      value={enrollmentCode}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                  <div>
                    <p className='small text-gray'>Timezone</p>
                    <select
                      name='timezone'
                      value={timezone}
                      onChange={e => onChange(e)}
                      style={{ width: 'auto' }}
                      required
                    >
                      <option value='EST'>Eastern</option>
                      <option value='CST'>Central</option>
                      <option value='MST'>Mountain</option>
                      <option value='PST'>Pacific</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <input
                      className='btn btn-secondary'
                      type='submit'
                      value='Update'
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

EditOrg.propTypes = {
  getOrgById: PropTypes.func.isRequired,
  updateOrg: PropTypes.func.isRequired,
  org: PropTypes.object
};

const mapStateToProps = state => ({
  org: state.org
});

export default connect(mapStateToProps, { getOrgById, updateOrg })(EditOrg);
