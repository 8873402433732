import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import PropTypes from 'prop-types';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = ({ logout, auth: { isAuthenticated, loading, user } }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRedirect(false);
  });

  const handleLogin = e => {
    e.preventDefault();
    window.open('https://ingenify.org/api/auth/google');
    // window.open('http://localhost:5000/api/auth/google');
  };
  const handleLogout = e => {
    e.preventDefault();
    setRedirect(true);
    logout();
  };
  if (redirect) {
    return <Redirect push to='/' />;
  }
  const authLinks = (
    <ul>
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/'>
            <FontAwesomeIcon icon='home' />
          </Link>
          <span className='icon-text text-home'>Home</span>
        </div>
      </li>
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/tutors'>
            <FontAwesomeIcon icon='user' />
          </Link>
          <span className='icon-text text-tutors'>Tutors</span>
        </div>
      </li>
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/organizations'>
            <FontAwesomeIcon icon='users' />
          </Link>
          <span className='icon-text text-organizations'>Organizations</span>
        </div>
      </li>
      {/* <li className='nav-flex-column-center'>
        <Link to='/chat'>
          <FontAwesomeIcon icon='comments' title='Chat' />
        </Link>
      </li> */}
      {/* <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/partners'>
            <FontAwesomeIcon icon='hands-helping' />
          </Link>
          <span className='icon-text text-partners'>Partners</span>
        </div>
      </li> */}
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/faq'>
            <FontAwesomeIcon icon='question' />
          </Link>
          <span className='icon-text text-faq'>FAQ</span>
        </div>
      </li>
      {user ? (
        <li className='nav-flex-column-center'>
          <div className='icon-wrapper'>
            <div className='nav-img'>
              <Link to='/account/dashboard'>
                <img
                  className='nav-account-img round-img'
                  src={user.profileImageUrl}
                  alt='account'
                />
              </Link>
            </div>
            <span className='icon-text text-account'>Account</span>
          </div>
        </li>
      ) : null}
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/' onClick={e => handleLogout(e)}>
            <FontAwesomeIcon icon='sign-out-alt' />
          </Link>
          <span className='icon-text text-sign-out'>Sign Out</span>
        </div>
      </li>
    </ul>
  );
  const guestLinks = (
    <ul>
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/'>
            <FontAwesomeIcon icon='home' />
          </Link>
          <span className='icon-text text-home'>Home</span>
        </div>
      </li>
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/tutors'>
            <FontAwesomeIcon icon='user' />
          </Link>
          <span className='icon-text text-tutors'>Tutors</span>
        </div>
      </li>
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/organizations'>
            <FontAwesomeIcon icon='users' />
          </Link>
          <span className='icon-text text-organizations'>Organizations</span>
        </div>
      </li>
      {/* <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/partners'>
            <FontAwesomeIcon icon='hands-helping' />
          </Link>
          <span className='icon-text text-partners'>Partners</span>
        </div>
      </li> */}
      <li className='nav-flex-column-center'>
        <div className='icon-wrapper'>
          <Link to='/faq'>
            <FontAwesomeIcon icon='question' />
          </Link>
          <span className='icon-text text-faq'>FAQ</span>
        </div>
      </li>
      <li className='nav-flex-column-center'>
        <div className='nav-img'>
          <img
            src={require('../../img/google_sign_in.png')}
            onClick={e => handleLogin(e)}
            alt='login'
          />
        </div>
      </li>
    </ul>
  );
  return (
    <nav className='navbar'>
      <h1>
        <ul>
          <li>
            <div className='nav-img'>
              <Link to='/'>
                <img src={require('../../img/ingenify-logo.png')} alt='logo' />
              </Link>
            </div>
          </li>
          <li>
            <a
              href='https://gf.me/u/y74zjm'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button className='btn btn-success'>Donate</button>
            </a>
          </li>
        </ul>
      </h1>
      {!loading ? (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      ) : null}
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Navbar);
