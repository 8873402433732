import axios from 'axios';
import { setAlert } from './alert';
import { GET_TUTOR, GET_TUTORS, TUTOR_ERROR, CLEAR_TUTOR } from './types';

export const getCurrentTutor = () => async dispatch => {
  try {
    await axios.get('/api/auth/zoom/refresh');
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
  try {
    const res = await axios.get('/api/tutor/me');
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
  } catch (err) {
    // const error = err.response.data.msg;
    // dispatch(setAlert(error, 'danger'));
    dispatch({
      type: TUTOR_ERROR
    });
  }
};

export const createTutor = formData => async dispatch => {
  try {
    const res = await axios.post('/api/tutor', formData);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Tutoring Profile Updated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;
    errors.forEach(error => {
      dispatch(setAlert(error.msg, 'danger'));
    });
    // dispatch({
    //   type: TUTOR_ERROR
    // });
  }
};

export const deleteTutor = () => async dispatch => {
  try {
    await axios.delete('/api/tutor');
    dispatch({
      type: TUTOR_ERROR
    });
    dispatch(setAlert('Tutoring Profile Deleted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
    dispatch({
      type: TUTOR_ERROR
    });
  }
};

export const getTutors = () => async dispatch => {
  dispatch({
    type: CLEAR_TUTOR
  });
  try {
    const res = await axios.get('/api/tutor');
    dispatch({
      type: GET_TUTORS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TUTOR_ERROR
    });
  }
};

export const getTutorById = userId => async dispatch => {
  try {
    const res = await axios.get(`/api/tutor/${userId}`);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
    dispatch({
      type: TUTOR_ERROR
    });
  }
};

export const removeStudent = userId => async dispatch => {
  try {
    const res = await axios.get(`/api/tutor/unenroll/${userId}`);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Student Removed', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const acceptStudent = userId => async dispatch => {
  try {
    const res = await axios.get(`/api/tutor/accept/${userId}`);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Student Accepted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const declineStudent = userId => async dispatch => {
  try {
    const res = await axios.get(`/api/tutor/decline/${userId}`);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Student Declined', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const scheduleMeeting = (userId, formData) => async dispatch => {
  try {
    await axios.get('/api/auth/zoom/refresh');
    const res = await axios.post(`/api/tutor/meeting/${userId}`, formData);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Meeting Scheduled', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const deleteMeeting = meetingId => async dispatch => {
  try {
    await axios.get('/api/auth/zoom/refresh');
    const res = await axios.delete(`/api/tutor/meeting/${meetingId}`);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Meeting Deleted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const createReview = (tutorId, formData) => async dispatch => {
  try {
    const res = await axios.post(`/api/tutor/${tutorId}/review`, formData);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Review Submitted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const deleteReview = (tutorId, reviewId) => async dispatch => {
  try {
    const res = await axios.delete(`/api/tutor/${tutorId}/review/${reviewId}`);
    dispatch({
      type: GET_TUTOR,
      payload: res.data
    });
    dispatch(setAlert('Review Deleted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};
