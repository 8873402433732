import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { getTutors } from '../../actions/tutor';
import AutoComplete from '../utils/AutoComplete';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from './Footer';
import Banner from './Banner';
import '../../App.css';

const possibleSubjects = [
  'Elementary School Math',
  'Elementary School Reading and Writing',
  'Elementary School Science',
  'Elementary School Social Studies',
  'Middle School Math',
  'Middle School Reading and Writing',
  'Middle School Science',
  'Middle School Social Studies',
  'Algebra',
  'Geometry',
  'Precalculus',
  'Calculus',
  'Biology',
  'Chemistry',
  'Physics',
  'English and Literature',
  'Spanish',
  'French',
  'Chinese',
  'Computer Science',
  'SAT',
  'ACT',
  'Math',
  'Science',
  'Programming',
  'Reading and Writing',
  'AIME',
  'Competition Math',
  'AP Research',
  'AP Seminar',
  'AP Art and Design Program',
  'AP Art History',
  'AP Music Theory',
  'AP English Language and Composition',
  'AP English Literature and Composition',
  'AP Comparative Government and Politics',
  'AP European History',
  'AP Human Geography',
  'AP Macroeconomics',
  'AP Microeconomics',
  'AP Psychology',
  'AP United States Government and Politics',
  'AP United States History',
  'AP World History: Modern',
  'AP Calculus AB',
  'AP Calculus BC',
  'AP Computer Science A',
  'AP Computer Science Principles',
  'AP Statistics',
  'AP Biology',
  'AP Chemistry',
  'AP Environmental Science',
  'AP Physics 1',
  'AP Physics 2',
  'AP Physics C',
  'AP Chinese Language and Culture',
  'AP French Language and Culture',
  'AP German Language and Culture',
  'AP Italian Language and Culture',
  'AP Japanese Language and Culture',
  'AP Latin',
  'AP Spanish Language and Culture',
  'AP Spanish Literature and Culture'
];

const popularSubjects = [
  'Algebra',
  'Reading and Writing',
  'Computer Science',
  'Geometry',
  'Science'
];

const shuffle = a => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

const schools = [
  'Northview High School',
  'Northview Computer Science Honors Society',
  'Northview Science National Honors Society',
  'Northview Beta',
  'Northview MAO',
  'North Gwinnett High School',
  'North Gwinnett Beta'
];

const searchPlaceHolders = [
  'Search for tutors',
  'Search for subjects',
  'Search for APs',
  'Search for schools'
];

const Landing = ({ tutor: { tutors, loading }, getTutors, auth }) => {
  const handleLogin = e => {
    e.preventDefault();
    window.open('https://ingenify.org/api/auth/google');
    // window.open('http://localhost:5000/api/auth/google');
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [newQuery, setNewQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchAhead, setSearchAhead] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [placeHolderIndex, setPlaceHolderIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //search options
  useEffect(() => {
    if (tutors) {
      let tutorArray = [];
      tutors.forEach(tutor => tutorArray.push({ label: tutor.user.name }));
      setSearchAhead([...searchAhead, ...tutorArray]);
    }
  }, [tutors]);

  useEffect(() => {
    let constantArray = [];
    possibleSubjects.forEach(subject => constantArray.push({ label: subject }));
    schools.forEach(school => constantArray.push({ label: school }));
    setSearchAhead([...searchAhead, ...constantArray]);
  }, []);

  useEffect(() => {
    setSearchOptions(shuffle(searchAhead));
  }, [searchAhead]);

  useEffect(() => {
    setSearchOptions(
      searchAhead.filter(item =>
        item.label.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    );
  }, [searchAhead, searchTerm]);

  const onChange = e => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const search = (e, s) => {
    e.preventDefault();
    setNewQuery(s);
    setRedirect(true);
  };

  const toggleSuggestions = e => {
    if (e.target.name === 'searchTerm') {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    getTutors();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceHolderIndex(
        placeHolderIndex => (placeHolderIndex + 1) % searchPlaceHolders.length
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  if (redirect) {
    return <Redirect push to={`/tutors?s=${newQuery}`} />;
  }

  return (
    <div className='container landing' onClick={e => toggleSuggestions(e)}>
      <HelmetWrapper
        title='Ingenify - Free Online Tutoring'
        description='Ingenify is a virtual tutoring platform where students can receive free online tutoring from qualified high school students looking to help the community.'
      />
      {/* <Banner /> */}
      {/* First Page */}
      <section className='showcase'>
        <div className='landing-search has-search'>
          <form className='form' onSubmit={e => search(e, searchTerm)}>
            <FontAwesomeIcon icon='search' className='form-control-feedback' />
            <input
              type='text'
              autoComplete='off'
              placeholder={searchPlaceHolders[placeHolderIndex]}
              className='landing-search-bar form-control'
              name='searchTerm'
              value={searchTerm}
              onChange={e => onChange(e)}
            />
            {showSuggestions && (
              <div className='search-suggestions'>
                <AutoComplete
                  options={searchOptions}
                  onClick={search}
                  length={10}
                />
              </div>
            )}
          </form>
          {/* {auth && !auth.isAuthenticated && (
            <button
              // src={require('../../img/google_sign_in.png')}
              onClick={e => handleLogin(e)}
              className='btn btn-success my hide-big'
              style={{ width: '80%' }}
              // alt='login'
            >
              {' '}
              Get Started{' '}
            </button>
          )} */}
        </div>
        <div className='explore'>
          <h1>Find a tutor</h1>
          <p>
            <strong>Search through dozens of qualified tutors</strong>
          </p>
          <button className='btn btn-light' style={{ borderRadius: '0.25rem' }}>
            <Link to='/tutors'>
              <strong style={{ color: '#333' }}>Explore tutors</strong>
            </Link>
          </button>
        </div>
        {auth && !auth.isAuthenticated && (
          <div className='sign-in'>
            {/* <div className='icon-wrapper'>
              <p className='small'>
                Sign in with a non-school issued (personal) google account
              </p>
              <span className='show-top text-explanation'>
                School emails won't receive emails from tutors or notifications
                from Ingenify
              </span>
            </div> */}
            {/* <hr style={{ marginBottom: '0.5rem' }} /> */}
            <button
              // src={require('../../img/google_sign_in.png')}
              onClick={e => handleLogin(e)}
              className='btn btn-secondary my'
              style={{ width: '100%' }}
              // alt='login'
            >
              {' '}
              Get Started{' '}
            </button>
            <hr />
            <p className='text-gray small'>
              By signing in, you agree to our{' '}
              <Link to='/terms_of_service'>terms of service</Link> and{' '}
              <Link to='/privacy_policy'>privacy policy</Link>
            </p>
          </div>
        )}
      </section>
      <br />
      {/* Second Page */}
      {/* <div className='page-two'>
        <div className='column'>
          <h1>Who we are</h1>
          <br />
          <p>
            Ingenify is a platform where students can search for and connect
            with tutors from a multitude established nonprofit organizations. We
            offer a maketplace for free tutoring that exsits nowhere else.
          </p>
        </div>
        <div>
          <img src={require('../../img/laptop.png')} alt='virtual-tutoring' />
        </div>
      </div> */}
      {/* Third Page */}
      <div className='student-promotion'>
        <div className='page-three'>
          <h1>What We Offer</h1>
          <div className='three-col'>
            <div className='col'>
              <img src={require('../../img/tutor.png')} alt='tutor' />
              <h2>Free Tutoring</h2>
              <p className='text-gray'>
                Choose a tutor from our list of high achieving tutors that fits
                your need. Simply sign up, create your profile, and then start
                sending requests to the tutors that you want to learn from.
              </p>
            </div>
            <div className='col'>
              <img
                src={require('../../img/subjects.png')}
                alt='organizations'
              />
              <h2>Organizations</h2>
              <p className='text-gray'>
                We offer a platform where established organizations can adapt to
                the increasingly virtual environment. Manage members, send
                announcments, and hold meetings all on one site.
              </p>
            </div>
            <div className='col'>
              <img src={require('../../img/meetings.png')} alt='meetings' />
              <h2>Meetings</h2>
              <p className='text-gray'>
                We enable students and tutors to connect via messaging and video
                calling directly from our platform to ensure the best
                educational experience technology can offer. Interact in real
                time, face-to-face.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Page Four */}
      <div className='page-four'>
        <h1>1 on 1 tutoring from the comfort of your home</h1>
      </div>

      {/* Press */}
      <div>
        <h1 className='press-heading'>As Seen On</h1>
        <div className='press-banner'>
          <a
            href='https://www.ajc.com/education/with-time-to-spare-during-covid-19-students-offer-free-tutoring/OQQH6NG26BGONG5S75AC6FEJPM/'
            style={{ width: '20%' }}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={require('../../img/ajc.png')}
              alt='ajc'
              style={{ width: '100%' }}
            />
          </a>
          <a
            href='https://www.wabe.org/georgia-high-school-students-create-online-tutoring-platform-amid-the-pandemic/'
            style={{ width: '20%' }}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={require('../../img/npr.png')}
              alt='npr'
              style={{ width: '100%' }}
            />
          </a>
          <a
            href='https://www.fox5atlanta.com/news/georgia-students-create-free-tutoring-site'
            style={{ width: '20%' }}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={require('../../img/fox5.jpg')}
              alt='fox5'
              style={{ width: '100%' }}
            />
          </a>
          <a
            href='https://www.appenmedia.com/community/teens-create-all-in-one-directory-to-match-students-with-tutors/article_715260ca-2856-11eb-9619-07ef82844a70.html'
            style={{ width: '20%' }}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={require('../../img/johns_creek_herald.png')}
              alt='herald'
              style={{ width: '100%' }}
            />
          </a>
        </div>
      </div>

      {/* Student Stuff */}
      <div className='student-promotion'>
        <div className='page-student'>
          <h1>Want to Learn?</h1>
          <div className='flex-justify-center'>
            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/CJRAo7BCgWw'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
          <div className='three-col-2'>
            <div className='col-2'>
              <img
                src={require('../../img/profile_activate.png')}
                alt='profile'
              />
              <h2>Create a Profile</h2>
              <p className='lead text-gray'>
                Complete your profile by filling out your school, grade, and
                state.
              </p>
            </div>
            <div className='col-2'>
              <img
                src={require('../../img/connect.png')}
                alt='connect-tutors'
              />
              <h2>Search for Tutors</h2>
              <p className='lead text-gray'>
                Search for the tutors and organizations you want to learn from.
              </p>
            </div>
            <div className='col-2'>
              <img src={require('../../img/enroll.png')} alt='enroll' />
              <h2>Request and Enroll</h2>
              <p className='lead text-gray'>
                Send a request message to enroll with the tutor or organization.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Subjects */}
      {/* <div className='popular-subjects'>
        <ul className='boxes'>
          <li style={{ backgroundColor: 'pink' }}>
            <strong>Algebra</strong>
          </li>
          <li style={{ backgroundColor: 'blue' }}>
            <strong>Reading</strong>
          </li>
          <li style={{ backgroundColor: 'green' }}>
            <strong>Computer Science</strong>
          </li>
          <li style={{ backgroundColor: 'red' }}>
            <strong>Geometry</strong>
          </li>
          <li style={{ backgroundColor: 'black' }}>
            <strong>Science</strong>
          </li>
        </ul>
      </div> */}

      {/* Page Five */}
      <div className='tutor-promotion'>
        <div className='page-five'>
          <h1 className='py-3 text-center'>Want to Teach?</h1>
          <div className='flex-justify-center'>
            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/BMZ3L95C75E'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
          <div className='three-row'>
            <div className='row'>
              <img
                src={require('../../img/profile.png')}
                alt='create-profile'
              />
              <div className='horizontal'>
                <h2>Create a tutoring profile</h2>
                <p className='lead text-gray'>
                  Authorize Zoom and Google Calendar to create a tutoring
                  profile and start teaching.
                </p>
              </div>
            </div>
            <div className='row'>
              <img src={require('../../img/student.png')} alt='student' />
              <div className='horizontal'>
                <h2>Students enroll with you</h2>
                <p className='lead text-gray'>
                  Start scheduling meetings with students once they request to
                  enroll with you.
                </p>
              </div>
            </div>
            <div className='row'>
              <img src={require('../../img/heart.png')} alt='volunteering' />
              <div className='horizontal'>
                <h2>Volunteer hours</h2>
                <p className='lead text-gray'>
                  Accumulate easy volunteer/service hours by teaching students
                  through our meetings!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Page Six */}

      {/* <div className='page-six'>
        <h1>Why Choose Us</h1>
        <div className='three-line'>
          <div className='line'>
            <FontAwesomeIcon icon='check-square' className='fa-large' />
            <div className='text-box'>
              <h2>Hundreds of registered users and students</h2>
              <p>
                Join our large platform as a student or tutor now and begin your
                virtual learning adventure.{' '}
              </p>
            </div>
          </div>
          <div className='line'>
            <FontAwesomeIcon icon='check-square' className='fa-large' />
            <div className='text-box'>
              <h2>Nonprofit organization, nonprofit operation</h2>
              <p>
                We were founded under the purpose to solely benefit our
                community with our educational services. Our entire platform is
                fully open to the public, no premiums, free for everyone.
              </p>
            </div>
          </div>
          <div className='line'>
            <FontAwesomeIcon icon='check-square' className='fa-large' />
            <div className='text-box'>
              <h2>Easy to use</h2>
              <p>
                Instead of using a drawn out application process which seeks to
                match students with tutors, our relatively simple application
                process allows students, who know their needs best, to select a
                tutor of their choice at simply a click of a button.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* Join Now */}

      {/* <div className='join'>
        <h1>Get started now!</h1>
        <hr />
        <button className='btn btn-secondary' onClick={e => handleLogin(e)}>
          <span className='lead'>Join as a student</span>
        </button>
        <button
          className='btn btn-secondary my-2'
          onClick={e => handleLogin(e)}
        >
          <span className='lead'>Join as a tutor</span>
        </button>
        <button className='btn btn-secondary' onClick={e => handleLogin(e)}>
          <span className='lead'>Join as an organization</span>
        </button>
        <Link to='/faq'>
          <button className='btn btn-secondary my-2'>
            <span className='lead'>Read Our FAQ</span>
          </button>
        </Link>
      </div> */}
      <Footer backgroundColor='#fff' />
    </div>
  );
};

Landing.propTypes = {
  getTutors: PropTypes.func.isRequired,
  tutor: PropTypes.object
};

const mapStateToProps = state => ({
  tutor: state.tutor,
  auth: state.auth
});

export default connect(mapStateToProps, { getTutors })(Landing);
