import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createOrg } from '../../actions/org';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from '../layout/Footer';
import '../../App.css';

const OrgForm = ({ createOrg, auth }) => {
  const initialForm = {
    name: '',
    description: '',
    orgImageUrl: '',
    email: '',
    website: '',
    enrollmentCode: '',
    timezone: 'EST'
  };
  const [formData, setFormData] = useState(initialForm);
  const [redirect, setRedirect] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    createOrg(formData);
    setFormData(initialForm);
    //redirect to profile org page
    // setRedirect(true);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (auth.user) {
      setFormData({ ...formData, orgImageUrl: auth.user.profileImageUrl });
    }
  }, [auth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    name,
    description,
    orgImageUrl,
    email,
    website,
    enrollmentCode,
    timezone
  } = formData;

  if (redirect) {
    return <Redirect push to='/account/organizations' />;
  }

  return (
    <div className='container colored'>
      <HelmetWrapper
        title='Create an Organization'
        description='This is page allows users to create an organization.'
      />
      <div className='flex-justify-center'>
        <div className='org-form'>
          <p className='large text-center'>Create An Organization</p>
          <div>
            {!auth.loading && auth.user && !auth.user.zoomAuthorized ? (
              <div>
                <div className='flex'>
                  <p className='lead'>Authorize Zoom</p>{' '}
                  <p className='text-danger'>*</p>
                </div>
                <div className='flex zoom-button'>
                  <a
                    href='https://ingenify.org/api/auth/zoom'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src='https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png'
                      alt='Add to ZOOM'
                    />
                  </a>
                </div>
              </div>
            ) : null}
          </div>
          <form className='form' onSubmit={e => onSubmit(e)}>
            <div className='form-group'>
              <p className='small text-gray'>
                Name{name === '' && <span className='text-danger'>*</span>}
              </p>
              <input
                type='text'
                name='name'
                placeholder='Name'
                value={name}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <div className='form-group'>
              <p className='small text-gray'>
                Description
                {description === '' && <span className='text-danger'>*</span>}
              </p>
              <textarea
                name='description'
                placeholder='Description'
                value={description}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <div className='form-group'>
              <p className='small text-gray'>
                Email{email === '' && <span className='text-danger'>*</span>}
              </p>
              <input
                type='email'
                name='email'
                placeholder='Email'
                value={email}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <div className='form-group'>
              <p className='small text-gray'>Website</p>
              <input
                type='text'
                name='website'
                placeholder='https://'
                value={website}
                onChange={e => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <p className='small text-gray'>Image Url</p>
              <input
                type='text'
                name='orgImageUrl'
                placeholder='https://'
                value={orgImageUrl}
                onChange={e => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <p className='small text-gray'>
                Enrollment Code {'(created by you)'}
                {enrollmentCode === '' && (
                  <span className='text-danger'>*</span>
                )}
              </p>
              <input
                type='text'
                name='enrollmentCode'
                placeholder='Enrollment Code'
                value={enrollmentCode}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <div>
              <p className='small text-gray'>Timezone</p>
              <select
                name='timezone'
                value={timezone}
                onChange={e => onChange(e)}
                style={{ width: 'auto' }}
                required
              >
                <option value='EST'>Eastern</option>
                <option value='CST'>Central</option>
                <option value='MST'>Mountain</option>
                <option value='PST'>Pacific</option>
              </select>
            </div>
            <div className='form-group'>
              <input
                className='btn btn-secondary'
                type='submit'
                value='Create'
              />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

OrgForm.propTypes = {
  createOrg: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { createOrg })(OrgForm);
