import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';

const grades = {
  0: 'K',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: 'College'
};

const AccountNav = ({
  auth,
  profile: { profile },
  tutor: { tutor },
  student: { student },
  setAlert,
  page
}) => {
  const copyToClipboard = e => {
    let textArea = document.createElement('textarea');
    textArea.value = `https://ingenify.org/api/tutor/${tutor.user._id}/join/${tutor.enrollmentCode}`;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    setAlert('Copied', 'success');
  };
  return (
    <div>
      {!auth.loading && auth.user ? (
        <div className='account-nav'>
          <div className='account-nav-top'>
            <img src={auth.user.profileImageUrl} alt='profile img' />
            <h1 className='mx-2 large'> {auth.user.name} </h1>
          </div>
          <div className='account-nav-mid'>
            <Link to='/account/dashboard'>
              <div>
                {page === 'dashboard' ? (
                  <p>
                    <strong>Dashboard</strong>
                  </p>
                ) : (
                  <p>Dashboard</p>
                )}
              </div>
            </Link>
            <Link to='/account/meetings'>
              <div>
                {page === 'meetings' ? (
                  <p>
                    <strong>Meetings</strong>
                  </p>
                ) : (
                  <p>Meetings</p>
                )}
              </div>
            </Link>
            <Link to='/account/achievements'>
              <div>
                {page === 'achievements' ? (
                  <p>
                    <strong>Achievements</strong>
                    {profile &&
                      profile.qualifiedBadges.length -
                        profile.claimedBadges.length >
                        0 && (
                        <span className='badge badge-success'>
                          {profile.qualifiedBadges.length -
                            profile.claimedBadges.length}
                        </span>
                      )}
                  </p>
                ) : (
                  <p>
                    Achievements
                    {profile &&
                      profile.qualifiedBadges.length -
                        profile.claimedBadges.length >
                        0 && (
                        <span className='badge badge-success'>
                          {profile.qualifiedBadges.length -
                            profile.claimedBadges.length}
                        </span>
                      )}
                  </p>
                )}
              </div>
            </Link>
            <Link to='/account/requests'>
              <div>
                {page === 'requests' ? (
                  <p>
                    <strong>
                      Requests{' '}
                      {tutor && tutor.requests.length > 0 ? (
                        <span className='badge badge-danger'>
                          {tutor.requests.length}
                        </span>
                      ) : null}
                    </strong>
                  </p>
                ) : (
                  <p>
                    Requests{' '}
                    {tutor && tutor.requests.length > 0 ? (
                      <span className='badge badge-danger'>
                        {tutor.requests.length}
                      </span>
                    ) : null}
                  </p>
                )}
              </div>
            </Link>
            <Link to='/account/organizations'>
              <div>
                {page === 'organizations' ? (
                  <p>
                    <strong>My Organizations</strong>
                  </p>
                ) : (
                  <p>My Organizations</p>
                )}
              </div>
            </Link>
            <Link to='/account/profile'>
              <div>
                {page === 'profile' ? (
                  <p>
                    <strong>Edit Profile</strong>
                  </p>
                ) : (
                  <p>Edit Profile</p>
                )}
              </div>
            </Link>
          </div>
          <div className='account-nav-low'>
            <div>
              <h1 className='lead'>My Stats</h1>
            </div>
            {profile ? (
              <div>
                <div>Location: {profile.location}</div>
                <div>School: {profile.school}</div>
                <div>Grade: {grades[profile.grade]}</div>
                <div>Referral Code: {profile.referralCode.toUpperCase()}</div>
              </div>
            ) : null}
            {tutor ? (
              <div>
                <div>Hours: {tutor.hours}</div>
                <div>Enrollment Code: {tutor.enrollmentCode}</div>
                {tutor.affiliation ? (
                  <div>Affiliation: {tutor.affiliation}</div>
                ) : null}
                <p>
                  <button
                    className='btn btn-secondary'
                    style={{ borderRadius: '0.5rem' }}
                    onClick={e => copyToClipboard(e)}
                  >
                    Copy Enroll Link
                  </button>
                </p>
              </div>
            ) : (
              student &&
              !tutor && (
                <div>
                  <div>Hours: {student.hours}</div>
                </div>
              )
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

AccountNav.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  tutor: PropTypes.object,
  student: PropTypes.object,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  student: state.student,
  tutor: state.tutor
});

export default connect(mapStateToProps, { setAlert })(AccountNav);
