import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountNav from '../AccountNav';
import '../../../App.css';

import {
  createStudent,
  enrollStudent,
  getCurrentStudent,
  deleteStudent
} from '../../../actions/student';
import { getCurrentProfile } from '../../../actions/profile';

const Student = ({
  student: { student, loading },
  auth,
  createStudent,
  enrollStudent,
  getCurrentStudent,
  getCurrentProfile,
  deleteStudent
}) => {
  useEffect(() => {
    getCurrentStudent();
    getCurrentProfile();
  }, []);

  const [formData, setFormData] = useState('');

  const [deleteClicked, setDeleteClicked] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    createStudent();
  };
  const onChange = e => {
    setFormData(e.target.value);
  };

  const noStudent = (
    <div>
      <form className='form' onSubmit={e => onSubmit(e)}>
        <div className='form-group'>
          <input type='checkbox' name='consent' required />
          <label className='mx' htmlFor='consent'>
            I give consent for my student to attend meetings on this site
          </label>
        </div>
        <div>
          <input
            type='submit'
            value='Activate Student Profile'
            className='btn btn-secondary'
          />
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <div className='flex'>
        <h1 className='lead'>Student Profile</h1>{' '}
        {!student && !loading && <p className='text-danger'>*</p>}
      </div>
      {!student && !loading ? (
        noStudent
      ) : (
        <div>
          {deleteClicked ? (
            <div>
              <button
                className='btn btn-text mx'
                onClick={e => {
                  deleteStudent();
                  setDeleteClicked(false);
                }}
              >
                Confirm
              </button>{' '}
              <button
                className='btn btn-text text-gray'
                onClick={e => setDeleteClicked(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              className='btn btn-text'
              onClick={e => setDeleteClicked(true)}
            >
              Delete Student Profile
            </button>
          )}
        </div>
      )}
    </div>
  );
};

Student.propTypes = {
  createStudent: PropTypes.func.isRequired,
  enrollStudent: PropTypes.func.isRequired,
  getCurrentStudent: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  deleteStudent: PropTypes.func.isRequired,
  student: PropTypes.object,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const mapStateToProps = state => ({
  student: state.student,
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, {
  createStudent,
  enrollStudent,
  getCurrentStudent,
  getCurrentProfile,
  deleteStudent
})(Student);
