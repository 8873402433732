import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AccountNav from '../AccountNav';
// import TutorItem from './TutorItem';
// import StudentItem from './StudentItem';
// import UpcomingMeetingItem from './UpcomingMeetingItem';
// import PastMeetingItem from './PastMeetingItem';
import { getCurrentProfile, claimTier } from '../../../actions/profile';
import { getCurrentTutor, scheduleMeeting } from '../../../actions/tutor';
import { getCurrentStudent } from '../../../actions/student';
import StreakBar from './StreakBar';
import TierDisplay from './TierDisplay';
import SpecialBadgeDisplay from './SpecialBadgesDisplay';
import NextMeeting from './NextMeeting';
import RecentStudent from './RecentStudent';
import RecentTutor from './RecentTutor';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const Dashboard = ({
  profile,
  tutor,
  student,
  user,
  getCurrentTutor,
  getCurrentStudent,
  getCurrentProfile,
  scheduleMeeting,
  claimTier
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getCurrentProfile();
    getCurrentTutor();
    getCurrentStudent();
  }, []);

  const [upcomingMeeting, setUpcomingMeeting] = useState(null);
  const [pastMeeting, setPastMeeting] = useState(null);
  const [hours, setHours] = useState(0);

  useEffect(() => {
    if (tutor && tutor.meetings) {
      setUpcomingMeeting(
        tutor.meetings
          .filter(meeting => !meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return aDate - bDate;
          })[0]
      );
      setPastMeeting(
        tutor.meetings
          .filter(meeting => meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return bDate - aDate;
          })[0]
      );
      setHours(tutor.hours);
    } else if (student && student.meetings) {
      setUpcomingMeeting(
        student.meetings
          .filter(meeting => !meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return aDate - bDate;
          })[0]
      );
      setPastMeeting(
        student.meetings
          .filter(meeting => meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return bDate - aDate;
          })[0]
      );
      setHours(student.hours);
    }
  }, [tutor, student]);

  return (
    <div className='container colored'>
      {user ? (
        <HelmetWrapper
          title={`${user.name} | Ingenify`}
          description='This is the dashboard of the Ingenify account. It contains information about streaks, tiers, next meeting, recent meeting, badges, and stats.'
        />
      ) : (
        <HelmetWrapper
          title={`My Account | Ingenify`}
          description='This is the dashboard of the Ingenify account. It contains information about streaks, tiers, next meeting, recent meeting, badges, and stats.'
        />
      )}
      <div className='account-page'>
        <AccountNav page='dashboard' />
        <div className='account-body' style={{ padding: '0' }}>
          <div className='dashboard-1'>
            <StreakBar profile={profile} />
            <div className='tier-display'>
              {profile && profile.tier !== 'unranked' ? (
                <img
                  className='tier-img'
                  src={require(`../../../img/tier_${profile.tier}.png`)}
                />
              ) : (
                <img
                  className='tier-img-unranked'
                  src={require(`../../../img/tier_unranked.png`)}
                />
              )}
            </div>
          </div>
          <div className='dashboard-2'>
            <div className='p next-meeting'>
              <p className='lead'>Next Meeting</p>
              {upcomingMeeting && tutor && (
                <NextMeeting meeting={upcomingMeeting} isTutor={true} />
              )}
              {upcomingMeeting && !tutor && student && (
                <NextMeeting meeting={upcomingMeeting} isTutor={false} />
              )}
            </div>
            <div className='p recent'>
              <p className='lead'>Recent</p>
              {upcomingMeeting && <p>&nbsp;</p>}
              {pastMeeting && tutor && (
                <RecentStudent student={pastMeeting.student} />
              )}
              {pastMeeting && !tutor && student && (
                <RecentTutor tutor={pastMeeting.tutor} />
              )}
            </div>
          </div>
          <div className='dashboard-3'>
            <TierDisplay
              profile={profile}
              hours={hours}
              claimTier={claimTier}
            />
          </div>
          <div className='dashboard-4'>
            <p className='lead text-center'>Badges</p>
            <SpecialBadgeDisplay profile={profile} />
            <Link to='/account/achievements' className='p-1 my show-more'>
              Show more
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Dashboard.propTypes = {
  profile: PropTypes.object,
  tutor: PropTypes.object,
  student: PropTypes.object,
  user: PropTypes.object,
  getCurrentStudent: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getCurrentTutor: PropTypes.func.isRequired,
  scheduleMeeting: PropTypes.func.isRequired,
  claimTier: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  tutor: state.tutor.tutor,
  student: state.student.student,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getCurrentTutor,
  getCurrentStudent,
  scheduleMeeting,
  claimTier
})(Dashboard);
