import axios from 'axios';
import { setAlert } from './alert';
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR
} from './types';

export const loadUser = () => async dispatch => {
  try {
    const res = await axios.get('/api/auth');
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// export const zoomAuthorization = () => async dispatch => {
//   try {
//     const res = await axios.get('/api/auth/zoom');
//     dispatch({
//       type: USER_LOADED,
//       payload: res.data
//     });
//   } catch (err) {
//     //just send message, do not dispatch error
//     dispatch({
//       type: AUTH_ERROR
//     });
//   }
// };

// export const login = () => async dispatch => {
//   try {
//     await axios.get('/api/auth/google');
//     dispatch({
//       type: LOGIN_SUCCESS
//     });
//     dispatch(loadUser());
//   } catch (err) {
//     dispatch({
//       type: LOGIN_FAIL
//     });
//   }
// };

export const logout = () => async dispatch => {
  try {
    await axios.get('/api/auth/logout');
    dispatch({
      type: LOGOUT
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};
