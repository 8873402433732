import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import {
  createProfile,
  getCurrentProfile,
  deleteProfile,
  uploadProfileImage,
  checkReferral
} from '../../../actions/profile';
import '../../../App.css';

const Profile = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  deleteProfile,
  uploadProfileImage,
  checkReferral,
  setAlert,
  auth
}) => {
  const initialForm = {
    name: '',
    location: '',
    school: '',
    grade: '',
    timezone: 'EST',
    teacherEmail: '',
    parentEmail: '',
    phoneNumber: '',
    includeEmail: true
  };
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState(initialForm);

  const [deleteClicked, setDeleteClicked] = useState(false);

  const [referralCode, setReferralCode] = useState('');

  useEffect(() => {
    getCurrentProfile();
  }, []);

  useEffect(() => {
    if (profile && !loading && auth.user) {
      const profileData = { ...initialForm };
      for (const key in profile) {
        if (key in profileData) profileData[key] = profile[key];
      }
      setFormData({
        ...profileData,
        name: auth.user.name,
        parentEmail: auth.user.parentEmail,
        phoneNumber: auth.user.phoneNumber
      });
    } else if (!profile && auth.user) {
      setFormData({
        ...initialForm,
        name: auth.user.name,
        parentEmail: auth.user.parentEmail,
        phoneNumber: auth.user.phoneNumber
      });
    }
  }, [loading, profile, auth]);

  const onSubmit = e => {
    e.preventDefault();
    if (
      formData.grade !== 'Select Grade' &&
      formData.location !== 'Select State'
    ) {
      createProfile({ formData });
      return;
    }
    setAlert('Grade and State required', 'danger');
  };

  const onReferralSubmit = e => {
    e.preventDefault();
    checkReferral(referralCode);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFile = e => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const fileSubmit = e => {
    e.preventDefault();
    if (file) {
      console.log(file);
      uploadProfileImage(file);
      console.log('done');
      return;
    }
    setAlert('Select an image first', 'danger');
  };
  const onCheckChange = e => {
    setFormData({ ...formData, includeEmail: !formData.includeEmail });
  };

  const {
    name,
    school,
    grade,
    location,
    includeEmail,
    timezone,
    teacherEmail,
    parentEmail,
    phoneNumber
  } = formData;
  return (
    <div>
      <div className='flex'>
        <h1 className='lead'>
          General Information
          {!profile && !loading && <span>(required)</span>}
        </h1>{' '}
        {!profile && !loading && <p className='text-danger'>*</p>}
      </div>
      {!profile && !loading && (
        <div>
          <p className='text-danger'>
            {/* <strong> */}
            If you are a parent signing up your student, please attend the first
            meeting with each tutor
            {/* </strong> */}
          </p>
        </div>
      )}
      <form className='form' onSubmit={e => onSubmit(e)}>
        <div>
          <p className='small text-gray'>
            Name
            {name === '' && <span className='text-danger'>*</span>}
          </p>
          <input
            type='text'
            placeholder='Name'
            autoComplete='off'
            name='name'
            onChange={e => onChange(e)}
            value={name}
            required
          />
        </div>
        <div className='form-group flex'>
          <div className='school-input'>
            <p className='small text-gray'>
              School{school === '' && <span className='text-danger'>*</span>}
            </p>
            <input
              type='text'
              placeholder='School'
              name='school'
              onChange={e => onChange(e)}
              value={school}
              required
            />
          </div>
          <div className='mx-1'>
            <p className='small text-gray'>
              Grade
              {(grade === 'Select Grade' || grade === '') && (
                <span className='text-danger'>*</span>
              )}
            </p>
            <select
              name='grade'
              value={grade}
              onChange={e => onChange(e)}
              required
            >
              <option>Select Grade</option>
              <option value='0'>K</option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
              <option value='11'>11</option>
              <option value='12'>12</option>
              <option value='13'>College</option>
            </select>
          </div>
          <div>
            <p className='small text-gray'>
              State
              {(location === 'Select State' || location === '') && (
                <span className='text-danger'>*</span>
              )}
            </p>
            <select
              name='location'
              value={location}
              onChange={e => onChange(e)}
              required
            >
              <option>Select State</option>
              <option value='AL'>AL</option>
              <option value='AK'>AK</option>
              <option value='AR'>AR</option>
              <option value='AZ'>AZ</option>
              <option value='CA'>CA</option>
              <option value='CO'>CO</option>
              <option value='CT'>CT</option>
              <option value='DC'>DC</option>
              <option value='DE'>DE</option>
              <option value='FL'>FL</option>
              <option value='GA'>GA</option>
              <option value='HI'>HI</option>
              <option value='IA'>IA</option>
              <option value='ID'>ID</option>
              <option value='IL'>IL</option>
              <option value='IN'>IN</option>
              <option value='KS'>KS</option>
              <option value='KY'>KY</option>
              <option value='LA'>LA</option>
              <option value='MA'>MA</option>
              <option value='MD'>MD</option>
              <option value='ME'>ME</option>
              <option value='MI'>MI</option>
              <option value='MN'>MN</option>
              <option value='MO'>MO</option>
              <option value='MS'>MS</option>
              <option value='MT'>MT</option>
              <option value='NC'>NC</option>
              <option value='NE'>NE</option>
              <option value='NH'>NH</option>
              <option value='NJ'>NJ</option>
              <option value='NM'>NM</option>
              <option value='NV'>NV</option>
              <option value='NY'>NY</option>
              <option value='ND'>ND</option>
              <option value='OH'>OH</option>
              <option value='OK'>OK</option>
              <option value='OR'>OR</option>
              <option value='PA'>PA</option>
              <option value='RI'>RI</option>
              <option value='SC'>SC</option>
              <option value='SD'>SD</option>
              <option value='TN'>TN</option>
              <option value='TX'>TX</option>
              <option value='UT'>UT</option>
              <option value='VT'>VT</option>
              <option value='VA'>VA</option>
              <option value='WA'>WA</option>
              <option value='WI'>WI</option>
              <option value='WV'>WV</option>
              <option value='WY'>WY</option>
            </select>
          </div>
        </div>
        <div className='form-group'>
          <p className='small text-gray'>
            School Email
            {teacherEmail === '' && <span className='text-danger'>*</span>}
          </p>
          <input
            type='email'
            placeholder='School or county issued email'
            name='teacherEmail'
            onChange={e => onChange(e)}
            value={teacherEmail}
            required
          />
        </div>
        <div className='form-group'>
          <p className='small text-gray'>
            Parent Email
            {(!parentEmail || parentEmail === '') && (
              <span className='text-danger'>*</span>
            )}
          </p>
          <input
            type='email'
            placeholder='Parent email'
            name='parentEmail'
            onChange={e => onChange(e)}
            value={parentEmail}
            required
          />
        </div>
        <div className='form-group flex'>
          <div className='school-input'>
            <p className='small text-gray'>
              Phone Number
              {(!phoneNumber || phoneNumber === '') && (
                <span className='text-danger'>*</span>
              )}
            </p>
            <input
              type='text'
              placeholder='Ex. 1112223333'
              name='phoneNumber'
              onChange={e => onChange(e)}
              value={phoneNumber}
              required
            />
          </div>
          <div className='mx-1'>
            <p className='small text-gray'>Timezone</p>
            <select
              name='timezone'
              value={timezone}
              onChange={e => onChange(e)}
              required
            >
              <option value='EST'>Eastern</option>
              <option value='CST'>Central</option>
              <option value='MST'>Mountain</option>
              <option value='PST'>Pacific</option>
            </select>
          </div>
        </div>
        {/* <div>
          <input
            type='checkbox'
            name='includeEmail'
            value={includeEmail}
            checked={includeEmail}
            onChange={e => onCheckChange(e)}
          />
          <label className='mx' htmlFor='includeEmail'>
            Include email when sending request messages
          </label>
        </div> */}
        <div className='form-group flex-spread'>
          <input
            className='btn btn-secondary'
            style={{ borderRadius: '0.25rem' }}
            type='submit'
            value='Save'
          />
          {profile ? (
            <div>
              {deleteClicked ? (
                <div>
                  <button
                    className='btn btn-text mx'
                    onClick={e => {
                      deleteProfile();
                      setDeleteClicked(false);
                    }}
                  >
                    Confirm
                  </button>{' '}
                  <button
                    className='btn btn-text text-gray'
                    onClick={e => setDeleteClicked(false)}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className='btn btn-text'
                  onClick={e => setDeleteClicked(true)}
                >
                  Delete Profile
                </button>
              )}
            </div>
          ) : null}
        </div>
      </form>
      <form className='form' onSubmit={e => fileSubmit(e)}>
        <div className='form-group'>
          <p className='small text-gray'>Profile Image (square dimensions)</p>
          <input type='file' name='file' onChange={e => handleFile(e)} />
        </div>
        <div className='form-group'>
          <input
            className='btn btn-secondary'
            style={{ borderRadius: '0.25rem' }}
            type='submit'
            value='Upload'
          />
        </div>
      </form>
      {(!profile || !profile.referredBy) && (
        <form className='form' onSubmit={e => onReferralSubmit(e)}>
          <div className='form-group' style={{ width: '20rem' }}>
            <p className='small text-gray'>Enter Referral Code (optional)</p>
            <div className='flex'>
              <input
                type='text'
                name='referralCode'
                onChange={e => setReferralCode(e.target.value)}
                placeholder='Referral Code'
                value={referralCode}
                required
              />
              <input
                className='btn btn-secondary mx'
                type='submit'
                value='Submit'
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

Profile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  deleteProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  getCurrentProfile: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  checkReferral: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createProfile,
  getCurrentProfile,
  deleteProfile,
  uploadProfileImage,
  checkReferral,
  setAlert
})(Profile);
