import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from './Footer';
import '../../App.css';
const Support = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container'>
      <HelmetWrapper
        title='Support'
        description='This is the support page of Ingenify.'
      />
      <div className='contact-card'>
        <h1 className='large'>Support</h1>
        <ul className='text-center'>
          <li className='lead'>Email Us: support@ingenify.org</li>
          <li className='lead'>Call Us: 404-932-7135</li>
        </ul>
        <div>
          <div className='my'>
            <h1 className='lead'>
              We value our users greatly and encourage their feedback
            </h1>
            <p>Please contact us for any of the following issues:</p>
            <ol>
              <li style={{ marginLeft: '0' }}>
                Problems regarding the functionality of the platform.
              </li>
              <li style={{ marginLeft: '0' }}>
                Reporting of abusive behavior.
              </li>
              <li style={{ marginLeft: '0' }}>
                Concerns regarding our{' '}
                <Link to='/privacy_policy'>privacy policy.</Link>
              </li>
              <li style={{ marginLeft: '0' }}>
                Suggestions and feedback for the platform.
              </li>
            </ol>
          </div>
          <div>
            <h1 className='lead'>Hours of operation</h1>
            <div>Monday - Friday : 9am to 9pm EST</div>
            <div>
              <p>
                Responses are guaranteed within <strong>24 hours</strong> of
                your first email sent.
              </p>
              <p>Calls will be picked up during the hours of operation.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer backgroundColor='#fff' />
    </div>
  );
};

export default Support;
