import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import queryString from 'query-string';
import '../../App.css';

const possibleSubjects = [
  'Elementary School Math',
  'Elementary School Reading and Writing',
  'Elementary School Science',
  'Elementary School Social Studies',
  'Middle School Math',
  'Middle School Reading and Writing',
  'Middle School Science',
  'Middle School Social Studies',
  'Algebra',
  'Geometry',
  'Precalculus',
  'Calculus',
  'Biology',
  'Chemistry',
  'Physics',
  'English and Literature',
  'Spanish',
  'French',
  'Chinese',
  'Computer Science',
  'SAT',
  'ACT'
];

const elementarySubjects = [
  'Elementary School Math',
  'Elementary School Reading and Writing',
  'Elementary School Science',
  'Elementary School Social Studies'
];

const middleSubjects = [
  'Middle School Math',
  'Middle School Reading and Writing',
  'Middle School Science',
  'Middle School Social Studies'
];

const highSubjects = [
  'Algebra',
  'Geometry',
  'Precalculus',
  'Calculus',
  'Biology',
  'Chemistry',
  'Physics',
  'English and Literature',
  'Spanish',
  'French',
  'Chinese',
  'Computer Science',
  'SAT',
  'ACT'
];

const possibleGrades = ['9', '10', '11', '12'];

const possibleAvailabilities = ['shortTerm', 'longTerm'];

//Filter/sort panel
const SidePanel = ({ location }) => {
  const initialForm = {
    grades: [],
    subjects: [],
    sort: '',
    ascending: false,
    availability: []
  };
  const [formData, setFormData] = useState(initialForm);
  const [redirect, setRedirect] = useState(false);
  const [newQuery, setNewQuery] = useState('');
  const [showGrades, setShowGrades] = useState(true);
  const [showAvailability, setShowAvailability] = useState(true);
  const [showElementarySubjects, setShowElementarySubjects] = useState(true);
  const [showMiddleSubjects, setShowMiddleSubjects] = useState(true);
  const [showHighSubjects, setShowHighSubjects] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  let { grades, subjects, sort, ascending, availability } = formData;
  useEffect(() => {
    setRedirect(false);
  });

  useEffect(() => {
    let {
      grades,
      subjects,
      sort,
      ascending,
      s,
      availability
    } = queryString.parse(location.search);
    if (s) {
      setSearchTerm(s);
    }
    if (grades) {
      grades = grades.split(' ');
    } else {
      grades = [];
    }
    if (subjects) {
      subjects = subjects.split(' ');
    } else {
      subjects = [];
    }
    if (availability) {
      availability = availability.split(' ');
    } else {
      availability = [];
    }
    setFormData({
      ...formData,
      grades,
      subjects,
      sort,
      ascending,
      availability
    });
    if (!location.search) {
      setFormData(initialForm);
    }
  }, [location.search]);
  const onFilter = e => {
    let query = '';
    if (grades) {
      query = query + 'grades=' + grades.join('+');
    }
    if (subjects) {
      query = query + '&subjects=' + subjects.join('+');
    }
    if (sort) {
      query = query + '&sort=' + sort;
    }
    if (availability) {
      query = query + '&availability=' + availability.join('+');
    }
    if (ascending) {
      query = query + '&ascending=true';
    }
    if (searchTerm) {
      query = query + '&s=' + searchTerm;
    }
    if (query) {
      setRedirect(true);
      setNewQuery(query);
    }
  };

  const onGradeChange = e => {
    if (grades.find(grade => grade === e.target.name)) {
      setFormData({
        ...formData,
        grades: grades.filter(grade => grade !== e.target.name)
      });
    } else {
      setFormData({ ...formData, grades: [...grades, e.target.name] });
    }
  };

  const onAvailabilityChange = e => {
    if (availability.find(ava => ava === e.target.name)) {
      setFormData({
        ...formData,
        availability: availability.filter(ava => ava !== e.target.name)
      });
    } else {
      setFormData({
        ...formData,
        availability: [...availability, e.target.name]
      });
    }
  };

  const onSubjectChange = e => {
    if (subjects.find(subject => subject === e.target.name)) {
      setFormData({
        ...formData,
        subjects: subjects.filter(subject => subject !== e.target.name)
      });
    } else {
      setFormData({ ...formData, subjects: [...subjects, e.target.name] });
    }
  };

  const onAscendingChange = e => {
    setFormData({ ...formData, ascending: !ascending });
  };

  const onSortChange = e => {
    setFormData({ ...formData, sort: e.target.value });
  };

  if (redirect) {
    return <Redirect push to={`tutors?${newQuery}`} />;
  }

  return (
    <div className='side-panel'>
      <strong>Filters</strong>
      <div>
        <button
          style={{ width: '100%', borderRadius: '0.25rem' }}
          className='btn btn-secondary my'
          onClick={e => onFilter(e)}
        >
          Filter
        </button>
      </div>
      <div>
        Sort By{' '}
        <select
          value={formData.sort}
          name='sort'
          onChange={e => onSortChange(e)}
        >
          <option></option>
          <option value='hours'>Hours</option>
          <option value='rating'>Rating</option>
        </select>
      </div>
      {/* <div>
        <input
          type='checkbox'
          name='ascending'
          onChange={e => onAscendingChange(e)}
          checked={ascending}
        />
        <label htmlFor='ascending'>{` Reverse`}</label>
      </div> */}
      <hr />
      <div>
        <div onClick={e => setShowGrades(!showGrades)}>
          <strong style={{ cursor: 'pointer' }}>Grade</strong>
        </div>
        <div>
          {showGrades
            ? possibleGrades.map((grade, index) => {
                const exists = formData.grades.find(g => g === grade);
                let checked = false;
                if (exists) {
                  checked = true;
                }
                return (
                  <div key={index}>
                    <input
                      type='checkbox'
                      name={grade}
                      onChange={e => onGradeChange(e)}
                      checked={checked}
                    />
                    <label htmlFor={grade}>{` ${grade}th`}</label>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <hr />
      <div>
        <div onClick={e => setShowAvailability(!showAvailability)}>
          <strong style={{ cursor: 'pointer' }}>Availability</strong>
        </div>
        <div>
          {showAvailability
            ? possibleAvailabilities.map((ava, index) => {
                const exists = formData.availability.find(a => a === ava);
                let checked = false;
                if (exists) {
                  checked = true;
                }
                return (
                  <div key={index}>
                    <input
                      type='checkbox'
                      name={ava}
                      onChange={e => onAvailabilityChange(e)}
                      checked={checked}
                    />
                    {ava === 'shortTerm' ? (
                      <label htmlFor={ava}>{` Short Term Help`}</label>
                    ) : null}
                    {ava === 'longTerm' ? (
                      <label htmlFor={ava}>{` Long Term Help`}</label>
                    ) : null}
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <hr />
      <div>
        <div onClick={e => setShowElementarySubjects(!showElementarySubjects)}>
          <strong style={{ cursor: 'pointer' }}>Elementary School</strong>
        </div>
        <div>
          {showElementarySubjects
            ? elementarySubjects.map((subject, index) => {
                const exists = formData.subjects.find(
                  s => s === subject.replace(/ /g, '_')
                );
                let checked = false;
                if (exists) {
                  checked = true;
                }
                return (
                  <div key={index}>
                    <input
                      type='checkbox'
                      name={subject.replace(/ /g, '_')}
                      onChange={e => onSubjectChange(e)}
                      checked={checked}
                    />
                    <label htmlFor={subject.replace(/ /g, '_')}>
                      {` ${subject.substring(17)}`}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
        <hr />
        <div onClick={e => setShowMiddleSubjects(!showMiddleSubjects)}>
          <strong style={{ cursor: 'pointer' }}>Middle School</strong>
        </div>
        <div>
          {showMiddleSubjects
            ? middleSubjects.map((subject, index) => {
                const exists = formData.subjects.find(
                  s => s === subject.replace(/ /g, '_')
                );
                let checked = false;
                if (exists) {
                  checked = true;
                }
                return (
                  <div key={index}>
                    <input
                      type='checkbox'
                      name={subject.replace(/ /g, '_')}
                      onChange={e => onSubjectChange(e)}
                      checked={checked}
                    />
                    <label htmlFor={subject.replace(/ /g, '_')}>
                      {` ${subject.substring(13)}`}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
        <hr />
        <div onClick={e => setShowHighSubjects(!showHighSubjects)}>
          <strong style={{ cursor: 'pointer' }}>High School</strong>
        </div>
        <div>
          {showHighSubjects
            ? highSubjects.map((subject, index) => {
                const exists = formData.subjects.find(
                  s => s === subject.replace(/ /g, '_')
                );
                let checked = false;
                if (exists) {
                  checked = true;
                }
                return (
                  <div key={index}>
                    <input
                      type='checkbox'
                      name={subject.replace(/ /g, '_')}
                      onChange={e => onSubjectChange(e)}
                      checked={checked}
                    />
                    <label htmlFor={subject.replace(/ /g, '_')}>
                      {` ${subject}`}
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* <button className='btn btn-secondary my' onClick={e => onFilter(e)}>
        Filter
      </button> */}
    </div>
  );
};

export default SidePanel;
