import React from 'react';
import Moment from 'react-moment';

const AnnouncementItem = ({ org, announcement }) => {
  return (
    <div className='announcement-item'>
      <div>
        <img src={org.orgImageUrl} alt='organization' />
        <p>{org.name}</p>
        <Moment format='LLL' className='small'>
          {announcement.date}
        </Moment>
      </div>
      <div>
        <p className='announcement-msg' style={{ whiteSpace: 'pre-wrap' }}>
          {announcement.msg}
        </p>
      </div>
    </div>
  );
};

export default AnnouncementItem;
