import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../App.css';
const Alert = ({ alerts }) => {
  if (alerts !== null && alerts.length > 0) {
    return (
      <div
        key={alerts[alerts.length - 1].id}
        className={`alert alert-${alerts[alerts.length - 1].alertType}`}
      >
        {alerts[alerts.length - 1].msg}
      </div>
    );
  }
  return null;
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
