import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const OrgItem = ({ org }) => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect push to={`/organizations/${org._id}`} />;
  }
  return (
    <div className='org-item' onClick={e => setRedirect(true)}>
      <img src={`${org.orgImageUrl}`} alt='img' />
      <div>
        <div className='mobile-center'>
          <Link to={`/organizations/${org._id}`}>{org.name}</Link>
        </div>
        <div>
          <FontAwesomeIcon
            icon='user-friends'
            className='text-secondary'
            title='Members'
          />
          <span className='mx'>{org.members.length}</span>
        </div>
        <div>
          <FontAwesomeIcon
            icon='hourglass-half'
            className='text-secondary'
            title='Hours'
          />
          <span className='mx-1'>{org.hours}</span>
        </div>
      </div>
      <div>
        <p className='text-gray'>
          {org.description.substring(0, 150)}{' '}
          {org.description.length > 150 ? <span>...</span> : null}
        </p>
      </div>
    </div>
  );
};

export default OrgItem;
