import axios from 'axios';
import { setAlert } from './alert';
import { GET_PARTNERS } from './types';

export const getPartners = () => async dispatch => {
  try {
    const res = await axios.get('/api/partner');
    dispatch({
      type: GET_PARTNERS,
      payload: res.data
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const createPartner = formData => async dispatch => {
  try {
    const res = await axios.post('/api/partner', formData);
    dispatch({
      type: GET_PARTNERS,
      payload: res.data
    });
    dispatch(setAlert('Partner Created', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};
