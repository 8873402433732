import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteMeeting, addParticipant } from '../../../actions/org';

const UpcomingMeetingItem = ({
  meeting,
  org,
  view,
  deleteMeeting,
  addParticipant
}) => {
  let date = meeting.startTime.split('T')[0];
  let time = meeting.startTime.split('T')[1];
  const dateParts = date.split('-');
  date = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]);
  const minutes = timeParts[1];
  if (hour >= 12) {
    if (hour === 12) {
      time = `${hour}:${minutes} PM`;
    } else {
      time = `${hour - 12}:${minutes} PM`;
    }
  } else {
    if (hour === 0) {
      time = `12:${minutes} AM`;
    } else {
      time = `${hour}:${minutes} AM`;
    }
  }

  let endTime = time;
  let endHour = hour + parseInt(meeting.expectedDuration / 60);
  let endMinutes = (parseInt(minutes) + (meeting.expectedDuration % 60)) % 60;
  if (endMinutes < parseInt(minutes)) {
    endHour += 1;
  }
  if (endMinutes < 10) {
    endMinutes = `0${endMinutes}`;
  }
  if (endHour >= 12) {
    if (endHour === 12) {
      endTime = `${endHour}:${endMinutes} PM`;
    } else {
      if (endHour >= 24) {
        endTime = `${endHour - 12}:${endMinutes} AM`;
      } else {
        endTime = `${endHour - 12}:${endMinutes} PM`;
      }
    }
  } else {
    if (endHour === 0) {
      endTime = `12:${endMinutes} AM`;
    } else {
      endTime = `${endHour}:${endMinutes} AM`;
    }
  }

  const handleDelete = e => {
    deleteMeeting(meeting._id, org._id);
  };

  const handleJoin = e => {
    addParticipant(meeting._id, org._id);
  };

  return (
    <div>
      {view === 0 ? (
        <div>
          <div className='org-upcoming-meeting'>
            <div className='flex'>
              <p>{meeting.topic}</p>
            </div>
            <div className='flex'>
              <p>
                {date} from {time} to {endTime} {org.timezone}
              </p>
            </div>
            <div className='flex right m-auto-vert'>
              <div className='flex'>
                <a
                  href={meeting.startUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button
                    className='btn btn-secondary'
                    onClick={e => handleJoin(e)}
                  >
                    Start Meeting
                  </button>
                </a>
              </div>
              <div className='flex hide-sm' style={{ marginLeft: '0.5rem' }}>
                <button className='btn btn-text' onClick={e => handleDelete(e)}>
                  Delete Meeting
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='org-upcoming-meeting'>
            <div className='flex'>
              <p>{meeting.topic}</p>
            </div>
            <div className='flex'>
              <p>
                {date} from {time} to {endTime} {meeting.timezone}
              </p>
            </div>
            <div className='flex right'>
              <div className='m-auto-vert'>
                <a
                  href={meeting.joinUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button
                    className='btn btn-secondary'
                    onClick={e => handleJoin(e)}
                  >
                    Join Meeting
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

UpcomingMeetingItem.propTypes = {
  meeting: PropTypes.object.isRequired,
  view: PropTypes.number.isRequired,
  deleteMeeting: PropTypes.func.isRequired,
  addParticipant: PropTypes.func.isRequired
};

export default connect(null, { deleteMeeting, addParticipant })(
  UpcomingMeetingItem
);
