import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from './Footer';
import '../../App.css';

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container'>
      <HelmetWrapper
        title='FAQ'
        description='These are the frequently asked questions of Ingenify.'
      />
      <div className='faq mx-3'>
        <h1>FAQs</h1>
        <h2>General</h2>
        <br />
        <p className='q'>Q: How does Ingenify work?</p>
        <br />
        <p>
          A: Ingenify offers a platform for both students and tutors to find
          each other, schedule lessons, and connect with other people. Anyone
          can become a student, tutor, or both on the website and seek mutual
          benefits by either learning or teaching using our service.
        </p>
        <br />
        <p className='q'>
          Q: How does Ingenify profit/benefit from the tutoring service?
        </p>
        <br />
        <p>
          A: We don’t! Our service is completely nonprofit and openly accessible
          to the public. Users do not need to pay or provide any payment
          information for anything we offer.
        </p>
        <br />
        <p className='q'>
          Q: What third party sites or services are associated with the site?
        </p>
        <br />
        <p>
          A: We are not directly affiliated with any other website or
          organization, though some other educational organizations do use our
          platform as a means of administration or finding students/tutors on
          our website. Third party features that we use to power our site
          include Google for login and account verification and Zoom for
          tutoring sessions.
        </p>
        <br />
        <h2>Students</h2>
        <br />
        <p>
          <a href='https://youtu.be/CJRAo7BCgWw' target='_blank'>
            Click Here for a Tutorial
          </a>
        </p>
        <br />
        <p className='q'>Q: How do I become a student?</p>
        <br />
        <p>
          A: To become a student, simply log in with Google and create a
          profile. Now, you are free to go searching and scheduling with tutors
          on the platform.
        </p>
        <br />
        <p className='q'>Q: How do I find and schedule with a tutor?</p>
        <br />
        <p>
          A: To search for a tutor, go to the tutors list on the website and
          search for tutors using any key word or phrase. Next, click on her
          name and schedule a session with a short message. Once the tutor
          approves your enrollment request, she will send you her enrollment
          code, and you may begin messaging her through email.
        </p>
        <br />
        <p className='q'>Q: How does Ingenify ensure the quality of tutors?</p>
        <br />
        <p>
          A: All tutors are vetted by Ingenify and their profiles will only be
          made public after a review by our team. Ingenify has an integrated
          rating system for tutors that works via student input. Our rating
          systems recommend the best of the best tutors based on user feedback,
          prior experience, and user activity, thus better tutors are more
          visible to more students, whereas vice versa helps quality control to
          ensure that our students get the best learning experience.
        </p>
        <br />
        <p className='q'>Q: How do I join meetings scheduled by my tutor?</p>
        <br />
        <p>
          A: Once a meeting has been created by your tutor, it will show up
          under the <strong>Upcoming Meetings</strong> section of your account's
          meetings page. There will be a button next to the meeting that allows
          you to join. Click that button to join.
        </p>
        <br />
        <h2>Tutors</h2>
        <br />
        <p>
          <a href='https://youtu.be/BMZ3L95C75E' target='_blank'>
            Click Here for a Tutorial
          </a>
        </p>
        <br />
        <p className='q'>Q: How do I become a tutor?</p>
        <br />
        <p>
          A: Similar to students, tutors need to sign in using Google and create
          a profile in the account page. In addition to general information,
          tutors also need to create a tutor profile that consists of providing
          a short bio and interested subjects to teach. You must also allow
          Ingenify to access your Zoom and Google Calendar accounts by clicking
          the <strong>Add to Zoom</strong> and{' '}
          <strong>Authorize Google Calendar</strong> buttons in order to
          generate meetings.
        </p>
        <br />
        <p className='q'>Q: What benefits do I receive if I become a tutor?</p>
        <br />
        <p>
          A: Tutors can use Ingenify to rack up certified volunteering hours by
          helping younger students on the site. Not only does it help you with
          college applications, but volunteering is also essential for
          developing character, social responsibility, and even your
          self-confidence. If you are a high school student that needs
          volunteering opportunities for Beta, honors societies, other clubs, or
          just want to serve the community, Ingenify is the perfect platform for
          you!
        </p>
        <br />
        <p className='q'>Q: How do meetings work?</p>
        <br />
        <p>
          A: In your account meetings page, you will be able to schedule
          meetings by navigating to your list of students. Once scheduled, the
          meeting will appear in the <strong>Upcoming Meetings</strong> section
          of the both you and your student's meetings page and it will also
          appear in the Zoom account you allowed Ingenify to access. To start
          the meeting, go to Zoom and start the meeting. The join link will be
          provided automatically on the student's meetings page. In order for
          your meeting hours to be logged, the student must also be logged in to
          his/her Zoom account which Ingenfiy was given access to when he/she
          joins the meeting.
        </p>
        <br />
        <p className='q'>Q: Why aren't my hours showing up?</p>
        <br />
        <p>
          A: First, try refreshing the page. If that did not work, it means your
          meeting failed to qualify to be logged by Ingenify. In order for your
          meetings to count for hours, the following 3 conditions must be met:
        </p>
        <ol>
          <li>
            You must be signed in to your Zoom account which you have authorized
            Ingenify to access and you must meet your student using the meeting
            created by Ingenify.
          </li>
          <li>
            The participants in the meeting must only be you and your student.
            If another person joins your meeting, it will not count.
          </li>
        </ol>
        <p>These conditions are to ensure the validity of the meeting.</p>
        <br />
        <h2>Organizations</h2>
        <br />
        <p className='q'>Q: How do organizations work?</p>
        <br />
        <p>
          A: Organizations are meant to be for group learning. If students
          prefer learning with other students or would like to learn from a
          variety of different people, then they could request to join an
          organization where meetings will be held with all of its members.
          Organizations may possibly have lecture style meetings or split into
          breakout rooms where tutors can teach a small group of students.
          Organizations outside of Ingenify may use our platform as a virtual
          option as Ingenify allows for easy communication as well as
          participant and hour tracking. Tutors who are a part of the
          organization will get service hours for their work as long as the
          owner of the organization approves it.
        </p>
        <br />
        <p className='q'>
          Q: I am the President/Leadership of an organization. How do I add my
          organization onto Ingenify?
        </p>
        <br />
        <p>
          A: First, complete a profile and authorize zoom. After this step is
          completed, click <strong>Create Organization</strong> under the
          organizations page. Fill in the necessary information. Once complete,
          the organization will be put under pending to be approved by our
          staff. After approval, the organization will become verified on
          Ingenify and will be publicly displayed for others to view and join.
        </p>
        <br />
        <p className='q'>
          Q: What are the requirements to join an organization?
        </p>
        <br />
        <p>
          A: To be able to make a request and join the organization, all that is
          required is a profile. However, if you are a tutor and would like your
          hours to be logged, Zoom authorization and a tutoring profile are
          required.
        </p>
        <br />
        <p className='q'>Q: How do I access the organization{'(s)'} I am in?</p>
        <br />
        <p>
          A: A list of your organizations will be located inder the{' '}
          <strong>My Organizations</strong> page in your account. If you are the
          owner of the organization, you can create announcements, schedule
          meetings, edit information, and manage members once you click on the
          name of your organization.
        </p>
        <br />
        <p className='q'>
          Q: What types of organizations can be created on Ingenify?
        </p>
        <br />
        <p>
          A: We welcome any independent or established tutoring and student
          bodies to use our platform with the organization feature. School
          clubs, such as Beta, honor societies, and local tutoring services may
          create organizations and manage their members and associated hours
          digitally on the Ingenify website.
        </p>
        <br />
        <p className='q'>
          Q: I am sure a participant was in the meeting, why is he/she not
          showing up in the participant list provided by Ingenify?
        </p>
        <br />
        <p>
          A: Before joining the meeting, that participant must be logged into
          the Zoom account he/she authorized Ingenify to access in order to
          appear under the participant list and possibly gain hours.
        </p>
        <br />
        <h2>Zoom</h2>
        <br />
        <p className='q'>Q: How do I authorize/install Zoom for Ingenify?</p>
        <br />
        <p>
          A: Simply click the <strong>Add to Zoom</strong> button located in the{' '}
          <strong>Edit Profile Information</strong> tab of your account page.
          You will be redirected to the <a href='https://zoom.us'>Zoom</a>{' '}
          website where you may authorize/install the Ingenify app on your zoom
          account. If you do not have Zoom installed you may install it{' '}
          <a href='https://zoom.us/download'>here</a>. If you have any problems,
          refer to{' '}
          <a href='https://support.zoom.us/hc/en-us/sections/200305593-Troubleshooting'>
            this
          </a>{' '}
          site.{' '}
        </p>
        <br />
        <p>
          If the instructions above do not work, it means another Ingenify user
          account already uses your Zoom account. There may only be one Ingenify
          user associated with each Zoom account. To handle this issue,
          uninstall Ingenify from you zoom account and authorize it using your
          desired Ingenify account.
        </p>
        <br />
        <p className='q'>Q: How does Ingenify use my Zoom account?</p>
        <br />
        <p>
          A: If you are a tutor, Ingenify uses your account to create Zoom
          meetings which Ingenify can access to determine its duration and
          participants. This is to keep an accurate log of service/volunteer
          hours for the tutor and prevent fraudulant meetings. If you are a
          student, Ingenify does not need your Zoom account.
        </p>
        <br />
        <p className='q'>
          Q: How do I stop/uninstall Ingenify from using my Zoom account?
        </p>
        <br />
        <p>A: To prevent Ingenify from accessing your Zoom account: </p>
        <ol>
          <li>
            Login to your Zoom account and navigate to the Zoom App Marketplace.
          </li>
          <li>
            Click <strong>Manage {'>'} Installed Apps</strong> or search for the{' '}
            <strong>Ingenify</strong> app.
          </li>
          <li>
            Click the <strong>Ingenify</strong> app.
          </li>
          <li>
            Click <strong>Uninstall</strong>
          </li>
        </ol>
        <br />
        <h2>Privacy</h2>
        <br />
        <p className='q'>
          Q: What information do I have to give Ingenify to use the site?
        </p>
        <br />
        <p>
          A: To use the platform, your name, school, grade, location (US state),
          tiemzone, parent email, and phone number are required. As a tutor, you
          should also include a short bio with your interests and achievements,
          subjects you want to teach, and your availability.
        </p>
        <br />
        <p className='q'>Q: Is my information private?</p>
        <br />
        <p>
          A: As a student on the site, all of your information is private and
          invisible to others on the platform. Your profile information (name,
          grade, school, email, parent email, and phone number) can only be seen
          by tutors that you sent enrollment requests to. For tutors, your basic
          information and bio will be publicly displayed on the site in the
          tutor page to attract students to enroll with you.
        </p>
        <br />
        <p className='q'>Q: How does Ingenify keep data secure?</p>
        <br />
        <p>
          A: All of our user data is maintained within a secure database
          protected with an SSH (secure shell). Data transmissions between our
          users are encrypted with a 256-bit SSL (secure sockets layer) between
          our user clients and the main server that create secure keys,
          impenetrable by malicious users and third-party software. For more
          information regarding how we use your data and keep it safe, please
          read our privacy policy <Link to='/privacy_policy'>here.</Link>
        </p>
        <br />
      </div>
      <Footer backgroundColor='#fff' />
    </div>
  );
};

export default FAQ;
