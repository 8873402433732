import {
  GET_ORG,
  GET_ORGS,
  ORG_ERROR,
  CLEAR_ORG,
  DELETE_ORG
} from '../actions/types';

const initialState = {
  org: null,
  view: null,
  orgs: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORG:
      return {
        ...state,
        org: payload.org,
        view: payload.view,
        loading: false
      };
    case GET_ORGS:
      return {
        ...state,
        orgs: payload,
        loading: false
      };
    case CLEAR_ORG:
      return {
        ...state,
        org: null,
        loading: false
      };
    case ORG_ERROR:
      return {
        ...state,
        org: null,
        view: null,
        orgs: [],
        loading: false
      };
    default:
      return state;
  }
}
