import { combineReducers } from 'redux';
import auth from './auth';
import profile from './profile';
import tutor from './tutor';
import student from './student';
import alert from './alert';
import chat from './chat';
import org from './org';
import partner from './partner';
export default combineReducers({
  auth,
  profile,
  tutor,
  student,
  alert,
  chat,
  org,
  partner
});
