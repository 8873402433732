import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { leaveOrg, deleteOrg } from '../../../actions/org';
import { setAlert } from '../../../actions/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrgHeader = ({ org, view, leaveOrg, deleteOrg, setAlert }) => {
  const [leave, setLeave] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const handleDelete = e => {
    deleteOrg(org._id);
    setRedirect(true);
  };

  const handleLeave = e => {
    leaveOrg(org._id);
    setRedirect(true);
  };

  const copyToClipboard = e => {
    let textArea = document.createElement('textarea');
    textArea.value = `https://ingenify.org/api/org/${org._id}/join/${org.enrollmentCode}`;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    setAlert('Copied', 'success');
  };

  if (redirect) {
    return <Redirect push to='/organizations' />;
  }

  return (
    <div className='org-header'>
      <img src={org.orgImageUrl} alt='org' />
      <div>
        <h1 className='large'>{org.name}</h1>
        <div>
          <FontAwesomeIcon
            icon='user-friends'
            className='text-secondary'
            title='Members'
          />
          <span className='mx'>{org.members.length}</span>
        </div>
        <div>
          <FontAwesomeIcon
            icon='hourglass-half'
            className='text-secondary'
            title='Hours'
          />
          <span className='mx-1'>{org.hours}</span>
        </div>
        <div>
          <FontAwesomeIcon
            icon='paper-plane'
            className='text-secondary'
            title='Email'
          />
          <span className='mx-1'>{org.email}</span>
        </div>
        {org.website && org.website !== '' ? (
          <div>
            <a href={org.website}>{org.website}</a>
          </div>
        ) : null}
        {org && !org.approved && view === 0 && (
          <p className='text-danger'>
            <i>Pending Approval</i>
          </p>
        )}
        {view === 0 && (
          <p>
            <button
              className='btn btn-secondary'
              style={{ borderRadius: '0.5rem' }}
              onClick={e => copyToClipboard(e)}
            >
              Copy Join Link
            </button>
          </p>
        )}
      </div>
      {view === 0 ? (
        <div>
          {leave ? (
            <div className='float-right'>
              <button
                className='btn btn-text mx'
                onClick={e => handleDelete(e)}
              >
                Confirm
              </button>{' '}
              <button
                className='btn btn-text text-gray'
                onClick={e => setLeave(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={e => setLeave(true)}
              className='float-right btn btn-text'
            >
              Delete Organization
            </button>
          )}
        </div>
      ) : (
        <div>
          {leave ? (
            <div className='float-right'>
              <button className='btn btn-text mx' onClick={e => handleLeave(e)}>
                Confirm
              </button>{' '}
              <button
                className='btn btn-text text-gray'
                onClick={e => setLeave(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={e => setLeave(true)}
              className='float-right btn btn-text'
            >
              Leave Organization
            </button>
          )}
        </div>
      )}
    </div>
  );
};

OrgHeader.propTypes = {
  org: PropTypes.object.isRequired,
  leaveOrg: PropTypes.func.isRequired,
  deleteOrg: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

export default connect(null, { leaveOrg, deleteOrg, setAlert })(OrgHeader);
