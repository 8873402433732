import React from 'react';
import { Link } from 'react-router-dom';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../App.css';

const Footer = ({ backgroundColor = '#f0ebeb' }) => {
  return (
    <footer style={{ backgroundColor }} className='py-2'>
      <div className='separate'>
        <div>
          <div>Contact Us | support@ingenify.org</div>
          <div>
            <Link to='/about'>About</Link> | <Link to='/support'>Support</Link>
          </div>
          <div>
            <Link to='/terms_of_service'>Terms of Service</Link>
          </div>
          <div>
            <Link to='/privacy_policy'>Privacy Policy</Link>
          </div>
        </div>
        <div>
          <p className='s-large'>Follow Us</p>
          <a href='https://www.facebook.com/ingenify' target='_blank'>
            <FontAwesomeIcon
              icon={faFacebook}
              className='fa-medium'
              style={{ color: '3b5998' }}
            />
          </a>
          <a href='https://twitter.com/ingenify' target='_blank'>
            <FontAwesomeIcon
              icon={faTwitter}
              className='fa-medium'
              style={{ color: '#00acee' }}
            />
          </a>
          <a href='https://www.instagram.com/ingenify.inc/' target='_blank'>
            <FontAwesomeIcon
              icon={faInstagram}
              className='fa-medium'
              style={{ color: '#cd486b' }}
            />
          </a>
          <a
            href='https://www.youtube.com/channel/UCHkq81pCphHW-gGId8vr-UQ/featured'
            target='_blank'
          >
            <FontAwesomeIcon
              icon={faYoutube}
              className='fa-medium'
              style={{ color: '#ff0000' }}
            />
          </a>
        </div>
      </div>
      <div>
        <p>Ingenify is a 501(c)(3) nonprofit organization</p>
        <p>&copy; 2021 Ingenify | All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
