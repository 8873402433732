import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const RecentTutor = ({ tutor }) => {
  return (
    <div className='flex-spread' style={{ alignItems: 'center' }}>
      <div className='flex' style={{ alignItems: 'center' }}>
        <img
          src={tutor.profileImageUrl}
          className='profile-img-small'
          alt='tutor'
        />
        <p className='medium mx'>
          <Link to={`/tutors/${tutor._id}`}>{tutor.name}</Link>
        </p>
      </div>
      <div className='flex'>
        <Link to={`/tutors/${tutor._id}`}>
          <button className='btn btn-success'>Write a Review</button>
        </Link>
      </div>
    </div>
  );
};

RecentTutor.propTypes = {
  tutor: PropTypes.object
};

export default RecentTutor;
