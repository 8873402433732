export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const LOGOUT = 'LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_TUTOR = 'GET_TUTOR';
export const TUTOR_ERROR = 'TUTOR_ERROR';
export const GET_TUTORS = 'GET_TUTORS';
export const CLEAR_TUTOR = 'CLEAR_TUTOR';
export const GET_STUDENT = 'GET_STUDENT';
export const STUDENT_ERROR = 'STUDENT_ERROR';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const GET_CHATS = 'GET_CHATS';
export const AFTER_POST_MESSAGE = 'AFTER_POST_MESSAGE';
export const GET_CHAT = 'GET_CHAT';

export const GET_ORG = 'GET_ORG';
export const GET_ORGS = 'GET_ORGS';
export const ORG_ERROR = 'ORG_ERROR';
export const CLEAR_ORG = 'CLEAR_ORG';

export const GET_PARTNERS = 'GET_PARTNERS';
