import React, { useState, useEffect } from 'react';
import { getCurrentProfile } from '../../../actions/profile';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Profile from './Profile';
import Tutor from './Tutor';
import Student from './Student';
import AccountNav from '../AccountNav';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const Settings = ({
  getCurrentProfile,
  profile: { profile, loading },
  user
}) => {
  const [makeTutor, setMakeTutor] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container colored'>
      {user ? (
        <HelmetWrapper
          title={`${user.name} | Ingenify`}
          description="This is the settings page of the Ingenify account. It contains information about the user's profiles."
        />
      ) : (
        <HelmetWrapper
          title={`My Account | Ingenify`}
          description="This is the settings page of the Ingenify account. It contains information about the user's profiles."
        />
      )}
      <div className='account-page'>
        <AccountNav page='profile' />
        <div className='account-body'>
          <Profile />
          {profile && profile.isTutor ? (
            <div>
              <Tutor />
              {/* <Student /> */}
            </div>
          ) : (
            <div>
              {/* <Student /> */}
              {/* <div className='my-1'> */}
              <div className='my'>
                <p className='small text-gray'>Only required for tutors</p>
                <button
                  className='btn btn-secondary'
                  style={{ borderRadius: '0.25rem' }}
                  onClick={e => setMakeTutor(true)}
                >
                  Set up tutor profile
                </button>
                {makeTutor && (
                  <button
                    className='btn btn-light mx-1'
                    style={{ borderRadius: '0.25rem' }}
                    onClick={e => setMakeTutor(false)}
                  >
                    Cancel
                  </button>
                )}
              </div>
              {makeTutor && <Tutor />}
              {/* </div> */}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

Settings.propTypes = {
  profile: PropTypes.object,
  getCurrentProfile: PropTypes.func.isRequired,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  profile: state.profile,
  user: state.auth.user
});

export default connect(mapStateToProps, { getCurrentProfile })(Settings);
