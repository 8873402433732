import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TutorItem = ({ tutor }) => {
  return (
    <div className='flex-spread student-item'>
      <div className='flex'>
        <img src={tutor.profileImageUrl} alt='tutor' />
        <p className='px-1'>
          <Link to={`/tutors/${tutor._id}`}>{tutor.name}</Link>
        </p>
      </div>
    </div>
  );
};

TutorItem.propTypes = {
  tutor: PropTypes.object
};

export default TutorItem;
