import React from 'react';
import { connect } from 'react-redux';
import { unenrollStudent } from '../../../actions/student';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TutorItem = ({ tutor, unenrollStudent }) => {
  const handleUnenroll = e => {
    e.preventDefault();
    unenrollStudent(tutor._id);
  };

  return (
    <div className='flex-spread student-item'>
      <div className='flex'>
        <img src={tutor.profileImageUrl} alt='tutor' />
        <p className='px-1'>
          <Link to={`/tutors/${tutor._id}`}>{tutor.name}</Link>
        </p>
      </div>
      <div className='flex right hide-sm'>
        <div className='px-1 m-auto-vert'>
          <Link to={`/tutors/${tutor._id}`}>
            <button className='btn btn-success'>Write a Review</button>
          </Link>
        </div>
        <div className='m-auto-vert'>
          <button className='btn btn-danger' onClick={e => handleUnenroll(e)}>
            Unenroll
          </button>
        </div>
      </div>
    </div>
  );
};

TutorItem.propTypes = {
  tutor: PropTypes.object,
  unenrollStudent: PropTypes.func.isRequired
};

export default connect(null, { unenrollStudent })(TutorItem);
