import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeMember } from '../../../actions/org';
import '../../../App.css';
const MemberItem = ({ member, view, org, removeMember }) => {
  return (
    <div>
      {view === 1 ? (
        <div className='flex height-3'>
          <img src={member.profileImageUrl} alt='member' />
          <p className='px'>{member.name}</p>
        </div>
      ) : (
        <div className='flex height-3'>
          <img src={member.profileImageUrl} alt='member' />
          <div className='flex-spread'>
            <div className='m-auto-vert'>
              <p className='px'>{member.name}</p>
            </div>
            <div className='flex right hide-sm'>
              <div className='m-auto-vert'>
                <button
                  className='btn btn-danger'
                  onClick={e => removeMember(member._id, org._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MemberItem.propTypes = {
  member: PropTypes.object.isRequired,
  view: PropTypes.number.isRequired,
  removeMember: PropTypes.func.isRequired
};

export default connect(null, { removeMember })(MemberItem);
