import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { claimBadge } from '../../../actions/profile';

const SpecialBadgesDisplay = ({ profile, claimBadge }) => {
  const initialBadgeState = {
    Getting_Started: 'unqualified',
    The_Beginning: 'unqualified',
    Pupil: 'unqualified',
    Undergraduate: 'unqualified',
    Postgraduate: 'unqualified',
    Critic: 'unqualified',
    Connoisseur: 'unqualified',
    Apprentice: 'unqualified',
    Month: 'unqualified',
    '2_Months': 'unqualified',
    '3_Months': 'unqualified',
    Double_Tap: 'unqualified',
    Endurance: 'unqualified',
    Marathon: 'unqualified',
    Referral: 'unqualified',
    Connected: 'unqualified',
    Jackpot: 'unqualified',
    Collector: 'unqualified',
    Patron: 'unqualified',
    National: 'unqualified'
  };
  const [badgeState, setBadgeState] = useState(initialBadgeState);

  useEffect(() => {
    if (profile) {
      const badgeData = { ...initialBadgeState };
      profile.qualifiedBadges.forEach(badgeName => {
        if (profile.claimedBadges.includes(badgeName)) {
          badgeData[badgeName] = 'claimed';
        } else {
          badgeData[badgeName] = 'qualified';
        }
      });
      setBadgeState({ ...badgeData });
    }
  }, [profile]);

  return (
    <div>
      <div className='badge-list'>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Jackpot}`}
            src={require(`../../../img/badge_Jackpot.png`)}
          />
          <h3>Jackpot</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${profile.normalBadges} / 16)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small'>
              Claim all 16 normal badges and obtain Gold Tier
            </p>
            <p className='small'>
              <strong>Reward:</strong> $50 Amazon Giftcard
            </p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Jackpot') &&
            !profile.claimedBadges.includes('Jackpot') && (
              <button
                onClick={e => claimBadge('Jackpot')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Collector}`}
            src={require(`../../../img/badge_Collector.png`)}
          />
          <h3>Collector</h3>
          <div
            style={{
              backgroundColor: '#e4e6e8',
              width: '100%',
              height: '0.5rem',
              borderRadius: '0.5rem'
            }}
          >
            {profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${Math.min(
                    profile.claimedBadges.length,
                    5
                  )} / 5)`,
                  height: '0.5rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            )}
          </div>
          <div className='tier-description'>
            <p className='small text-center'>Collect five badges</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Collector') &&
            !profile.claimedBadges.includes('Collector') && (
              <button
                onClick={e => claimBadge('Collector')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.Patron}`}
            src={require(`../../../img/badge_Patron.png`)}
          />
          <h3>Patron</h3>
          <div className='tier-description'>
            <p className='small text-center'>Donate to Ingenify</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('Patron') &&
            !profile.claimedBadges.includes('Patron') && (
              <button
                onClick={e => claimBadge('Patron')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
        <div className='badge-boxed'>
          <img
            className={`badge-img ${badgeState.National}`}
            src={require(`../../../img/badge_National.png`)}
          />
          <h3>National</h3>
          <div className='tier-description'>
            <p className='small'>Have a tutor/student from a different state</p>
          </div>
          {profile &&
            profile.qualifiedBadges.includes('National') &&
            !profile.claimedBadges.includes('National') && (
              <button
                onClick={e => claimBadge('National')}
                className='btn btn-success claim-btn my'
              >
                Claim Badge
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

SpecialBadgesDisplay.propTypes = {
  profile: PropTypes.object.isRequired,
  claimBadge: PropTypes.func.isRequired
};

export default connect(null, { claimBadge })(SpecialBadgesDisplay);
