import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getOrgById,
  scheduleMeeting,
  refreshMeetings
} from '../../../actions/org';
import OrgHeader from './OrgHeader';
import OrgNav from './OrgNav';
import UpcomingMeetingItem from './UpcomingMeetingItem';
import PastMeetingItem from './PastMeetingItem';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const Meetings = ({
  org: { org, loading, view },
  getOrgById,
  scheduleMeeting,
  refreshMeetings,
  match
}) => {
  useEffect(() => {
    if (match.params.id) {
      getOrgById(match.params.id);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (org && view === 0) {
      refreshMeetings(org._id);
    }
  }, [loading]);

  const initialState = {
    topic: '',
    date: '',
    time: '',
    duration: 0
  };

  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [pastMeetings, setPastMeetings] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [validBrowser, setValidBrowser] = useState(false);

  useEffect(() => {
    let userAgentString = navigator.userAgent;
    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1;

    // Detect Internet Explorer
    let IExplorerAgent =
      userAgentString.indexOf('MSIE') > -1 ||
      userAgentString.indexOf('rv:') > -1;

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf('Firefox') > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;

    // Detect Opera
    let operaAgent = userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if (chromeAgent && operaAgent) chromeAgent = false;

    if (chromeAgent || firefoxAgent) {
      setValidBrowser(true);
    } else {
      setValidBrowser(false);
    }
  }, []);

  useEffect(() => {
    if (org && org.meetings) {
      setUpcomingMeetings(
        org.meetings
          .filter(meeting => !meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return aDate - bDate;
          })
      );
      setPastMeetings(
        org.meetings
          .filter(meeting => meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return bDate - aDate;
          })
      );
    }
  }, [org]);
  const onSubmit = e => {
    e.preventDefault();
    scheduleMeeting(formData, org._id);
    setFormData(initialState);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { date, time, duration, topic } = formData;

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (view === 2 && !loading) {
      setRedirect(true);
    }
  }, [view, loading]);

  if (redirect) {
    return <Redirect push to={`/organizations/${org._id}`} />;
  }

  return (
    <div className='container colored'>
      {org ? (
        <HelmetWrapper
          title={`${org.name} | Ingenify`}
          description="This is the organization's meetings page. It contains the information about the organization's upcoming meetings, past meetings, and meeting participants. It also allows the organization to schedule meetings and reward hours for tutors."
        />
      ) : (
        <HelmetWrapper
          title={`Organization | Ingenify`}
          description="This is the organization's meetings page. It contains the information about the organization's upcoming meetings, past meetings, and meeting participants. It also allows the organization to schedule meetings and reward hours for tutors."
        />
      )}
      <div className='flex-column-center'>
        {org && (
          <div className='org-width'>
            <OrgHeader org={org} view={view} />
            <OrgNav org={org} view={view} />
            <div className='org-body'>
              <div>
                <h1 className='lead'>Upcoming Meetings</h1>
                {view === 0 && (
                  <div>
                    <button
                      className='btn btn-text text-primary'
                      onClick={e => setShowForm(!showForm)}
                    >
                      Schedule Meeting
                    </button>
                    {showForm && validBrowser && (
                      <form className='form' onSubmit={e => onSubmit(e)}>
                        <div className='flex form-group mobile-form'>
                          <div>
                            <div className='medium'>
                              <strong>Topic</strong>
                            </div>
                            <input
                              type='text'
                              name='topic'
                              placeholder='Meeting Topic'
                              style={{ height: '2.6rem' }}
                              onChange={e => onChange(e)}
                              value={topic}
                              required
                            />
                          </div>
                          <div className='mx-2'>
                            <div className='medium'>
                              <strong>Date</strong>
                            </div>
                            <input
                              type='date'
                              name='date'
                              onChange={e => onChange(e)}
                              value={date}
                              required
                            />
                          </div>
                          <div>
                            <div className='medium'>
                              <strong>Time</strong>
                            </div>
                            <input
                              type='time'
                              name='time'
                              onChange={e => onChange(e)}
                              value={time}
                              required
                            />
                          </div>
                          <div className='mx-2'>
                            <div className='medium'>
                              <strong>Duration</strong>
                            </div>
                            <select
                              name='duration'
                              value={duration}
                              onChange={e => onChange(e)}
                              required
                            >
                              <option value='0'>0 minutes</option>
                              <option value='30'>30 minutes</option>
                              <option value='60'>1 hour</option>
                              <option value='90'>1 hour 30 minutes</option>
                              <option value='120'>2 hours</option>
                            </select>
                          </div>
                        </div>
                        <div className='flex-spread'>
                          <input
                            type='submit'
                            className='btn btn-secondary'
                            value='Schedule'
                          />
                          <button
                            className='btn btn-danger'
                            onClick={e => setShowForm(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
                <hr className='my' />
                <ul>
                  {upcomingMeetings.map((meeting, index) => (
                    <li key={index}>
                      <UpcomingMeetingItem
                        meeting={meeting}
                        org={org}
                        view={view}
                      />{' '}
                      <hr className='my' />
                    </li>
                  ))}
                </ul>
              </div>
              {upcomingMeetings.length === 0 && (
                <div style={{ height: '3rem' }}></div>
              )}
              <div>
                <h1 className='lead'>Past Meetings</h1>
                <hr className='my' />
                <ul>
                  {pastMeetings.map((meeting, index) => (
                    <li key={index}>
                      <PastMeetingItem
                        meeting={meeting}
                        org={org}
                        view={view}
                      />{' '}
                      <hr className='my' />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

Meetings.propTypes = {
  getOrgById: PropTypes.func.isRequired,
  scheduleMeeting: PropTypes.func.isRequired,
  refreshMeetings: PropTypes.func.isRequired,
  org: PropTypes.object
};

const mapStateToProps = state => ({
  org: state.org
});

export default connect(mapStateToProps, {
  getOrgById,
  scheduleMeeting,
  refreshMeetings
})(Meetings);
