import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { requestOrg, getCurrentProfile } from '../../../actions/profile';
import { joinOrg } from '../../../actions/org';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../App.css';

const PublicView = ({
  org,
  requestOrg,
  joinOrg,
  getCurrentProfile,
  profile: { profile, loading },
  auth
}) => {
  const [requestData, setRequestData] = useState('');
  const [enrollData, setEnrollData] = useState('');

  useEffect(() => {
    if (auth.user) {
      getCurrentProfile();
    }
  }, [auth]);

  const onRequestChange = e => {
    setRequestData(e.target.value);
  };

  const onEnrollChange = e => {
    setEnrollData(e.target.value);
  };

  const onEnrollSubmit = e => {
    e.preventDefault();
    joinOrg(enrollData, org._id);
  };

  const onRequestSubmit = e => {
    e.preventDefault();
    requestOrg(requestData, org._id);
  };

  return (
    <div className='flex-column-center'>
      <div className='org-info'>
        <div className='org-top'>
          <img src={org.orgImageUrl} alt='organization' />
          <div>
            <h1 className='lead mobile-center'>{org.name}</h1>
            <div>
              <FontAwesomeIcon
                icon='user-friends'
                className='text-secondary'
                title='Members'
              />{' '}
              <span className='mx'>{org.members.length}</span>
            </div>
            <div>
              <FontAwesomeIcon
                icon='hourglass-half'
                className='text-secondary'
                title='Hours'
              />{' '}
              <span className='mx-1'>{org.hours}</span>
            </div>
            <div>
              <FontAwesomeIcon
                icon='paper-plane'
                className='text-secondary'
                title='Email'
              />
              <span className='mx-1'>{org.email}</span>
            </div>
            {org.website ? (
              <div>
                <a href={org.website}>{org.website}</a>
              </div>
            ) : null}
            <form className='form flex' onSubmit={e => onEnrollSubmit(e)}>
              <div className='width-50'>
                <input
                  type='text'
                  placeholder='Enrollment Code'
                  name='code'
                  onChange={e => onEnrollChange(e)}
                  value={enrollData}
                  required
                />
              </div>
              <input
                type='submit'
                style={{ borderRadius: '0.25rem' }}
                className='btn btn-secondary mx-1'
                value='Enroll'
              />
            </form>
          </div>
          <div className='hide-sm'>
            <Link to='/organizations'>Back</Link>
          </div>
        </div>
        <hr />
        <div className='org-bottom' style={{ whiteSpace: 'pre-wrap' }}>
          {org.description}
        </div>
      </div>
      {!profile && (
        <p className='text-danger'>
          <i>Complete profile to send request</i>
        </p>
      )}
      <form
        className='form flex-column-center'
        onSubmit={e => onRequestSubmit(e)}
      >
        <div className='form-group request-form'>
          <textarea
            placeholder='Send Request to Join Organization'
            name='msg'
            value={requestData}
            onChange={e => onRequestChange(e)}
            required
          />
          <input
            className='btn btn-secondary'
            type='submit'
            value='Send Request'
          />
        </div>
      </form>
    </div>
  );
};

PublicView.propTypes = {
  requestOrg: PropTypes.func.isRequired,
  joinOrg: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  org: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(mapStateToProps, {
  requestOrg,
  joinOrg,
  getCurrentProfile
})(PublicView);
