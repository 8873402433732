import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountNav from '../AccountNav';
import {
  createTutor,
  getCurrentTutor,
  deleteTutor
} from '../../../actions/tutor';
import { setAlert } from '../../../actions/alert';
import '../../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const possibleSubjects = [
  'Elementary School Math',
  'Elementary School Reading and Writing',
  'Elementary School Science',
  'Elementary School Social Studies',
  'Middle School Math',
  'Middle School Reading and Writing',
  'Middle School Science',
  'Middle School Social Studies',
  'Algebra',
  'Geometry',
  'Precalculus',
  'Calculus',
  'Biology',
  'Chemistry',
  'Physics',
  'English and Literature',
  'Spanish',
  'French',
  'Chinese',
  'Computer Science',
  'SAT',
  'ACT'
];

const possibleAPs = [
  'AP Research',
  'AP Seminar',
  'AP Art and Design Program',
  'AP Art History',
  'AP Music Theory',
  'AP English Language and Composition',
  'AP English Literature and Composition',
  'AP Comparative Government and Politics',
  'AP European History',
  'AP Human Geography',
  'AP Macroeconomics',
  'AP Microeconomics',
  'AP Psychology',
  'AP United States Government and Politics',
  'AP United States History',
  'AP World History: Modern',
  'AP Calculus AB',
  'AP Calculus BC',
  'AP Computer Science A',
  'AP Computer Science Principles',
  'AP Statistics',
  'AP Biology',
  'AP Chemistry',
  'AP Environmental Science',
  'AP Physics 1',
  'AP Physics 2',
  'AP Physics C',
  'AP Chinese Language and Culture',
  'AP French Language and Culture',
  'AP German Language and Culture',
  'AP Italian Language and Culture',
  'AP Japanese Language and Culture',
  'AP Latin',
  'AP Spanish Language and Culture',
  'AP Spanish Literature and Culture'
];

const Tutor = ({
  tutor: { tutor, loading },
  auth,
  createTutor,
  getCurrentTutor,
  deleteTutor,
  setAlert
}) => {
  const initialForm = {
    subjects: [],
    ap: [],
    bio: '',
    website: '',
    affiliation: '',
    enrollmentCode: '',
    isPublic: true,
    shortTerm: false,
    longTerm: false,
    availability: ''
  };

  const initialAP = {
    courseName: '',
    score: '',
    completed: false
  };

  const [formData, setFormData] = useState(initialForm);

  const [subjectList, setSubjectList] = useState([]);

  const [APList, setAPList] = useState([]);

  const [subjectFormData, setSubjectFormData] = useState('');

  const [selectedAP, setSelectedAP] = useState(initialAP);

  const [deleteClicked, setDeleteClicked] = useState(false);

  useEffect(() => {
    getCurrentTutor();
  }, []);

  useEffect(() => {
    if (!loading) {
      setFormData({ ...formData, subjects: subjectList });
    }
  }, [loading, subjectList]);

  useEffect(() => {
    if (!loading) {
      setFormData({ ...formData, ap: APList });
    }
  }, [loading, APList]);

  useEffect(() => {
    if (tutor && !loading) {
      const tutorData = { ...initialForm };
      for (const key in tutor) {
        if (key in tutorData) tutorData[key] = tutor[key];
      }
      setFormData(tutorData);
      setSubjectList(tutorData.subjects);
      setAPList(tutorData.ap);
    }
  }, [loading, tutor]);

  const onSubmit = e => {
    e.preventDefault();
    if (bio.split(' ').length < 50) {
      setAlert('Bio is too short, must be at least 50 words', 'danger');
      return;
    }
    createTutor(formData);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onPublicChange = e => {
    setFormData({ ...formData, isPublic: !isPublic });
  };

  const onShortTermChange = e => {
    setFormData({ ...formData, shortTerm: !shortTerm });
  };

  const onLongTermChange = e => {
    setFormData({ ...formData, longTerm: !longTerm });
  };

  const onSubjectFormChange = e => {
    setSubjectFormData(e.target.value);
  };

  const onSubjectFormSubmit = e => {
    e.preventDefault();
    const exists = possibleSubjects.find(
      subject => subject === subjectFormData
    );
    if (exists && subjectFormData && !subjectList.includes(subjectFormData)) {
      setSubjectList([...subjectList, subjectFormData]);
    }
    setSubjectFormData('');
  };

  const onAPFormSubmit = e => {
    e.preventDefault();
    const exists = possibleAPs.find(ap => ap === selectedAP.courseName);
    const alreadySelected = APList.find(
      ap => ap.courseName === selectedAP.courseName
    );
    if (!selectedAP.score || isNaN(selectedAP.score)) {
      setAlert('You must submit your score', 'danger');
      return;
    }
    if (exists && selectedAP.courseName !== '' && !alreadySelected) {
      setAPList([...APList, selectedAP]);
    }
    setSelectedAP(initialAP);
  };

  const onAPRemove = (e, index) => {
    e.preventDefault();
    setAPList(APList.filter((ap, i) => i !== index));
  };

  const onRemove = (e, index) => {
    e.preventDefault();
    setSubjectList(subjectList.filter((subject, i) => i !== index));
  };

  const {
    subjects,
    bio,
    website,
    enrollmentCode,
    affiliation,
    isPublic,
    shortTerm,
    longTerm,
    availability
  } = formData;

  return (
    <div>
      <div>
        {!auth.loading && auth.user && !auth.user.zoomAuthorized ? (
          <div>
            <div className='flex'>
              <h1 className='lead'>Authorize Zoom</h1>{' '}
              <p className='text-danger'>*</p>
            </div>
            <div className='flex zoom-button'>
              <a
                href='https://ingenify.org/api/auth/zoom'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src='https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png'
                  alt='Add to ZOOM'
                />
              </a>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        {!auth.loading && auth.user && !auth.user.calendarAuthorized ? (
          <div>
            <div className='flex'>
              <h1 className='lead'>Authorize Google Calendar</h1>{' '}
              <p className='text-danger'>*</p>
            </div>
            <div className='flex zoom-button'>
              <a
                href='https://ingenify.org/api/auth/google/calendar'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button className='btn btn-secondary'>
                  Enable Google Calendar Sync
                </button>
              </a>
            </div>
          </div>
        ) : null}
      </div>
      <div className='flex'>
        <h1 className='lead'>Tutoring Profile</h1>{' '}
        {!tutor && !loading && <p className='text-danger'>*</p>}
      </div>
      {tutor && !tutor.approved && (
        <p className='text-danger'>
          <i>Pending Approval</i>
        </p>
      )}
      {/* <p className='my-1'>
          <strong>Requirements:</strong>
          <ul>
            <li className='mx-1'>1. You must be in High School</li>
            <li className='mx-1'>
              2. Your weighted GPA is above 3.7 or 97 numeric average
            </li>
            <li className='mx-1'>
              3. You have achieved the highest honor roll at your school for at
              least two semesters
            </li>
          </ul>
        </p> */}
      <form className='form' onSubmit={e => onSubmit(e)}>
        <div className='form-group'>
          <p className='small text-gray'>
            Bio{bio === '' && <span className='text-danger'>*</span>}
          </p>
          <textarea
            placeholder='Why students should choose you...'
            name='bio'
            className='bio-input'
            onChange={e => onChange(e)}
            value={bio}
            required
          />
        </div>
        <div className='form-group'>
          <p className='small text-gray'>Affiliation</p>
          <input
            type='text'
            placeholder='Organization you volunteer for (if applicable)'
            name='affiliation'
            onChange={e => onChange(e)}
            value={affiliation}
          />
        </div>
        <div className='form-group'>
          <h1 className='medium'>Subjects</h1>
          <ul>
            {subjectList.map((subject, index) => (
              <div className='subject-item my' key={index}>
                <li>{subject}</li>
                <FontAwesomeIcon
                  onClick={e => onRemove(e, index)}
                  className='icon-btn'
                  icon='minus-circle'
                />
              </div>
            ))}
          </ul>
          <div className='form-group'>
            <div className='new-subject-item'>
              <div style={{ width: '80%' }}>
                <input
                  type='text'
                  placeholder='Add Subject'
                  autoComplete='off'
                  name='subject'
                  onChange={e => onSubjectFormChange(e)}
                  value={subjectFormData}
                  list='subject'
                />
                <datalist id='subject'>
                  {possibleSubjects.map((subject, index) => (
                    <option key={index} value={subject}>
                      {subject}
                    </option>
                  ))}
                </datalist>
              </div>
              <FontAwesomeIcon
                icon='plus-circle'
                onClick={e => onSubjectFormSubmit(e)}
                className='icon-btn'
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <h1 className='medium'>AP</h1>
          <ul>
            {APList.map((ap, index) => (
              <div className='subject-item my' key={index}>
                <li className='flex-spread' style={{ width: '60%' }}>
                  <p>{ap.courseName}</p>
                  <p style={{ color: '#28a745' }}>{ap.score}</p>
                </li>
                <FontAwesomeIcon
                  onClick={e => onAPRemove(e, index)}
                  className='icon-btn'
                  icon='minus-circle'
                />
              </div>
            ))}
          </ul>
          <div className='form-group'>
            <div className='new-subject-item'>
              <div style={{ width: '80%' }} className='flex-spread'>
                <div style={{ width: '80%' }}>
                  <p className='small text-gray'>Course Name</p>
                  <input
                    type='text'
                    placeholder='Add AP'
                    autoComplete='off'
                    name='subject'
                    onChange={e =>
                      setSelectedAP({
                        ...selectedAP,
                        courseName: e.target.value
                      })
                    }
                    value={selectedAP.courseName}
                    list='ap'
                  />
                  <datalist id='ap'>
                    {possibleAPs.map((ap, index) => (
                      <option key={index} value={ap}>
                        {ap}
                      </option>
                    ))}
                  </datalist>
                </div>
                {selectedAP.completed ? (
                  <div style={{ width: '10%' }}>
                    <p className='small text-gray'>Exam Score</p>
                    <input
                      type='text'
                      name='score'
                      onChange={e =>
                        setSelectedAP({ ...selectedAP, score: e.target.value })
                      }
                      value={selectedAP.score}
                    />
                  </div>
                ) : (
                  <div style={{ width: '10%' }}>
                    <p className='small text-gray'>Average</p>
                    <input
                      type='text'
                      name='score'
                      onChange={e =>
                        setSelectedAP({ ...selectedAP, score: e.target.value })
                      }
                      value={selectedAP.score}
                    />
                  </div>
                )}
              </div>
              <FontAwesomeIcon
                icon='plus-circle'
                onClick={e => onAPFormSubmit(e)}
                className='icon-btn'
              />
            </div>
            <div>
              <input
                type='checkbox'
                name='completed'
                checked={selectedAP.completed}
                onChange={e =>
                  setSelectedAP({
                    ...selectedAP,
                    completed: !selectedAP.completed
                  })
                }
              />
              <label htmlFor='completed'>{` Exam Taken`}</label>
            </div>
          </div>
        </div>
        <div className='form-group'>
          <h1 className='medium'>Availability</h1>
          <input
            type='checkbox'
            name='shortTerm'
            checked={shortTerm}
            onChange={e => onShortTermChange(e)}
          />
          <label htmlFor='shortTerm'>{` Short Term Help`}</label>
          <input
            style={{ marginLeft: '2rem' }}
            type='checkbox'
            name='longTerm'
            checked={longTerm}
            onChange={e => onLongTermChange(e)}
          />
          <label htmlFor='longTerm'>{` Long Term Help`}</label>
          <div>
            <p className='small text-gray'>
              Availability{' '}
              {availability === '' && <span className='text-danger'>*</span>}
            </p>
            <input
              type='text'
              placeholder='Ex. Mon, Wed, Fri evenings'
              name='availability'
              value={availability}
              onChange={e => onChange(e)}
              required
            />
          </div>
        </div>
        <div className='form-group'>
          <p className='small text-gray'>
            Enrollment Code {'(created by you)'}
            {enrollmentCode === '' && <span className='text-danger'>*</span>}
          </p>
          <input
            type='text'
            placeholder='Personal Enrollment Code'
            name='enrollmentCode'
            value={enrollmentCode}
            title='Students sign up with you by entering this code'
            onChange={e => onChange(e)}
            required
          />
        </div>
        <div className='form-group'>
          <input
            type='checkbox'
            name='isPublic'
            checked={isPublic}
            onChange={e => onPublicChange(e)}
          />
          <label htmlFor='isPublic'>{` Check if you would like to receive requests from students`}</label>
        </div>
        <div className='form-group flex-spread'>
          {tutor ? (
            <input
              className='btn btn-secondary'
              style={{ borderRadius: '0.25rem' }}
              type='submit'
              value='Save'
            />
          ) : (
            <input
              className='btn btn-secondary'
              type='submit'
              value='Activate Tutor Profile'
              style={{ borderRadius: '0.25rem' }}
            />
          )}
          {tutor ? (
            <div>
              {deleteClicked ? (
                <div>
                  <button
                    className='btn btn-text mx'
                    onClick={e => {
                      deleteTutor();
                      setDeleteClicked(false);
                    }}
                  >
                    Confirm
                  </button>{' '}
                  <button
                    className='btn btn-text text-gray'
                    onClick={e => setDeleteClicked(false)}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className='btn btn-text'
                  onClick={e => setDeleteClicked(true)}
                >
                  Delete Tutor Profile
                </button>
              )}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

Tutor.propTypes = {
  tutor: PropTypes.object,
  auth: PropTypes.object.isRequired,
  createTutor: PropTypes.func.isRequired,
  getCurrentTutor: PropTypes.func.isRequired,
  deleteTutor: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  tutor: state.tutor,
  auth: state.auth
});

export default connect(mapStateToProps, {
  createTutor,
  getCurrentTutor,
  deleteTutor,
  setAlert
})(Tutor);
