import React from 'react';
import PropTypes from 'prop-types';

const TierDisplay = ({ profile, hours, claimTier }) => {
  return (
    <div className='ninety-percent'>
      <p className='lead text-center'>Tiers</p>
      <div>
        <div
          style={{
            backgroundColor: '#e4e6e8',
            width: '100%',
            height: '1rem',
            borderRadius: '0.5rem'
          }}
        >
          {(profile && (!profile.tier || profile.tier === 'unranked')) ||
            (!profile && (
              <div
                style={{
                  backgroundColor: '#90ee90',
                  width: `calc(100% * ${hours} / 5)`,
                  height: '1rem',
                  borderRadius: '0.5rem'
                }}
              ></div>
            ))}
          {profile && profile.tier === 'bronze' && (
            <div
              style={{
                backgroundColor: '#90ee90',
                width: `calc(100% * ${hours} / 10)`,
                height: '1rem',
                borderRadius: '0.5rem'
              }}
            ></div>
          )}
          {profile && profile.tier === 'silver' && (
            <div
              style={{
                backgroundColor: '#90ee90',
                width: `calc(100% * ${hours} / 15)`,
                height: '1rem',
                borderRadius: '0.5rem'
              }}
            ></div>
          )}
          {profile && profile.tier === 'gold' && (
            <div
              style={{
                backgroundColor: '#90ee90',
                width: '100%',
                height: '1rem',
                borderRadius: '0.5rem'
              }}
            ></div>
          )}
        </div>
        {!profile || (profile.tier === 'unranked' && <div>{hours} / 5</div>)}
        {profile && profile.tier === 'bronze' && <div>{hours} / 10</div>}
        {profile && profile.tier === 'silver' && <div>{hours} / 15</div>}
        {profile && profile.tier === 'gold' && <div>{hours} / 15</div>}
      </div>
      <div className='tier-list my'>
        <div className='boxed'>
          <img src={require(`../../../img/tier_bronze.png`)} />
          <h3>Bronze Tier</h3>
          <div className='tier-description'>
            <p className='small'>Obtain by teaching or learning for 5+ hours</p>
          </div>
        </div>
        <div className='boxed'>
          <img src={require(`../../../img/tier_silver.png`)} />
          <h3>Silver Tier</h3>
          <div className='tier-description'>
            <p className='small'>
              Obtain by teaching or learning for 10+ hours
            </p>
            <p className='small'>
              <strong>Reward:</strong> Ingenify t-shirt
            </p>
          </div>
          {profile &&
            profile.qualifiedTiers.includes('silver') &&
            !profile.claimedTiers.includes('silver') && (
              <button
                onClick={e => claimTier('silver')}
                className='btn btn-success claim-btn my'
              >
                Claim Reward
              </button>
            )}
        </div>
        <div className='boxed'>
          <img src={require(`../../../img/tier_gold.png`)} />
          <h3>Gold Tier</h3>
          <div className='tier-description'>
            <p className='small'>
              Obtain by teaching or learning for 15+ hours
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

TierDisplay.propTypes = {
  profile: PropTypes.object.isRequired,
  hours: PropTypes.number.isRequired,
  claimTier: PropTypes.func.isRequired
};

export default TierDisplay;
