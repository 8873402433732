import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { set } from 'mongoose';

const StreakBar = ({ profile }) => {
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(5);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [longestStreak, setLongestStreak] = useState(0);
  const [haveMeeting, setHaveMeeting] = useState(true);
  useEffect(() => {
    if (profile) {
      if (profile.currentStreak >= 3) {
        setMin(profile.currentStreak - (profile.currentStreak % 5) + 1);
      }
      setCurrentStreak(profile.currentStreak);
      setLongestStreak(profile.longestStreak);
      if (
        profile.meetingWeeks[0] ===
        Math.floor(Date.now() / 1000 / 60 / 60 / 24 / 7)
      ) {
        setHaveMeeting(false);
      }
    }
  }, [profile]);
  return (
    <div className='streak-bar'>
      <p className='lead my' style={{ textAlign: 'center' }}>
        Weekly Streaks
      </p>
      <ul className='flex-even'>
        {min <= currentStreak ? (
          <li className='fill'>
            <strong>{min}</strong>
          </li>
        ) : (
          <li className='unfill'>
            <strong>{min}</strong>
          </li>
        )}
        {min + 1 <= currentStreak ? (
          <li className='fill'>
            <strong>{min + 1}</strong>
          </li>
        ) : (
          <li className='unfill'>
            <strong>{min + 1}</strong>
          </li>
        )}
        {min + 2 <= currentStreak ? (
          <li className='fill'>
            <strong>{min + 2}</strong>
          </li>
        ) : (
          <li className='unfill'>
            <strong>{min + 2}</strong>
          </li>
        )}
        {min + 3 <= currentStreak ? (
          <li className='fill'>
            <strong>{min + 3}</strong>
          </li>
        ) : (
          <li className='unfill'>
            <strong>{min + 3}</strong>
          </li>
        )}
        {min + 4 <= currentStreak ? (
          <li className='fill'>
            <strong>{min + 4}</strong>
          </li>
        ) : (
          <li className='unfill'>
            <strong>{min + 4}</strong>
          </li>
        )}
      </ul>
      <p className='small my-1' style={{ textAlign: 'center' }}>
        {haveMeeting ? (
          <p>Have a meeting this week to keep your streak!</p>
        ) : (
          <p>Have a meeting next week to keep your streak!</p>
        )}
      </p>
    </div>
  );
};

StreakBar.propTypes = {
  profile: PropTypes.object.isRequired
};

export default StreakBar;
