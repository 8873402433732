import axios from 'axios';
import { setAlert } from './alert';
import { loadUser } from './auth';
import { GET_PROFILE, PROFILE_ERROR } from './types';

export const getCurrentProfile = () => async dispatch => {
  try {
    const res = await axios.get('/api/profile/me');
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
    dispatch({
      type: PROFILE_ERROR
    });
  }
};

export const createProfile = ({ formData }) => async dispatch => {
  try {
    const res = await axios.post('/api/profile', formData);
    dispatch(loadUser());
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    dispatch(setAlert('Profile Updated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;
    errors.forEach(error => {
      dispatch(setAlert(error.msg, 'danger'));
    });
  }
};

export const uploadProfileImage = file => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    await axios.post('/api/profile/upload', formData, config);
    dispatch(loadUser());
    dispatch(setAlert('Image Uploaded', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const deleteProfile = () => async dispatch => {
  try {
    await axios.delete('/api/profile');
    dispatch({
      type: PROFILE_ERROR
    });
    dispatch(setAlert('Profile Deleted', 'success'));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR
    });
  }
};

export const requestOrg = (formData, orgId) => async dispatch => {
  const body = { msg: formData };
  try {
    const res = await axios.post(`/api/profile/org/${orgId}/request`, body);
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    dispatch(setAlert('Request Sent', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const claimTier = tierName => async dispatch => {
  try {
    const res = await axios.get(`/api/profile/tiers/claim/${tierName}`);
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    dispatch(setAlert('Reward Claimed', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const claimBadge = badgeName => async dispatch => {
  try {
    const res = await axios.get(`/api/profile/badges/claim/${badgeName}`);
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    dispatch(setAlert('Badge Claimed', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const checkSocial = username => async dispatch => {
  const body = { username };
  try {
    const res = await axios.post(`/api/profile/check_social`, body);
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    dispatch(setAlert('Username Submitted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const checkReferral = referralCode => async dispatch => {
  const body = { referralCode: referralCode.toLowerCase() };
  try {
    const res = await axios.post(`/api/profile/check_referral`, body);
    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
    dispatch(setAlert('Referral Submitted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};
