import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountNav from '../AccountNav';
import { getCurrentProfile } from '../../../actions/profile';
import { getCurrentTutor } from '../../../actions/tutor';
import { getCurrentStudent } from '../../../actions/student';
import SpecialBadgesDisplay from '../dashboard/SpecialBadgesDisplay';
import NormalBadgesDisplay from './NormalBadgesDisplay';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const Achievement = ({
  profile,
  tutor,
  student,
  user,
  getCurrentTutor,
  getCurrentStudent,
  getCurrentProfile
}) => {
  useEffect(() => {
    getCurrentProfile();
    getCurrentStudent();
    getCurrentTutor();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='container colored'>
      {user ? (
        <HelmetWrapper
          title={`${user.name} | Ingenify`}
          description='This is the achievements page of the Ingenify account. It contains information about what badges the user can claim and the progress on each of the badges.'
        />
      ) : (
        <HelmetWrapper
          title={`My Account | Ingenify`}
          description='This is the achievements page of the Ingenify account. It contains information about what badges the user can claim and the progress on each of the badges.'
        />
      )}
      <div className='account-page'>
        <AccountNav page='achievements' />
        <div className='account-body'>
          {/* <h1 className='lead text-center'>Achievements</h1> */}
          {/* <hr /> */}
          <div>
            <p className='lead px-1'>Special Badges</p>
            <hr />
            <SpecialBadgesDisplay profile={profile} />
          </div>
          <div>
            <p className='lead px-1'>Normal Badges</p>
            <hr />
            <NormalBadgesDisplay
              profile={profile}
              student={student}
              tutor={tutor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Achievement.propTypes = {
  profile: PropTypes.object,
  tutor: PropTypes.object,
  student: PropTypes.object,
  user: PropTypes.object,
  getCurrentStudent: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getCurrentTutor: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  tutor: state.tutor.tutor,
  student: state.student.student,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getCurrentTutor,
  getCurrentStudent
})(Achievement);
