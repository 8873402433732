import React from 'react';

import ChatItem from './ChatItem';

const SideBar = ({ newSpecifiedChat, chats, user }) => {
  return (
    <div className='chat-side-bar'>
      <ul>
        {chats &&
          chats.map((chat, index) => (
            <div key={index}>
              <li onClick={e => newSpecifiedChat(e, chat)}>
                <ChatItem chat={chat} user={user} />
              </li>
              <hr />
            </div>
          ))}
      </ul>
    </div>
  );
};

export default SideBar;
