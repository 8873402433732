import React from 'react';
import moment from 'moment';

const ChatMessage = ({ message }) => {
  return (
    <div className='chat-message'>
      <div className='flex'>
        <img src={message.sentUser.profileImageUrl} alt='user' />
        <div className='mx'>
          <p className='small'>{message.sentUser.name}</p>
          <p className='x-small'>{moment(message.sentTime).fromNow()}</p>
        </div>
      </div>
      <div className='chat-text'>
        <p style={{ whiteSpace: 'pre-wrap' }}>{message.sentMessage}</p>
      </div>
    </div>
  );
};

export default ChatMessage;
