import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
const OrgNav = ({ org, view }) => {
  return (
    <div className='org-body'>
      <ul className='flex org-nav'>
        <li>
          <Link to={`/organizations/${org._id}/meetings`}>Meetings</Link>
        </li>
        <li className='mx-2'>
          <Link to={`/organizations/${org._id}/announcements`}>
            Announcements
          </Link>
        </li>
        <li>
          <Link to={`/organizations/${org._id}/members`}>Members</Link>
        </li>
        {view == 0 && (
          <li className='mx-2'>
            <Link to={`/organizations/${org._id}/edit`}>Edit Organization</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

OrgNav.propTypes = {
  org: PropTypes.object.isRequired
};

export default OrgNav;
