import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../../App.css';

const OrgItem = ({ org }) => {
  return (
    <div className='flex student-item'>
      <img src={org.orgImageUrl} alt='org' />
      <p className='px-1'>
        <Link to={`/organizations/${org._id}`}>{org.name}</Link>
      </p>
    </div>
  );
};

OrgItem.propTypes = {
  org: PropTypes.object.isRequired
};

export default OrgItem;
