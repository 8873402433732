import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getOrgById, clearOrg } from '../../../actions/org';
import PublicView from './PublicView';
import Footer from '../../layout/Footer';
import HelmetWrapper from '../../utils/HelmetWrapper';
import '../../../App.css';
import { Redirect } from 'react-router-dom';

const OrgPage = ({
  org: { org, loading, view },
  getOrgById,
  clearOrg,
  match
}) => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (match.params.id) {
      getOrgById(match.params.id);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (!loading && org && view !== 2) {
      setRedirect(true);
    }
  }, [org, loading, view]);

  if (redirect) {
    return <Redirect push to={`/organizations/${org._id}/meetings`} />;
  }

  return (
    <div className='container colored'>
      {org ? (
        <HelmetWrapper
          title={`${org.name} | Ingenify`}
          description="This is the organization's public profile page. It contains the organization's name, number of members, number of hours, contact information, and description."
        />
      ) : (
        <HelmetWrapper
          title={`Organization | Ingenify`}
          description="This is the organization's public profile page. It contains the organization's name, number of members, number of hours, contact information, and description."
        />
      )}
      {org ? <div>{view === 2 && <PublicView org={org} />}</div> : null}
      <Footer />
    </div>
  );
};

OrgPage.propTypes = {
  getOrgById: PropTypes.func.isRequired,
  clearOrg: PropTypes.func.isRequired,
  org: PropTypes.object
};

const mapStateToProps = state => ({
  org: state.org
});

export default connect(mapStateToProps, { getOrgById, clearOrg })(OrgPage);
