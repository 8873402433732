import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getOrgById, sendAnnouncement } from '../../../actions/org';
import OrgHeader from './OrgHeader';
import OrgNav from './OrgNav';
import AnnouncementItem from './AnnouncementItem';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const Announcements = ({
  org: { org, loading, view },
  getOrgById,
  sendAnnouncement,
  match
}) => {
  useEffect(() => {
    if (match.params.id) {
      getOrgById(match.params.id);
    }
  }, [match.params.id]);

  const [formData, setFormData] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    sendAnnouncement(formData, org._id);
    setFormData('');
  };

  const onChange = e => {
    setFormData(e.target.value);
  };

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (view === 2 && !loading) {
      setRedirect(true);
    }
  }, [view, loading]);

  if (redirect) {
    return <Redirect push to={`/organizations/${org._id}`} />;
  }

  return (
    <div className='container colored'>
      {org ? (
        <HelmetWrapper
          title={`${org.name} | Ingenify`}
          description="This is the organization's announcemnt page. It contains the organization's announcements."
        />
      ) : (
        <HelmetWrapper
          title={`Organization | Ingenify`}
          description="This is the organization's announcemnt page. It contains the organization's announcements."
        />
      )}
      <div className='flex-column-center'>
        {org && (
          <div className='org-width'>
            <OrgHeader org={org} view={view} />
            <OrgNav org={org} view={view} />
            <div className='org-body'>
              <div className='announcement-container'>
                <ul>
                  {org &&
                    org.announcements &&
                    org.announcements.map((announcement, index) => (
                      <li key={index}>
                        <hr className='my' />
                        <AnnouncementItem
                          org={org}
                          announcement={announcement}
                        />
                      </li>
                    ))}
                </ul>
              </div>
              {view === 0 && (
                <form
                  className='form org-form-edit'
                  onSubmit={e => onSubmit(e)}
                >
                  <div className='form-group'>
                    <textarea
                      name='announcement'
                      placeholder='Send Announcement'
                      onChange={e => onChange(e)}
                      value={formData}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      type='submit'
                      value='Send'
                      className='btn btn-secondary'
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

Announcements.propTypes = {
  getOrgById: PropTypes.func.isRequired,
  sendAnnouncement: PropTypes.func.isRequired,
  org: PropTypes.object
};

const mapStateToProps = state => ({
  org: state.org
});

export default connect(mapStateToProps, { getOrgById, sendAnnouncement })(
  Announcements
);
