import { GET_CHATS, GET_CHAT, AFTER_POST_MESSAGE } from '../actions/types';

const initialState = {
  chats: [],
  chat: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CHATS:
      return { ...state, chats: payload };
    case GET_CHAT:
      return { ...state, chat: payload };
    case AFTER_POST_MESSAGE:
      let ind = 0;
      for (let i = 0; i < state.chats.length; i++) {
        if (state.chats[i]._id === payload.chat) {
          //CHANGE HERE IN FUTURE?
          ind = i;
        }
      }
      if (state.chat && state.chat._id === payload.chat) {
        return {
          ...state,
          chat: {
            ...state.chat,
            messages: [...state.chat.messages, payload]
          },
          chats: [
            {
              ...state.chats[ind],
              messages: [...state.chats[ind].messages, payload]
            },
            ...state.chats.slice(0, ind),
            ...state.chats.slice(ind + 1)
          ]
        };
      } else {
        return {
          ...state,
          chats: [
            {
              ...state.chats[ind],
              messages: [...state.chats[ind].messages, payload]
            },
            ...state.chats.slice(0, ind),
            ...state.chats.slice(ind + 1)
          ]
        };
      }
    default:
      return state;
  }
}
