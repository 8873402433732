import {
  GET_TUTOR,
  CLEAR_TUTOR,
  TUTOR_ERROR,
  GET_TUTORS
} from '../actions/types';

const initialState = {
  tutor: null,
  tutors: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TUTOR:
      return {
        ...state,
        tutor: payload,
        loading: false
      };
    case GET_TUTORS:
      return {
        ...state,
        tutors: payload,
        loading: false
      };
    case CLEAR_TUTOR:
      return {
        ...state,
        tutor: null,
        loading: false
      };
    case TUTOR_ERROR:
      return {
        ...state,
        tutor: null,
        tutors: [],
        loading: false
      };
    default:
      return state;
  }
}
