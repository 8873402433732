import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountNav from '../AccountNav';
import { getCurrentProfile } from '../../../actions/profile';
import {
  getCurrentTutor,
  acceptStudent,
  declineStudent
} from '../../../actions/tutor';
import { getCurrentStudent } from '../../../actions/student';
import StudentItem from './StudentItem';
import TutorItem from './TutorItem';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const Request = ({
  profile,
  tutor,
  student,
  user,
  getCurrentTutor,
  getCurrentStudent,
  getCurrentProfile,
  acceptStudent,
  declineStudent
}) => {
  useEffect(() => {
    getCurrentProfile();
    getCurrentStudent();
    getCurrentTutor();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container colored'>
      {user ? (
        <HelmetWrapper
          title={`${user.name} | Ingenify`}
          description='This is the requests page of the Ingenify account. It contains information about the pending requests of the user.'
        />
      ) : (
        <HelmetWrapper
          title={`My Account | Ingenify`}
          description='This is the requests page of the Ingenify account. It contains information about the pending requests of the user.'
        />
      )}
      <div className='account-page'>
        <AccountNav page='requests' />
        <div className='account-body'>
          <p className='lead'>Pending Requests</p>
          <hr className='my' />
          {tutor &&
            student &&
            student.requested &&
            student.requested.length > 0 && (
              <p className='medium'>Student Requests</p>
            )}
          {tutor && (
            <div>
              <ul>
                {tutor.requests.map((student, index) => (
                  <li key={index}>
                    <StudentItem student={student} />
                    <hr className='my' />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {tutor &&
            student &&
            student.requested &&
            student.requested.length > 0 && (
              <p className='medium'>Tutor Requests</p>
            )}
          {student && (
            <div>
              <ul>
                {student.requested.map((tutor, index) => (
                  <li key={index}>
                    <TutorItem tutor={tutor} />
                    <hr className='my' />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

Request.propTypes = {
  profile: PropTypes.object,
  tutor: PropTypes.object,
  student: PropTypes.object,
  user: PropTypes.object,
  getCurrentStudent: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getCurrentTutor: PropTypes.func.isRequired,
  acceptStudent: PropTypes.func.isRequired,
  declineStudent: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  tutor: state.tutor.tutor,
  student: state.student.student,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getCurrentStudent,
  getCurrentTutor,
  acceptStudent,
  declineStudent
})(Request);
