import React, { Fragment, useEffect } from 'react';
import './App.css';
import Landing from './components/layout/Landing';
import Navbar from './components/layout/Navbar';
import PrivateRoute from './components/routing/PrivateRoute';
import Dashboard from './components/account/dashboard/Dashboard';
import Meeting from './components/account/meetings/Meeting';
import Achievement from './components/account/achievements/Achievement';
import Settings from './components/account/settings/Settings';
import Tutors from './components/tutors/Tutors';
import TutorPage from './components/tutors/TutorPage';
import Alert from './components/layout/Alert';
import Privacy from './components/layout/Privacy';
import Terms from './components/layout/Terms';
import Footer from './components/layout/Footer';
import Support from './components/layout/Support';
import FAQ from './components/layout/FAQ';
import About from './components/layout/About';
import Request from './components/account/requests/Request';

import Chat from './components/chat/Chat';

import Orgs from './components/orgs/Orgs';
import OrgPage from './components/orgs/orgPage/OrgPage';
import OrgForm from './components/orgs/OrgForm';
import MyOrgs from './components/account/orgs/MyOrgs';
import Announcements from './components/orgs/orgPage/Announcements';
import Meetings from './components/orgs/orgPage/Meetings';
import Members from './components/orgs/orgPage/Members';
import EditOrg from './components/orgs/orgPage/EditOrg';

import Partners from './components/layout/Partners';

//redux
import { loadUser } from './actions/auth';
import { Provider } from 'react-redux';
import store from './store';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
//google analytics
import ReactGA from 'react-ga';
//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faSignOutAlt,
  faUser,
  faHome,
  faPlusCircle,
  faMinusCircle,
  faSchool,
  faGraduationCap,
  faUserFriends,
  faHourglassHalf,
  faQuestion,
  faVideo,
  faCheckSquare,
  faComments,
  faUsers,
  faPaperPlane,
  faStar,
  faSitemap,
  faHandsHelping,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faCheck,
  faSignOutAlt,
  faUser,
  faHome,
  faPlusCircle,
  faMinusCircle,
  faSchool,
  faGraduationCap,
  faUserFriends,
  faHourglassHalf,
  faQuestion,
  faVideo,
  faCheckSquare,
  faComments,
  faUsers,
  faPaperPlane,
  faStar,
  faSitemap,
  faHandsHelping,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faSearch
);

// ReactGA.initialize('UA-197626854-1');

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  useEffect(() => {
    ReactGA.initialize('UA-197626854-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <Route exact path='/' component={Landing} />
        <Switch>
          <PrivateRoute exact path='/account/' component={Dashboard} />
          <PrivateRoute exact path='/account/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/account/meetings' component={Meeting} />
          <PrivateRoute
            exact
            path='/account/achievements'
            component={Achievement}
          />

          <PrivateRoute exact path='/account/profile' component={Settings} />

          <PrivateRoute exact path='/chat' component={Chat} />

          <PrivateRoute
            exact
            path='/account/organizations'
            component={MyOrgs}
          />

          <PrivateRoute exact path='/account/requests' component={Request} />

          <Route exact path='/tutors' component={Tutors} />
          <Route exact path='/tutors/:id' component={TutorPage} />
          <Route exact path='/organizations' component={Orgs} />
          <PrivateRoute
            exact
            path='/create/organizations'
            component={OrgForm}
          />
          <Route exact path='/organizations/:id' component={OrgPage} />
          <PrivateRoute
            exact
            path='/organizations/:id/announcements'
            component={Announcements}
          />
          <PrivateRoute
            exact
            path='/organizations/:id/meetings'
            component={Meetings}
          />
          <PrivateRoute
            exact
            path='/organizations/:id/members'
            component={Members}
          />
          <PrivateRoute
            exact
            path='/organizations/:id/edit'
            component={EditOrg}
          />
          <Route exact path='/privacy_policy' component={Privacy} />
          <Route exact path='/terms_of_service' component={Terms} />
          <Route exact path='/support' component={Support} />
          <Route exact path='/faq' component={FAQ} />
          <Route exact path='/partners' component={Partners} />
          <Route exact path='/about' component={About} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default withRouter(App);
