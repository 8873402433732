import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Rating from 'react-rating';

const grades = {
  0: 'K',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: 'College'
};

const TutorItem = ({ tutor }) => {
  const { profile, user } = tutor;
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect push to={`/tutors/${user._id}`} />;
  }

  return (
    <div className='profile' onClick={e => setRedirect(true)}>
      <img src={`${user.profileImageUrl}`} alt='user' />
      <div>
        <div className='mobile-center'>
          <Link to={`/tutors/${user._id}`} className='lead'>
            <strong style={{ color: '#333' }}>{user.name}</strong>
          </Link>
        </div>
        <div>
          {/* <FontAwesomeIcon
            icon='star'
            className='text-secondary'
            title='Rating'
          />
          <span className='mx-1'>
            {tutor.rating === 0 ? <span>Not Rated</span> : tutor.rating}
          </span> */}
          <Rating
            fractions={2}
            readonly={true}
            initialRating={tutor.rating}
            emptySymbol={<FontAwesomeIcon icon='star' className='text-gray' />}
            fullSymbol={<FontAwesomeIcon icon='star' className='text-gold' />}
          />
        </div>
        <div>
          <FontAwesomeIcon
            icon='school'
            className='text-secondary'
            title='School'
          />{' '}
          <span className='mx'>
            {profile.school}, {profile.location}
          </span>
        </div>
        <div>
          <FontAwesomeIcon
            icon='graduation-cap'
            className='text-secondary'
            title='Grade'
          />{' '}
          {profile.grade == 13 ? (
            <span className='mx'>College</span>
          ) : (
            <span className='mx'>{profile.grade}th Grade</span>
          )}
        </div>
        {/* <div>
          <FontAwesomeIcon
            icon='user-friends'
            className='text-secondary'
            title='Current Students'
          />{' '}
          <span className='mx'>{tutor.totalStudents.length} Students</span>
        </div>
        <div>
          <FontAwesomeIcon
            icon='hourglass-half'
            className='text-secondary'
            title='Hours'
          />{' '}
          <span className='mx-1'>{tutor.hours} Hours</span>
        </div> */}
        {/* {tutor.affiliation && tutor.affiliation !== '' && (
          <div>
            <FontAwesomeIcon
              icon='sitemap'
              className='text-secondary'
              title='Affiliation'
            />{' '}
            <span className='mx'>{tutor.affiliation}</span>
          </div>
        )} */}
        {tutor.availability && tutor.availability !== '' && (
          <div>
            <FontAwesomeIcon
              icon='calendar-alt'
              className='text-secondary'
              title='Availability'
            />
            <span className='mx-1'>{tutor.availability}</span>
          </div>
        )}
      </div>
      <div className='hide-sm tutor-text'>
        {tutor.bio.substring(0, 300)}
        {tutor.bio.length > 300 ? <span>...</span> : null}
      </div>
      <div>
        <ul>
          {tutor.subjects.map((subject, index) => {
            if (index < 3) {
              return (
                <li key={index}>
                  <FontAwesomeIcon icon='check' className='text-secondary' />{' '}
                  {subject}
                </li>
              );
            }
            if (index === 3) {
              return <li key={index}>+ {tutor.subjects.length - 3} more</li>;
            }
          })}
          {tutor.ap &&
            tutor.ap.map((ap, index) => {
              if (index < 3) {
                return (
                  <li key={index}>
                    <FontAwesomeIcon icon='check' className='text-secondary' />{' '}
                    {ap.courseName}
                  </li>
                );
              }
              if (index === 3) {
                return <li key={index}>+ {tutor.ap.length - 3} more</li>;
              }
            })}
        </ul>
      </div>
    </div>
  );
};

export default TutorItem;
