import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountNav from '../AccountNav';
import TutorItem from './TutorItem';
import StudentItem from './StudentItem';
import UpcomingMeetingItem from './UpcomingMeetingItem';
import PastMeetingItem from './PastMeetingItem';
import { getCurrentProfile } from '../../../actions/profile';
import { getCurrentTutor, scheduleMeeting } from '../../../actions/tutor';
import { getCurrentStudent } from '../../../actions/student';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const Meeting = ({
  profile,
  tutor,
  student,
  user,
  getCurrentTutor,
  getCurrentStudent,
  getCurrentProfile,
  scheduleMeeting
}) => {
  const [upcomingTutorMeetings, setUpcomingTutorMeetings] = useState([]);

  const [pastTutorMeetings, setPastTutorMeetings] = useState([]);

  const [upcomingStudentMeetings, setUpcomingStudentMeetings] = useState([]);

  const [pastStudentMeetings, setPastStudentMeetings] = useState([]);

  useEffect(() => {
    getCurrentProfile();
    getCurrentStudent();
    getCurrentTutor();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tutor && tutor.meetings) {
      setUpcomingTutorMeetings(
        tutor.meetings
          .filter(meeting => !meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return aDate - bDate;
          })
      );
      setPastTutorMeetings(
        tutor.meetings
          .filter(meeting => meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return bDate - aDate;
          })
      );
    }
    if (student && student.meetings) {
      setUpcomingStudentMeetings(
        student.meetings
          .filter(meeting => !meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return aDate - bDate;
          })
      );
      setPastStudentMeetings(
        student.meetings
          .filter(meeting => meeting.completed)
          .sort((a, b) => {
            const aDate = Date.parse(a.startTime);
            const bDate = Date.parse(b.startTime);
            return bDate - aDate;
          })
      );
    }
  }, [tutor, student]);

  const [showStudents, setShowStudents] = useState(true);

  const [showTutors, setShowTutors] = useState(true);

  return (
    <div className='container colored'>
      {user ? (
        <HelmetWrapper
          title={`${user.name} | Ingenify`}
          description='This is the meetings page of the Ingenify account. It contains information about tutors and students, upcoming and past meetings, and it allows students and tutors to start, join, and schedule meetings.'
        />
      ) : (
        <HelmetWrapper
          title={`My Account | Ingenify`}
          description='This is the meetings page of the Ingenify account. It contains information about tutors and students, upcoming and past meetings, and it allows students and tutors to start, join, and schedule meetings.'
        />
      )}
      <div className='account-page'>
        <AccountNav page='meetings' />
        <div className='account-body'>
          {tutor ? (
            <div>
              <span
                className='highlight'
                onClick={e => setShowStudents(!showStudents)}
              >
                My Students
              </span>
              {showStudents ? (
                <ul>
                  {tutor.students.map((student, index) => (
                    <li key={index}>
                      <StudentItem student={student} />
                      <hr className='my' />
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          ) : null}
          {student && student.tutors && student.tutors.length > 0 ? (
            <div>
              <span
                className='highlight'
                onClick={e => setShowTutors(!showTutors)}
              >
                My Tutors
              </span>
              {showTutors ? (
                <div>
                  <ul>
                    {student.tutors.map((tutor, index) => (
                      <li key={index}>
                        <TutorItem tutor={tutor} />
                        <hr className='my' />
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
          <div>
            <p className='lead'>Upcoming Meetings</p>
            <hr className='my' />
            {tutor && student && student.tutors && student.tutors.length > 0 ? (
              <p className='medium'>As Tutor</p>
            ) : null}
            <ul>
              {upcomingTutorMeetings.map((meeting, index) => (
                <li key={index}>
                  <UpcomingMeetingItem meeting={meeting} isTutor={true} />
                  <hr className='my' />
                </li>
              ))}
            </ul>
            {student && student.tutors && student.tutors.length > 0 && tutor ? (
              <p className='medium'>As Student</p>
            ) : null}
            <ul>
              {upcomingStudentMeetings.map((meeting, index) => (
                <li key={index}>
                  <UpcomingMeetingItem meeting={meeting} isTutor={false} />
                  <hr className='my' />
                </li>
              ))}
            </ul>
          </div>
          {upcomingTutorMeetings.length === 0 &&
            upcomingStudentMeetings.length === 0 && (
              <div style={{ height: '3rem' }}></div>
            )}
          <div>
            <p className='lead'>Past Meetings</p>
            <hr className='my' />
            {tutor && student && student.tutors && student.tutors.length > 0 ? (
              <p className='medium'>As Tutor</p>
            ) : null}
            <ul>
              {pastTutorMeetings.map((meeting, index) => (
                <li key={index}>
                  <PastMeetingItem meeting={meeting} isTutor={true} />
                  <hr className='my' />
                </li>
              ))}
            </ul>
            {student && student.tutors && student.tutors.length > 0 && tutor ? (
              <p className='medium'>As Student</p>
            ) : null}
            <ul>
              {pastStudentMeetings.map((meeting, index) => (
                <li key={index}>
                  <PastMeetingItem meeting={meeting} isTutor={false} />
                  <hr className='my' />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

Meeting.propTypes = {
  profile: PropTypes.object,
  tutor: PropTypes.object,
  student: PropTypes.object,
  user: PropTypes.object,
  getCurrentStudent: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getCurrentTutor: PropTypes.func.isRequired,
  scheduleMeeting: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  tutor: state.tutor.tutor,
  student: state.student.student,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getCurrentTutor,
  getCurrentStudent,
  scheduleMeeting
})(Meeting);
