import React, { useState } from 'react';
import { connect } from 'react-redux';
import { deleteReview } from '../../actions/tutor';
import Rating from 'react-rating';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const Review = ({ review, ownership, deleteReview, tutor }) => {
  const handleDelete = e => {
    e.preventDefault();
    deleteReview(tutor._id, review._id);
  };
  return (
    <div>
      <div>
        {!review.anonymous ? (
          <div className='flex'>
            <img
              src={review.student.profileImageUrl}
              className='profile-img-small'
            />
            <div className='mx'>
              <p>
                <strong>{review.student.name}</strong>
              </p>
              <Moment format='MM/DD/YYYY'>{review.date}</Moment>
            </div>
          </div>
        ) : (
          <div>
            <p>
              <strong>Anonymous</strong>
            </p>
            <p>
              <Moment format='MM/DD/YYYY'>{review.date}</Moment>
            </p>
          </div>
        )}
      </div>
      <Rating
        fractions={2}
        readonly={true}
        initialRating={review.rating}
        emptySymbol={<FontAwesomeIcon icon='star' className='text-gray' />}
        fullSymbol={<FontAwesomeIcon icon='star' className='text-gold' />}
      />
      <p style={{ whiteSpace: 'pre-wrap' }}>{review.text}</p>
      {ownership && (
        <button className='btn btn-danger' onClick={e => handleDelete(e)}>
          Delete Review
        </button>
      )}
    </div>
  );
};

Review.propTypes = {
  review: PropTypes.object.isRequired,
  ownership: PropTypes.bool.isRequired,
  deleteReview: PropTypes.func.isRequired,
  tutor: PropTypes.object.isRequired
};

export default connect(null, { deleteReview })(Review);
