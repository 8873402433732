import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addHours } from '../../../actions/org';
import '../../../App.css';
const ParticipantItem = ({ participant, view, meeting, org, addHours }) => {
  return (
    <div className='participant-item flex'>
      <img src={participant.user.profileImageUrl} alt='participant' />
      <div className='flex-spread'>
        <div className='m-auto-vert'>
          <p className='mx'>{participant.user.name}</p>
        </div>
        {view === 0 && !participant.approved && (
          <div className='hide-sm'>
            <div className='m-auto-vert'>
              <button
                className='btn btn-secondary'
                onClick={e =>
                  addHours(participant.user._id, meeting._id, org._id)
                }
              >
                Approve Hours
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ParticipantItem.propTypes = {
  addHours: PropTypes.func.isRequired,
  participant: PropTypes.object.isRequired
};

export default connect(null, { addHours })(ParticipantItem);
