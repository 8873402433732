import axios from 'axios';
import { GET_CHATS, GET_CHAT, AFTER_POST_MESSAGE } from './types';
import { setAlert } from './alert';

export const afterPostMessage = messageFromBackEnd => async dispatch => {
  try {
    dispatch({
      type: AFTER_POST_MESSAGE,
      payload: messageFromBackEnd
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const getChats = () => async dispatch => {
  try {
    const res = await axios.get('/api/chat/');
    dispatch({
      type: GET_CHATS,
      payload: res.data
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const getChat = chatId => async dispatch => {
  try {
    const res = await axios.get(`/api/chat/${chatId}`);
    dispatch({
      type: GET_CHAT,
      payload: res.data
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};
