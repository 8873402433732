import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountNav from '../AccountNav';
import { getCurrentProfile } from '../../../actions/profile';
import { clearOrg } from '../../../actions/org';
import OrgItem from './OrgItem';
import OrgMeeting from './OrgMeeting';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';

const MyOrgs = ({
  profile: { profile, loading },
  user,
  getCurrentProfile,
  clearOrg
}) => {
  useEffect(() => {
    getCurrentProfile();
    clearOrg();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [orgMeetings, setOrgMeetings] = useState([]);

  useEffect(() => {
    if (profile && profile.orgMeetings) {
      setOrgMeetings(
        profile.orgMeetings.sort((a, b) => {
          const aDate = Date.parse(a.startTime);
          const bDate = Date.parse(b.startTime);
          return bDate - aDate;
        })
      );
    }
  }, [profile]);

  return (
    <div className='container colored'>
      {user ? (
        <HelmetWrapper
          title={`${user.name} | Ingenify`}
          description='This is the organizations page of the Ingenify account. It contains information about all of the organizations the user is a part of.'
        />
      ) : (
        <HelmetWrapper
          title={`My Account | Ingenify`}
          description='This is the organizations page of the Ingenify account. It contains information about all of the organizations the user is a part of.'
        />
      )}
      <div className='account-page'>
        <AccountNav page='organizations' />
        <div className='account-body'>
          <p className='lead'>My Organizations</p>
          <hr className='my' />
          <ul>
            {profile && profile.orgs
              ? profile.orgs.map((org, index) => (
                  <li key={index}>
                    <OrgItem org={org} />
                    <hr className='my' />
                  </li>
                ))
              : null}
          </ul>
          <p className='lead'>My Meetings</p>
          <hr className='my' />
          <ul>
            {orgMeetings.map((meeting, index) => (
              <li key={index}>
                <OrgMeeting meeting={meeting} />
                <hr className='my' />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

MyOrgs.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  clearOrg: PropTypes.func.isRequired,
  profile: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  profile: state.profile,
  user: state.auth.user
});

export default connect(mapStateToProps, { getCurrentProfile, clearOrg })(
  MyOrgs
);
