import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ParticipantItem from './ParticipantItem';

const PastMeetingItem = ({ meeting, org, view }) => {
  let date = meeting.startTime.split('T')[0];
  let time = meeting.startTime.split('T')[1];
  const dateParts = date.split('-');
  date = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
  const timeParts = time.split(':');
  const hour = parseInt(timeParts[0]);
  const minutes = timeParts[1];
  if (hour >= 12) {
    if (hour === 12) {
      time = `${hour}:${minutes} PM`;
    } else {
      time = `${hour - 12}:${minutes} PM`;
    }
  } else {
    if (hour === 0) {
      time = `12:${minutes} AM`;
    } else {
      time = `${hour}:${minutes} AM`;
    }
  }

  const [showParticipants, setShowParticipants] = useState(false);
  return (
    <div>
      <div>
        <div className='org-past-meeting'>
          <div className='flex'>
            <div>
              <p
                onClick={e => setShowParticipants(!showParticipants)}
                className='highlight'
              >
                {meeting.topic}
              </p>
              {showParticipants && (
                <ul>
                  {meeting.participants.map((participant, index) => (
                    <li key={index}>
                      <hr className='my' />
                      <ParticipantItem
                        participant={participant}
                        view={view}
                        meeting={meeting}
                        org={org}
                      />{' '}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className='flex hide-sm'>
            <p>
              {date} at {time} {meeting.timezone}
            </p>
          </div>
          <div className='flex right'>
            <p>{meeting.duration} minutes</p>
          </div>
        </div>
      </div>
    </div>
  );
};

PastMeetingItem.propTypes = {
  meeeting: PropTypes.object.isRequired,
  org: PropTypes.object.isRequired,
  view: PropTypes.number.isRequired
};

export default PastMeetingItem;
