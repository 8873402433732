import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPartners, createPartner } from '../../actions/partner';
import HelmetWrapper from '../utils/HelmetWrapper';
import Footer from './Footer';
import '../../App.css';

const Partners = ({ getPartners, createPartner, auth, partner }) => {
  const initialForm = {
    name: '',
    description: '',
    partnerImageUrl: '',
    website: ''
  };

  const [formData, setFormData] = useState(initialForm);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getPartners();
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    createPartner(formData);
    setFormData(initialForm);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { name, description, partnerImageUrl, website } = formData;

  return (
    <div className='container'>
      <HelmetWrapper
        title='Partners'
        description='These are the partners of Ingenify.'
      />
      <div className='partners-head'>
        <div>
          <h1 className='text-center'>Our Partners</h1>
          <p className='text-center'>
            We are proud to be working with all of our partners who contribute
            to providing free accessible tutoring
          </p>
        </div>
      </div>
      <ul className='partners-body'>
        {partner.partners &&
          partner.partners.map((partner, index) => (
            <li key={index} className='partner-item'>
              <img src={partner.partnerImageUrl} alt='img' />
              <div className='mx'>
                <a
                  href={partner.website}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <h1 className='lead mobile-center'>{partner.name}</h1>
                </a>
                <p style={{ whiteSpace: 'pre-wrap' }}>{partner.description}</p>
                <hr className='my' />
              </div>
            </li>
          ))}
      </ul>
      {auth.user && auth.user.admin && (
        <form onSubmit={e => onSubmit(e)}>
          <div>
            <input
              type='text'
              placeholder='name'
              name='name'
              value={name}
              onChange={e => onChange(e)}
              required
            />
          </div>
          <div>
            <input
              type='text'
              placeholder='website'
              name='website'
              value={website}
              onChange={e => onChange(e)}
              required
            />
          </div>
          <div>
            <input
              type='text'
              placeholder='Image Url'
              name='partnerImageUrl'
              value={partnerImageUrl}
              onChange={e => onChange(e)}
              required
            />
          </div>
          <div>
            <textarea
              name='description'
              placeholder='description'
              value={description}
              onChange={e => onChange(e)}
              required
            />
          </div>
          <div>
            <input type='submit' value='submit' />
          </div>
        </form>
      )}
      <Footer />
    </div>
  );
};

Partners.propTypes = {
  getPartners: PropTypes.func.isRequired,
  createPartner: PropTypes.func.isRequired,
  auth: PropTypes.object,
  partner: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  partner: state.partner
});

export default connect(mapStateToProps, { getPartners, createPartner })(
  Partners
);
