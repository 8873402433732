import axios from 'axios';
import { setAlert } from './alert';
import { GET_ORG, GET_ORGS, ORG_ERROR, CLEAR_ORG } from './types';

export const getOrgById = orgId => async dispatch => {
  try {
    const res = await axios.get(`/api/org/${orgId}`);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
    dispatch({
      type: ORG_ERROR
    });
  }
};

export const getOrgs = () => async dispatch => {
  dispatch({
    type: CLEAR_ORG
  });
  try {
    const res = await axios.get('/api/org');
    dispatch({
      type: GET_ORGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ORG_ERROR
    });
  }
};

export const deleteOrg = orgId => async dispatch => {
  try {
    await axios.delete(`/api/org/${orgId}`);
    dispatch({
      type: CLEAR_ORG
    });
    dispatch(setAlert('Organization Deleted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const createOrg = formData => async dispatch => {
  try {
    const res = await axios.post('/api/org', formData);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Organization Created', 'success'));
    window.location.replace(
      `https://ingenify.org/organizations/${res.data.org._id}`
    );
  } catch (err) {
    const errors = err.response.data.errors;
    errors.forEach(error => {
      dispatch(setAlert(error.msg, 'danger'));
    });
  }
};

export const updateOrg = (formData, orgId) => async dispatch => {
  try {
    const res = await axios.put(`/api/org/${orgId}`, formData);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Organization Updated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;
    errors.forEach(error => {
      dispatch(setAlert(error.msg, 'danger'));
    });
  }
};

export const joinOrg = (formData, orgId) => async dispatch => {
  const body = { code: formData };
  try {
    const res = await axios.post(`/api/org/${orgId}/enroll`, body);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Enrolled', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const leaveOrg = orgId => async dispatch => {
  try {
    const res = await axios.get(`/api/org/${orgId}/unenroll`);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Left Organization', 'success'));
  } catch (err) {
    dispatch({
      type: ORG_ERROR
    });
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const removeMember = (userId, orgId) => async dispatch => {
  try {
    const res = await axios.get(`/api/org/${orgId}/remove/${userId}`);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Removed Member', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const clearOrg = () => dispatch => {
  dispatch({
    type: CLEAR_ORG
  });
};

export const scheduleMeeting = (formData, orgId) => async dispatch => {
  try {
    await axios.get('/api/auth/zoom/refresh');
    const res = await axios.post(`/api/org/${orgId}/meetings`, formData);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Meeting Scheduled', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const deleteMeeting = (meetingId, orgId) => async dispatch => {
  try {
    await axios.get('/api/auth/zoom/refresh');
    const res = await axios.delete(`/api/org/${orgId}/meetings/${meetingId}`);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Meeting Deleted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const refreshMeetings = orgId => async dispatch => {
  try {
    await axios.get('/api/auth/zoom/refresh');
    const res = await axios.get(`/api/org/${orgId}/meetings`);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const addHours = (userId, meetingId, orgId) => async dispatch => {
  try {
    const res = await axios.get(
      `/api/org/${orgId}/meetings/${meetingId}/users/${userId}`
    );
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Hours Added', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const addParticipant = (meetingId, orgId) => async dispatch => {
  try {
    await axios.get(`/api/org/${orgId}/meetings/${meetingId}/add`);
    dispatch(setAlert('Joined', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
  }
};

export const sendAnnouncement = (formData, orgId) => async dispatch => {
  const body = { msg: formData };
  try {
    const res = await axios.post(`/api/org/${orgId}/announcements`, body);
    dispatch({
      type: GET_ORG,
      payload: res.data
    });
    dispatch(setAlert('Announcement Sent', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};
