import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { acceptStudent, declineStudent } from '../../../actions/tutor';
import PropTypes from 'prop-types';

const StudentItem = ({ student, acceptStudent, declineStudent }) => {
  const handleAccept = e => {
    e.preventDefault();
    acceptStudent(student._id);
  };
  const handleDecline = e => {
    e.preventDefault();
    declineStudent(student._id);
  };
  return (
    <div>
      <div className='flex-spread student-item'>
        <div className='flex'>
          <img src={student.profileImageUrl} alt='student' />
          <p className='px-1'>{student.name}</p>
        </div>
        <div className='flex right'>
          <div className='px-1 m-auto-vert'>
            <button
              className='btn btn-secondary'
              onClick={e => handleAccept(e)}
            >
              Accept
            </button>
          </div>
          <div className='m-auto-vert'>
            <button className='btn btn-danger' onClick={e => handleDecline(e)}>
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

StudentItem.propTypes = {
  student: PropTypes.object,
  acceptStudent: PropTypes.func.isRequired,
  declineStudent: PropTypes.func.isRequired
};

export default connect(null, { acceptStudent, declineStudent })(StudentItem);
