import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getOrgById } from '../../../actions/org';
import OrgHeader from './OrgHeader';
import OrgNav from './OrgNav';
import MemberItem from './MemberItem';
import HelmetWrapper from '../../utils/HelmetWrapper';
import Footer from '../../layout/Footer';
import '../../../App.css';
const Members = ({ org: { org, loading, view }, getOrgById, match }) => {
  useEffect(() => {
    if (match.params.id) {
      getOrgById(match.params.id);
    }
  }, [match.params.id]);

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (view === 2 && !loading) {
      setRedirect(true);
    }
  }, [view, loading]);
  if (redirect) {
    return <Redirect push to={`/organizations/${org._id}`} />;
  }
  return (
    <div className='container colored'>
      {org ? (
        <HelmetWrapper
          title={`${org.name} | Ingenify`}
          description="This is the organization's members page. It contains the information about the organization's members and allows the organization to remove members."
        />
      ) : (
        <HelmetWrapper
          title={`Organization | Ingenify`}
          description="This is the organization's members page. It contains the information about the organization's members and allows the organization to remove members."
        />
      )}
      <div className='flex-column-center'>
        {org && (
          <div className='org-width'>
            <OrgHeader org={org} view={view} />
            <OrgNav org={org} view={view} />
            <div className='org-body'>
              <h1 className='lead'>Owner</h1>
              <div className='flex student-item'>
                <img src={org.admin.profileImageUrl} alt='admin' />
                <p className='px'>{org.admin.name}</p>
              </div>
              <hr className='my' />
              <h1 className='lead'>Members</h1>
              <ul>
                {org.members.map((member, index) => (
                  <li key={index}>
                    {member._id !== org.admin._id && (
                      <div>
                        {' '}
                        <MemberItem
                          member={member}
                          view={view}
                          org={org}
                        />{' '}
                        <hr className='my' />{' '}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
Members.propTypes = {
  getOrgById: PropTypes.func.isRequired,
  org: PropTypes.object
};

const mapStateToProps = state => ({
  org: state.org
});

export default connect(mapStateToProps, { getOrgById })(Members);
