import axios from 'axios';
import { setAlert } from './alert';
import { GET_STUDENT, STUDENT_ERROR } from './types';

export const getCurrentStudent = () => async dispatch => {
  try {
    const res = await axios.get('/api/student/me');
    dispatch({
      type: GET_STUDENT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: STUDENT_ERROR
    });
  }
};

export const createStudent = () => async dispatch => {
  try {
    const res = await axios.post('/api/student');
    dispatch({
      type: GET_STUDENT,
      payload: res.data
    });
    dispatch(setAlert('Student Profile Updated', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
    dispatch({
      type: STUDENT_ERROR
    });
  }
};

export const deleteStudent = () => async dispatch => {
  try {
    await axios.delete('/api/student');
    dispatch({
      type: STUDENT_ERROR
    });
    dispatch(setAlert('Student Profile Deleted', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
    dispatch({
      type: STUDENT_ERROR
    });
  }
};

export const enrollStudent = (formData, userId) => async dispatch => {
  try {
    const body = { code: formData };
    const res = await axios.post(`/api/student/enroll/${userId}`, body);
    dispatch({
      type: GET_STUDENT,
      payload: res.data
    });
    dispatch(setAlert('Enrolled', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const unenrollStudent = userId => async dispatch => {
  try {
    const res = await axios.get(`/api/student/unenroll/${userId}`);
    dispatch({
      type: GET_STUDENT,
      payload: res.data
    });
    dispatch(setAlert('Unenrolled', 'success'));
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};

export const requestTutor = (formData, userId) => async dispatch => {
  try {
    const body = { msg: formData };
    const res = await axios.post(`/api/student/request/${userId}`, body);
    dispatch({
      type: GET_STUDENT,
      payload: res.data
    });
    dispatch(
      setAlert(
        'Request Sent! You will receive an email when the tutor accepts or declines your request.',
        'important',
        5000
      )
    );
  } catch (err) {
    const error = err.response.data.msg;
    dispatch(setAlert(error, 'danger'));
  }
};
